<template>
  <v-dialog
    v-model="showDialog"
    scrollable
    max-width="500">
    <v-card class="smfm-dialog-with-header">
      <v-toolbar flat :height="$vuetify.breakpoint.smAndDown ? 60 : 70"
        class="smfm-page-subheading">
        {{ $t('pro.qrlist.removetags') }}
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click.stop="closeDialog()">
          <v-icon color="secondary">{{ iconClose }}</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <div class="mb-7">
          {{ $t('pro.qrlist.removingfrom') }}:
          <div v-for="code in selected" :key="code.codeid" class="text--primary">
            {{ code.name }}
          </div>
        </div>
        <v-list-item-group
          v-if="tags.length > 0"
          v-model="tagstoremove"
          multiple
          class="tags-wrap">
          <v-list-item v-for="(tag, index) in tags" :key="`tag-${index}`" :value="tag">
            <template v-slot:default="{ active }">
              <v-list-item-action>
                <v-checkbox :input-value="active"></v-checkbox>
              </v-list-item-action>
              <v-list-item-title>{{ tag }}</v-list-item-title>
            </template>
          </v-list-item>
        </v-list-item-group>
        <div v-else class="secondary--text">
          {{ $t('pro.qrlist.notags') }}
        </div>
      </v-card-text>
      <v-card-actions class="d-flex justify-end align-center pt-5"
          :class="[$vuetify.breakpoint.smAndDown ? 'px-5 pb-7' : 'px-10 pb-10']">
        <v-btn
          text
          class="me-3"
          color="secondary"
          @click.stop="closeDialog()"
        > {{ $t('app.cancel') }}
        </v-btn>
        <v-btn
          color="error"
          class="smfm-lg-btn smfm-error-btn"
          :disabled="!tagstoremove.length"
          @click="bulkRemoveTags"
        > {{ $t('pro.qrlist.remove') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
    import userMixin from "@/mixins/userMixin";
    import { mdiClose } from '@mdi/js';

    export default {
        name: "ProTagsRemoveDialog",
        mixins: [ userMixin ],
        props: {
            dialogVisible: {
                type: Boolean,
                default: false,
            },
            selected: {
                type: Array,
                default: () => {}
            }
        },
        data() {
            return {
                tagstoremove: [],
            }
        },
        computed: {
            iconClose() { return mdiClose },
            showDialog: {
                get() {
                    return this.dialogVisible;
                },
                set(newValue) {
                    if(!newValue) this.$emit('close');
                }              
            }, 
            tags() {
                const tags = [];
                const tagsInSelected = [].concat(...this.selected.map(code => code.tags ? code.tags : []));
                tagsInSelected.forEach(tag => {
                    if (!tags.includes(tag)) tags.push(tag);
                })
                return tags;
            },
            selectedCodes() {
                return this.selected.map(code => code.codeid);
            }        
        },
        methods: {
            closeDialog() {
                this.showDialog = false;
                this.tagstoremove = [];
            },
            remove(tag) {
                const index = this.tagstoremove.indexOf(tag)
                if (index >= 0) this.tagstoremove.splice(index, 1)
            },   
            bulkRemoveTags() {
                this.authPost({
                    url: 'pro/codesbulk/removetags', 
                    params: { codes: this.selectedCodes, tags: this.tagstoremove }
                }).then(() => {
                    this.$emit('refresh');
                    this.closeDialog();
                })
            },     
        }   
    }
</script>

<style scoped lang="scss">
.tags-wrap {
  max-height: 260px;
  height: auto;
  overflow-y: auto;
  border: 1px solid #C7C8CA;
  border-radius: 6px;
}
</style>
