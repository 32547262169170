import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"
import SecureLS from "secure-ls"
import { default as app } from "./modules/app";
import { default as app_pro } from "./modules/app_pro";
import { default as currency } from "./modules/currency";
import { default as currentqr } from "./modules/currentqr";
import { default as resources } from "./modules/resources";
import { default as library_pro } from "./modules/library_pro";
import { default as qrcodes_pro } from "./modules/qrcodes_pro";
import { default as qrdownload_pro } from "./modules/qrdownload_pro";
import { default as resources_admin } from './modules/resources_admin';

const ls = new SecureLS({ isCompression: false })

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        library_pro,
        resources,
        resources_admin,
        app,
        app_pro,
        currentqr,
        currency,
        qrcodes_pro,
        qrdownload_pro,
    },
    plugins: [
        createPersistedState({
            key: "globalstate",
            paths: [
                "currency",
            ],
            storage: {
                getItem: (key) => ls.get(key),
                setItem: (key, value) => ls.set(key, value),
                removeItem: (key) => ls.remove(key),
            },
        }),
    ],
})
