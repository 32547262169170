import userMixin from "@/mixins/userMixin";

const smartCopy = (source, target) => {
    if (target === null) return source
    let res = []
    for (let s of source) {
        const els = target.filter(t => t.value === s.value && t.previewurl === s.previewurl)
        res.push((els.length === 1) ? els[0] : s)
    }
    return res
}

export default {
    state: () => ({
        userlogos: null,
        usertemplates: null,
        userpresets: null,
        tempfiles: null,
        logooptionscounter: 0,
        templateoptionscounter: 0,
        presetoptionscounter: 0,
        domains: [],
        apikeys: [],
    }),

    getters: {
        userlogos(state) { return state.userlogos ? state.userlogos : [] },
        usertemplates(state) { return state.usertemplates ? state.usertemplates : [] },
        userpresets(state) { return state.userpresets ? state.userpresets : [] },
        logooptionscounter(state) { return state.logooptionscounter },
        templateoptionscounter(state) { return state.templateoptionscounter },
        presetoptionscounter(state) { return state.presetoptionscounter },
        logooptions(state, getters, rootState, rootGetters) {
            if (rootGetters.isAdmin) {
                return state.userlogos ? state.userlogos : []
            } else if (rootGetters.isPro) {
                return (state.userlogos ? state.userlogos : []).concat(rootGetters.sharedlibrary.logos)
            } else {
                return rootGetters.sharedlibrary.logos
            }
        },
        logooptionswithempty(state, getters) {
            return [{value: '0', src: getters.noImageSrc}].concat(getters.logooptions)
        },
        templateoptions(state, getters, rootState, rootGetters) {
            if (rootGetters.isAdmin) {
                return getters.usertemplates
            } else if (rootGetters.isPro) {
                return getters.usertemplates.concat(rootGetters.sharedlibrary.templates)
            } else {
                return rootGetters.sharedlibrary.templates
            }
        },
        templateoptionswithempty(state, getters) {
            return [{value: '0', src: getters.noImageSrc}].concat(getters.templateoptions)
        },
        presetoptions(state, getters, rootState, rootGetters) {
            if (rootGetters.isAdmin) {
                return getters.userpresets
            } else if (rootGetters.isPro) {
                return getters.userpresets.concat(rootGetters.sharedlibrary.presets)
            } else {
                return rootGetters.sharedlibrary.presets
            }
        },
        tempfiles(state) {
            return state.tempfiles
        },
        noImageSrc(state, getters, rootState, rootGetters) {
            return `${rootGetters.isPro ? process.env.VUE_APP_PRO_URL : process.env.VUE_APP_URL}/images/noimage.svg`
        },
        brokenImageSrc(state, getters, rootState, rootGetters) {
            return `${rootGetters.isPro ? process.env.VUE_APP_PRO_URL : process.env.VUE_APP_URL}/images/brokenimage.svg`
        },
        libraryloaded(state, getters, rootState, rootGetters) {
            if (rootGetters.isPro || rootGetters.isAdmin)
                return state.userlogos !== null && state.usertemplates !== null
            return true
        },
        tempfilesloaded(state) {
            return state.tempfiles !== null
        },
        availableDomains(state) {
            return state.domains
        }
    },

    mutations: {
        _setUserLibrarySection(state, {section, items, dispatch}) {
            if (dispatch) {
                const loadcontent = (url) => (() => dispatch('_loadLibraryItem', {section, url}))
                const f = (v) => Object.assign({}, v, {withbadge: !!v.usercontent, loadcontent: loadcontent(v.previewurl)})
                items = items.map(f)
            }
            if (section === 'logo') {
                state.userlogos = smartCopy(items, state.userlogos)
            } else if (section === 'template') {
                state.usertemplates = smartCopy(items, state.usertemplates)
            } else if (section === 'preset') {
                state.userpresets = smartCopy(items, state.userpresets)
            }
        },
        _setTempFiles(state, items) {
            state.tempfiles = items
        },
        _setOptionSrc(state, {section, url, src, config, width, height}) {
            let haschanges = false
            state[`user${section}s`].map(option => {
                if (option.previewurl === url && config && !option.config) {
                    option.config = JSON.parse(config)
                    haschanges = true
                }
                if (option.previewurl === url && !option.previewsrc) {
                    //console.log(`setting previewsrc for [${section}] option ${option.value} (also setting width=${width} and height=${height}`)
                    option.previewsrc = src
                    if (width) option.width = width
                    if (height) option.height = height
                    option.loadcontent = null
                    haschanges = true
                    //option.isLoading = false
                }
            })
            if (haschanges && section === 'logo') state.logooptionscounter = state.logooptionscounter + 1
            else if (haschanges && section === 'template') state.templateoptionscounter = state.templateoptionscounter + 1
            else if (haschanges && section === 'preset') state.presetoptionscounter = state.presetoptionscounter + 1
        },
        _setDomains(state, payload) {
            state.domains = payload
        },
    },

    actions: {
        _loadLibraryItem({commit, getters}, {section, url}) {
            if (!url) {
                return
            }
            const makeSrc = (resp) => {
                return new Promise((resolve) => {
                    const src = `data:${resp.headers['content-type']};base64,${btoa(String.fromCharCode(...new Uint8Array(resp.data)))}`,
                        config = resp.headers['x-amz-meta-config']
                    const img = new Image();
                    img.onload = () => resolve({src, config, width: img.naturalWidth, height: img.naturalHeight})
                    img.src = src
                })
            }
            userMixin.methods.authGetBinary({url})
                .then(resp => makeSrc(resp))
                .then(r => commit('_setOptionSrc', {section, url, ...r}))
                .catch(() => commit('_setOptionSrc', {section, url, src: getters.brokenImageSrc}))

        },

        loadUserLibrary({dispatch, commit, getters}) {
            let url = getters.isPro ? 'pro/library' : (getters.isAdmin ? 'admin/library' : null)
            if (!url) return; // No user library in demo mode.
            return userMixin.methods.authGet({url}).then(resp => {
                for (let section of ['logo', 'template', 'preset']) {
                    commit('_setUserLibrarySection', {section, items: resp.data[section+'s'], dispatch})
                }
                return null
            })
        },

        loadTempFiles({commit, getters}, force = false) {
            if (!getters.isPro) return // No user library in demo mode.
            if (!force && getters.tempfilesloaded) return
            return userMixin.methods.authGet({url: 'pro/tempfiles'})
                .then(resp => commit('_setTempFiles', resp.data.tempfiles))
        },

        refreshUserLibrary({dispatch, commit, getters}, {section, uploaded, deleted, modified, renamed, newname, moved, after}) {
            let url = getters.isPro ? 'pro/library' : (getters.isAdmin ? 'admin/library' : null)
            if (!url) return; // No user library in demo mode.
            const prefix = getters.isAdmin ? 'shared/' : ''
            const olditems = getters['user'+section+'s']

            if (uploaded) {
                const newitem = Object.assign({value: prefix + uploaded}, getters.isPro ? {usercontent: true, withbadge: true} : {})
                commit('_setUserLibrarySection', {section, items: [newitem].concat(olditems)})
            }

            if (deleted) {
                let items = olditems.filter(v => v.value !== prefix + deleted)
                commit('_setUserLibrarySection', {section, items})
            }

            if (modified) {
                let items = olditems.map(v => (v.value === modified) ? Object.assign(v, {url:null, src: null}) : v)
                commit('_setUserLibrarySection', {section, items})
            }

            if (renamed) {
                let items = olditems.map(v => (v.value === prefix + renamed) ?
                    Object.assign(v, {value: prefix + newname, url:null, src: null}) : v)
                commit('_setUserLibrarySection', {section, items})
            }

            if (moved) {
                let item = olditems.filter(v => v.value === prefix + moved)
                let items = olditems.filter(v => v.value !== prefix + moved)
                let found = false
                if (item.length) {
                    if (after === '') {
                        items = item.concat(items)
                    } else {
                        let newitems = []
                        for (let i in items) {
                            newitems.push(items[i])
                            if (items[i].value === prefix + after) {
                                found = true
                                newitems.push(item[0])
                            }
                        }
                        items = newitems
                    }
                }
                if (found) {
                    commit('_setUserLibrarySection', {section, items})
                }
            }

            return userMixin.methods.authGet({url, params: {resource: section}}).then(resp => {
                let items = resp.data[section+'s'].map(item => {
                    // Save on extra loading, for unchanged items take the existing src/width/height.
                    let l = olditems.find(o => o.url === item.url)
                    return Object.assign({}, item, (item.url && !item.src && l) ? {src: l.src, width: l.width, height: l.height, config: l.config} : {})
                })
                commit('_setUserLibrarySection', {section, items, dispatch})
            })
        },

        parseXML(_, xmlString) {
            // https://stackoverflow.com/questions/11563554/how-do-i-detect-xml-parsing-errors-when-using-javascripts-domparser-in-a-cross/20294226
            const parser = new DOMParser(),
                parsererrorNS = parser.parseFromString('INVALID', 'application/xml').getElementsByTagName("parsererror")[0].namespaceURI,
                dom = parser.parseFromString(xmlString, 'text/xml')
            if (dom.getElementsByTagNameNS(parsererrorNS, 'parsererror').length > 0) {
                return null
            }
            return dom
        },

        validateUploadedTemplate({dispatch}, file) {
            return new Promise((resolve, reject) => {
                if (file.type !== 'image/svg+xml') {
                    reject('Template must be in SVG format')
                    return
                }

                // Make sure the svg file has <rect id="qr"> element.
                let fr = new FileReader();
                fr.onload = () => {
                    dispatch('parseXML', fr.result).then(tmplDom => {
                        if (!tmplDom || !tmplDom.querySelector('svg')) {
                            reject('Is this file in SVG format? Sorry we could not parse it')
                        } else if (!tmplDom.querySelector('rect#qr[x][y][width][height]')) {
                            reject('This template file must have a placeholder for the QR code, a <rect> element with id="qr", for example: <rect id="qr" x="0" y="0" width="100" height="100"></rect>')
                        } else {
                            resolve(file)
                        }
                    })
                }
                fr.readAsText(file);
            })
        },

        validateUploadedLogo(_, file) {
            const allowed = ['image/svg+xml', 'image/svg', 'image/png', 'image/jpeg', 'image/jpg']
            return new Promise((resolve, reject) => {
                if (!allowed.includes(file.type)) {
                    reject('Logo must be in SVG, PNG or JPG format')
                } else {
                    resolve(file)
                }
            })
        },

        getUploadedFileSize(_, file) {
            return new Promise((resolve) => {
                const src = URL.createObjectURL(file)
                const img = new Image();
                img.onload = () => resolve({file, width: img.naturalWidth, height: img.naturalHeight})
                img.src = src
            })
        },

        loadUserInfo({commit, getters}) {
            if (!getters.isPro) {
                return
            }
            return userMixin.methods.authGet({url: 'pro/user/info'})
                .then(resp => commit('_setDomains', resp.data.domains))
        },
    },
}
