<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn text v-bind="attrs" v-on="on"
        :ripple="false"
        class="time-limits">
          {{ tzbyhour.find(el => el.value === timezone).title }}
          <v-icon>
            {{ attrs["aria-expanded"] === "true" ? iconChevronUp : iconChevronDown }}
          </v-icon>
      </v-btn>
    </template>
    
    <v-list dense width="200">
      <v-list-item v-for="zone in tzbyhour" :key="zone.value"
          @click="selectTimezone(zone)">
          <v-list-item-title class="text-body-1 font-weight-medium">{{ zone.title }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
    import { mdiChevronDown, mdiChevronUp } from '@mdi/js';

    export default {
        name: "ProTimezone",
        props: {
            timezone: {
                type: String,
                default: 'user'
            },
        },
        data() {
            return {
                tzbyhour: [
                    { title: this.$t('pro.dashboard.mytimezone'), value: 'user' },
                    { title: this.$t('pro.dashboard.usertimezone'), value: 'local' },
                ]
            }
        },
        computed: {
            iconChevronDown() { return mdiChevronDown },
            iconChevronUp() { return mdiChevronUp },
        },
        methods: {
            selectTimezone(zone) {
                this.$emit('change', zone.value);
            }
        }
    }
</script>

<style scoped lang="scss">
.v-btn.time-limits {
  height: auto !important;
  font-size: 14px !important;
  line-height: 21px;
  color: #393E46 !important;
  padding-inline-start: 0 !important;
}
</style>