<template>
    <div>
        <pro-qr-list type="dynamic" :editable="false" :apiurl="`admin/users/${userid}/codes`"></pro-qr-list>
    </div>
</template>

<script>
    import ProQrList from "@/components/pro/lists/ProQrList";
    export default {
        name: "AdminUsersCodes",
        components: {ProQrList},
        computed: {
            userid() { return this.$route.params.userid }
        }
    }
</script>

<style scoped>

</style>