<template>
    <div v-if="userPlan.active && shortname" class="stats">
        <v-card flat :tile="$vuetify.breakpoint.smAndDown" class="mb-4"
            :class="[$vuetify.breakpoint.smAndDown ? 'pa-5' : 'pa-7']">
            <div class="d-flex justify-space-between align-center mb-5">
                <div class="smfm-page-heading">{{ qrlabel }}</div>
                <v-btn text color="primary"
                    :to="{ name: 'editdynamic', params: {codeid} }">
                    <v-icon color="primary" class="me-3">{{ iconEdit }}</v-icon>
                    {{ $t('pro.qrlist.editcode') }}
                </v-btn>
            </div>  
            <v-alert color="success" class="pa-5 mb-7">
                <div class="d-flex justify-space-between align-center">
                    <div class="d-flex align-center">
                        <div class="d-flex justify-center align-center info-icon-wrap me-4">
                            <v-icon color="white">{{ iconInfo }}</v-icon>
                        </div>
                        {{ shorturl }}
                        <a :href="shorturl+'?notracking=1'" target="_blank" class="ms-2">
                            <v-icon color="white">{{ iconOpenLink }}</v-icon>
                        </a>
                    </div>
                </div>
            </v-alert>

            <!-- Total scans -->
            <v-card outlined class="smfm-dashboard-card">
                <pro-dashboard-toolbar :title="$t('pro.dashboard.totalscans')"
                    :withList="false" :withBtns="true"
                    :total="stats.total"
                    @change="update($event, 'total', 0)"
                    @refresh="onRefresh('total', 0)">
                </pro-dashboard-toolbar>                            
                <div class="pa-5">
                    <loading v-if="loading[0]" :size="1" class="loading xl"></loading>
                    <div v-else class="total-scans">
                        <div v-if="hasCodes">
                            <div class="title mb-3">
                                <span class="text--primary">{{stats.total[0].cnt}}</span>
                                <span class="secondary--text">({{stats.total[0].cntunique}} {{$t('pro.dashboard.uniquecnt')}})</span>
                            </div>
                            <div class="d-flex flex-wrap justify-space-between align-center mb-4">
                                <div class="text--primary text-body-1 font-weight-medium">
                                    {{ $t('pro.dashboard.scanstimeline') }}
                                </div>
                                <pro-chart-legend></pro-chart-legend>
                            </div>
                            <div v-if="hasCodes && stats.total[0].scanstimeline && stats.total[0].scanstimeline.length > 0">
                                <stacked-bar-chart :chart-data="scanstimelineData" :height="270"></stacked-bar-chart>
                            </div>
                        </div>
                        <pro-scans-no-data v-else 
                            :icon-type="'total'"
                            :size="'xl'"
                            :has-data="hasCodes"
                            :has-create-btn="true"
                            :is-stats="true">
                        </pro-scans-no-data>
                    </div>
                </div>
            </v-card>  

            <v-row>
                <!-- Scans by months -->
                <v-col cols="12" sm="12" md="12" lg="6" :class="[$vuetify.breakpoint.mdAndDown ? 'px-0' : 'ps-0']">
                    <v-card outlined class="smfm-dashboard-card">
                        <pro-dashboard-toolbar :title="$t('pro.dashboard.scansbymonths')"
                            :by-month="true"
                            @change="update($event, 'bymonth', 1)"
                            @refresh="onRefresh('bymonth', 1)">
                        </pro-dashboard-toolbar>
                        <div class="pa-5">
                            <loading v-if="loading[1]" :size="1" class="loading lg"></loading>
                            <div v-else>
                                <div v-if="stats.bymonth && stats.bymonth.length > 0">
                                    <div class="d-flex justify-end align-center mb-4">
                                        <pro-chart-legend></pro-chart-legend>
                                    </div>                                    
                                    <stacked-bar-chart :chart-data="monthsData" :height="270"></stacked-bar-chart>
                                </div>
                                <pro-scans-no-data v-else 
                                    :icon-type="'date'"
                                    :size="'lg'"
                                    :has-data="hasCodes"
                                    :is-stats="true">
                                </pro-scans-no-data>
                            </div>
                        </div>
                    </v-card>
                </v-col>
                <!-- Scans by hour of the day -->
                <v-col cols="12" sm="12" md="12" lg="6" :class="[$vuetify.breakpoint.mdAndDown ? 'px-0' : 'pe-0']">
                    <v-card outlined class="smfm-dashboard-card">
                        <pro-dashboard-toolbar :title="$t('pro.dashboard.scansbyhour')"
                            @change="update($event, 'byhour', 2)"
                            @refresh="onRefresh('byhour', 2)">
                        </pro-dashboard-toolbar>
                        <div class="pa-5">
                            <loading v-if="loading[2]" :size="1" class="loading lg"></loading>
                            <div v-else>
                                <div v-if="stats.byhour && stats.byhour.length > 0">
                                    <div class="d-flex justify-space-between align-center mb-4">
                                        <pro-timezone
                                            @change="changeTimezone($event, 'byhour', 2)">
                                        </pro-timezone>
                                        <pro-chart-legend></pro-chart-legend>
                                    </div>
                                    <stacked-bar-chart :chart-data="hoursData" :height="270"></stacked-bar-chart>
                                </div>
                                <pro-scans-no-data v-else 
                                    :icon-type="'date'"
                                    :size="'lg'"
                                    :has-data="hasCodes"
                                    :is-stats="true">
                                </pro-scans-no-data>
                            </div>
                        </div>
                    </v-card>                            
                </v-col>
            </v-row>

            <v-row>
                <!-- Scans by OS -->
                <v-col cols="12" sm="12" md="12" lg="6" :class="[$vuetify.breakpoint.mdAndDown ? 'px-0' : 'ps-0']">
                    <v-card outlined class="smfm-dashboard-card">
                        <pro-dashboard-toolbar :title="$t('pro.dashboard.scansbyos')"
                            @change="update($event, 'byos', 3)"
                            @refresh="onRefresh('byos', 3)">
                        </pro-dashboard-toolbar>
                        <div class="pa-5">
                            <loading v-if="loading[3]" :size="1" class="loading"></loading>
                            <div v-else>
                                <div v-if="stats.byos && stats.byos.length > 0">
                                    <doughnut-chart :chart-data="osData" :height="200"></doughnut-chart>
                                </div>
                                <pro-scans-no-data v-else 
                                    :icon-type="'os'"
                                    :has-data="hasCodes"
                                    :is-stats="true">
                                </pro-scans-no-data>
                            </div>
                        </div>
                    </v-card>
                </v-col>
                <!-- Scans by language -->
                <v-col cols="12" sm="12" md="12" lg="6" :class="[$vuetify.breakpoint.mdAndDown ? 'px-0' : 'pe-0']">
                    <v-card outlined class="smfm-dashboard-card">
                        <pro-dashboard-toolbar :title="$t('pro.dashboard.scansbylang')"
                            @change="update($event, 'bylang', 4)"
                            @refresh="onRefresh('bylang', 4)">
                        </pro-dashboard-toolbar>                                    
                        <div class="pa-5">
                            <loading v-if="loading[4]" :size="1" class="loading"></loading>
                            <div v-else>
                                <div v-if="stats.bylang && stats.bylang.length > 0">
                                    <doughnut-chart :chart-data="langData" :height="200"></doughnut-chart>
                                </div>
                                <pro-scans-no-data v-else 
                                    :icon-type="'lang'"
                                    :has-data="hasCodes"
                                    :is-stats="true">
                                </pro-scans-no-data>
                            </div>
                        </div>
                    </v-card>
                </v-col>
            </v-row>

            <v-row>
                <!-- Scans by country -->
                <v-col cols="12" sm="12" md="12" lg="6" :class="[$vuetify.breakpoint.mdAndDown ? 'px-0' : 'ps-0']">
                    <v-card outlined class="smfm-dashboard-card">
                        <pro-dashboard-toolbar :title="$t('pro.dashboard.scansbycountry')"
                            :withInfo="true"
                            @change="update($event, 'bycountry', 5)"
                            @refresh="onRefresh('bycountry', 5)">
                        </pro-dashboard-toolbar>                                      
                        <div class="pa-5">
                            <loading v-if="loading[5]" :size="1" class="loading"></loading>
                            <div v-else>
                                <div v-if="stats.bycountry && stats.bycountry.length > 0">
                                    <doughnut-chart :chart-data="countriesData" :height="200"></doughnut-chart>
                                </div>
                                <pro-scans-no-data v-else 
                                    :icon-type="'location'"
                                    :has-data="hasCodes"
                                    :is-stats="true">
                                </pro-scans-no-data>
                            </div>
                        </div>
                    </v-card>
                </v-col>
                <!-- Scans by city -->
                <v-col cols="12" sm="12" md="12" lg="6" :class="[$vuetify.breakpoint.mdAndDown ? 'px-0' : 'pe-0']">
                    <v-card outlined class="smfm-dashboard-card">
                        <pro-dashboard-toolbar :title="$t('pro.dashboard.scansbycity')"
                            :withInfo="true"
                            @change="update($event, 'bycity', 6)"
                            @refresh="onRefresh('bycity', 6)">
                        </pro-dashboard-toolbar>                                        
                        <div class="pa-5">
                            <loading v-if="loading[6]" :size="1" class="loading"></loading>
                            <div v-else>
                                <div v-if="stats.bycity && stats.bycity.length > 0">
                                    <bar-chart :chart-data="citiesData" :height="200"></bar-chart>
                                </div>
                                <pro-scans-no-data v-else 
                                    :icon-type="'location'"
                                    :has-data="hasCodes"
                                    :is-stats="true">
                                </pro-scans-no-data>
                            </div>
                        </div>
                    </v-card>
                </v-col>
            </v-row>         
        </v-card>

        <!-- Last scans table -->
        <v-card outlined :tile="$vuetify.breakpoint.smAndDown" class="smfm-dashboard-card">
            <pro-dashboard-toolbar :title="$t('pro.dashboard.scanslast')"
                :withList="false" :withCSV="true"
                :scans="stats.scans"
                @refresh="onRefresh('scans', 7)">
            </pro-dashboard-toolbar> 
            <v-card-text :class="[!loading[7] ? 'pa-5' : 'py-13']">
                <loading v-if="loading[7]" :size="1" class="my-10"></loading>
                <pro-scans-list v-else :rows="stats.scans"></pro-scans-list>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
    import { mdiOpenInNew, mdiInformationOutline, mdiPencil } from "@mdi/js";
    import userMixin from "@/mixins/userMixin";
    import chartMixin from "@/mixins/chartMixin";
    import ProScansList from "@/components/pro/lists/ProScansList";
    import ProDashboardToolbar from "@/components/pro/dashboard/ProDashboardToolbar";
    import Loading from "@/components/common/Loading";
    import BarChart from "@/components/chart/BarChart";
    import DoughnutChart from "@/components/chart/DoughnutChart";
    import StackedBarChart from "@/components/chart/StackedBarChart";
    import ProTimezone from "@/components/pro/dashboard/ProTimezone";
    import ProScansNoData from "@/components/pro/dashboard/ProScansNoData";
    import ProChartLegend from "@/components/pro/dashboard/ProChartLegend";

    export default {
        name: "ProCodeStats",
        mixins: [ userMixin, chartMixin ],
        components: { 
            ProScansList, 
            Loading,
            BarChart, 
            DoughnutChart, 
            StackedBarChart, 
            ProDashboardToolbar,
            ProTimezone,
            ProScansNoData,
            ProChartLegend,
        },
        data() {
            return {
                qrlabel: null,
                shorturl: null,
                shortname: null,
                stats: {},
                params: {
                    total: {timelimit: "all"},
                    byhour: {tz: "user", timelimit: "all"},
                    bycountry: {timelimit: "all"},
                    bycity: {timelimit: "all"},
                    bymonth: {timelimit: "all"},
                    byos: {timelimit: "all"},
                    bylang: {timelimit: "all"},
                    scans: {limit: 100},
                },
                loading: { },
                loadingKeys: [...Array(8).keys()],
                scanstimelineData: null,
                monthsData: null,
                hoursData:null,
                osData: null,
                langData: null,
                countriesData: null,
                citiesData: null,
            }
        },
        computed: {
            codeid() { return this.$route.params.codeid },
            hasCodes() { return this.stats.total && this.stats.total[0].cnt !== "0" },
            iconInfo() { return mdiInformationOutline },
            iconOpenLink() { return mdiOpenInNew },
            iconEdit() { return mdiPencil },
            apiurl() {
                return this.isAdmin ? `admin/users/${this.$route.params.userid}/codes/${this.codeid}` : `pro/codes/${this.codeid}`
            }
        },
        created() {
            if (this.userPlan.active) {
                // TODO check codeid format (must start with 'd' etc)
                this.loadCode()
                this.loadStats()
            }
        },
        methods: {
            loadCode() {
                return this.authGet({url: this.apiurl})
                    .then(res => {
                        const data = res.data
                        this.qrlabel = data.name
                        this.shorturl = data.text
                        this.shortname = data.type
                    })
                    .catch(() => this.invalidcode = true)
            },
            loadStats(metric = null, loading = null) {
                if (loading === null) {
                    this.loading = this.loadingKeys.map(() => true);
                } else {
                    this.loading[loading] = true;
                }
                const url = metric ? `${this.apiurl}/stats/${metric}` : `${this.apiurl}/stats`;
                return this.authPost({url, params: this.params})
                    .then(resp => {
                        this.stats = {...this.stats, ...resp.data};
                        // Scans timeline
                        if (this.stats.total && this.stats.total[0] && this.stats.total[0].scanstimeline && this.stats.total[0].scanstimeline.length > 0) {
                            const bin = this.stats.total[0].bin
                            const scanstimelineLabels = this.stats.total[0].scanstimeline.map(res => this.getFormattedBin(res.bin, bin));
                            this.scanstimelineData = {
                                labels: scanstimelineLabels,
                                datasets: this.getStackedChartDataset(this.stats.total[0].scanstimeline)
                            };
                        }
                        // Scans by months
                        if (this.stats.bymonth && this.stats.bymonth.length > 0) {
                            const monthsLabels = this.stats.bymonth.map(month => this.getMonthName(month.month - 1));
                            this.monthsData = {
                                labels: monthsLabels,
                                datasets: this.getStackedChartDataset(this.stats.bymonth)
                            };
                        }
                        // Scans by hour
                        if (this.stats.byhour && this.stats.byhour.length > 0) {
                            const hoursLabels = this.stats.byhour.map(hour => this.getFormattedTime(hour.hour));
                            this.hoursData = {
                                labels: hoursLabels,
                                datasets: this.getStackedChartDataset(this.stats.byhour)
                            };
                        }
                        // Scans by OS
                        if (this.stats.byos && this.stats.byos.length > 0) {
                            this.osData = this.getDoughnutChartData(this.stats.byos, 'os');
                        }
                        // Scans by language
                        if (this.stats.bylang && this.stats.bylang.length > 0) {
                            this.langData = this.getDoughnutChartData(this.stats.bylang, 'lang');
                        }
                        // Scans by countries
                        if (this.stats.bycountry && this.stats.bycountry.length > 0) {
                            this.countriesData = this.getDoughnutChartData(this.stats.bycountry, 'country');
                        }
                        // Scans by cities
                        if (this.stats.bycity && this.stats.bycity.length > 0) {
                            const citiesLabels = this.stats.bycity.map(city => city.city);
                            const citiesDataset = this.stats.bycity.map(city => city.cnt);
                            this.citiesData = {
                                labels: citiesLabels,
                                datasets: [
                                    {
                                        backgroundColor: "#12C7D0",
                                        hoverBackgroundColor: "#03949A",
                                        maxBarThickness: 26,
                                        data: citiesDataset
                                    }
                                ]
                            };
                        }
                        if (!loading) {
                            this.loading = this.loadingKeys.map(() => false);
                        } else {
                            this.loading[loading] = false;
                        }
                    })
            },
            update(limit, type, loading) {
                this.params[type].timelimit = limit;
                this.loadStats(type, loading);
            },
            changeTimezone(zone, type, loading) {
                this.params[type].tz = zone;
                this.loadStats(type, loading);
            },
            onRefresh(type, loading) {
                this.loadStats(type, loading);
            }
        }
    }
</script>

<style scoped lang="scss">
.stats {
    .info-icon-wrap {
        width: 50px;
        height: 50px;
        border: 1px solid #FFFFFF;
        border-radius: 50%;
    }
    .v-alert {
        color: #fff;
    }    
}
.smfm-dashboard-card {
    border-radius: 12px;
    .total-scans {
        width: 100%;
        .title {
            font-weight: 600;
            font-size: 42px !important;
            line-height: 63px;
            color: #21252C;
            @media(max-width: 960px) {
                font-size: 22px !important;
                line-height: 33px;
            }
        }
    }
    .loading {
        height: 200px;
        &.lg {
            min-height: 310px;
        }
        &.xl {
            min-height: 385px;
            @media(max-width: 960px) {
                min-height: 355px;
            }
        }
    }
}
</style>