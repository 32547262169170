const hostwithpath = window.location.origin + window.location.pathname
const isPro = window.location.origin === process.env.VUE_APP_PRO_URL || hostwithpath === process.env.VUE_APP_PRO_LOGIN_CALLBACK
const isAdmin = !isPro &&
    (window.location.origin === process.env.VUE_APP_ADMIN_URL || hostwithpath === process.env.VUE_APP_ADMIN_LOGIN_CALLBACK)

const countries = require('@/smfm/countries.json')
const smfm = require('@/smfm/pro.json')
const languagesMap = require('@/smfm/languagesmap.json')

const _parseJwt = function(token) {
    //let keysurl = 'https://cognito-idp.eu-west-1.amazonaws.com/eu-west-1_vDbmrQecY/.well-known/jwks.json'
    let base64Urldecode = function(p) { return atob(p.replace(/-/g, '+').replace(/_/g, '/')) }
    let payload = base64Urldecode(token.split('.')[1]),
        jsonPayload = decodeURIComponent(payload.split('')
            .map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)).join(''))
    return JSON.parse(jsonPayload);
}

const getIdToken = function() {
    try {
        const token = JSON.parse(window.localStorage.token)
        return (token.id_token && token.token_expires > new Date().getTime()) ? token.id_token : null
    } catch (_) {
        return null
    }
}

const idtoken = getIdToken()
const user = idtoken ? _parseJwt(idtoken) : {}

export default {
    //namespaced: true,

    state: () => ({
        user: user,
        isPro: isPro,
        isAdmin: isAdmin,
        countriesNames: Object.freeze(countries),
        smfm: Object.freeze(smfm),
        languagesMap: Object.freeze(languagesMap),
    }),

    getters: {
        user(state) { return state.user },
        countriesNames(state) { return state.countriesNames },
        smfm(state) { return state.smfm },
        isPro(state) { return state.isPro },
        isAdmin(state) { return state.isAdmin },
        languagesMap(state) { return state.languagesMap },
    },

    mutations: {
        _setUser(state, payload) {
            state.user = payload
        },
    },

    actions: {
        setUserFromIdToken({commit}, idtoken) {
            commit('_setUser', idtoken ? _parseJwt(idtoken) : {})
        },

        syncUserWithLocalStorage({commit, getters}) {
            const idtoken = getIdToken()
            if (idtoken && (!this.user || getters.user.idtoken !== idtoken)) {
                commit('_setUser', {idtoken, ..._parseJwt(idtoken)})
            } else if (!idtoken && getters.user.idtoken) {
                commit('_setUser', {})
            }
        }
    }
}
