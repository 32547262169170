<template>
  <v-toolbar flat :height="'extended'"
    class="dashboard-toolbar">
    <div class="d-flex justify-start align-center">
      <span class="card-title">{{ title }}</span>
      <article-link v-if="withInfo" article="about_statistics" class="ms-2" :disabled="!hasData" :show-title="true">
          <v-icon class="info-icon" :disabled="!hasData">{{ iconInfo }}</v-icon>
      </article-link>
      <!-- Refresh button -->
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">   
          <v-btn v-bind="attrs" v-on="on"
            x-small outlined fab
            :aria-label="$t('pro.refresh')"
            class="ms-3"
            :disabled="!hasData"
            @click="$emit('refresh')">
            <v-icon color="#393E46">{{ iconRefresh }}</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('pro.refresh') }}</span>
      </v-tooltip>
    </div>
    <v-spacer></v-spacer>
    <!-- Time limits -->
    <pro-time-limits v-if="withList" :by-month="byMonth"
      :has-data="hasData"
      :field="field"
      @selected="$emit('change', $event)">
    </pro-time-limits>
    <div v-if="withBtns" class="d-flex flex-wrap justify-end align-center ms-auto">
      <div v-for="limit in timelimits" :key="limit.value">
        <v-btn small
          class="time-limit ms-2"
          :class="{'selected': limit.value === selected}"
          :disabled="!hasData"
          @click="$emit('change', limit.value); selected = limit.value">
          {{ limit.title }}
        </v-btn>
      </div>
    </div>
    <!-- Downlaod CSV button -->
    <v-btn v-if="withCSV && scans && scans.length > 0"
      text class="download"
      @click="downloadCSV">
      <v-icon class="me-3">{{ iconDownload }}</v-icon>
      {{ $t('pro.dashboard.downloadascsv') }}
    </v-btn>
  </v-toolbar>
</template>

<script>
    import { mdiInformation } from '@mdi/js';
    import userMixin from "@/mixins/userMixin";
    import ProTimeLimits from "@/components/pro/dashboard/ProTimeLimits";
    import customIcons from "@/mixins/customIcons";
    import ArticleLink from "@/components/forms/ArticleLink";

    export default {
        name: "ProDashboardToolbar",
        components: { ProTimeLimits, ArticleLink },
        mixins: [ userMixin, customIcons ],
        props: {
            hasData: {
                type: Boolean,
                default: true
            },
            title: {
                type: String,
                default: '',
            },
            withList: {
                type: Boolean,
                default: true,
            },
            withBtns: {
                type: Boolean,
                default: false,
            },
            withCSV: {
                type: Boolean,
                default: false,
            },
            withInfo: {
                type: Boolean,
                default: false,
            },
            scans: {
                type: Array,
                default: () => []
            },
            byMonth: {
                type: Boolean,
                default: () => false
            },
            field: {
                type: String,
                default: ''
            },
        },
        data() {
            return {
                timelimits: [
                    { title: this.$t('pro.dashboard.last24h'), value: '24h' },
                    { title: this.$t('pro.dashboard.lastweek'), value: '7d' },
                    { title: this.$t('pro.dashboard.lastmonth'), value: '30d' },
                    { title: this.$t('pro.dashboard.alltime'), value: 'all' }
                ],
                selected: null,
            }
        },
        computed: {
            iconInfo() { return mdiInformation },
        },
        methods: {
            downloadCSV() {
                const url = this.$route.name === 'dashboard' ? 
                    'pro/stats/scans/download' : `pro/codes/${this.$route.params.codeid}/stats/scans/download`;
                this.authPost({ url, params: {'scans': {'limit': 100}} })
                    .then((resp) => {
                        const filename = 'Last_scans_table.csv';
                        var blob = new Blob([resp.data], { type: 'text/csv;charset=utf-8;' });
                        if (navigator.msSaveBlob) { // IE 10+
                            navigator.msSaveBlob(blob, filename);
                        } else {
                            var link = document.createElement("a");
                            if (link.download !== undefined) { // feature detection
                                // Browsers that support HTML5 download attribute
                                var url = URL.createObjectURL(blob);
                                link.setAttribute("href", url);
                                link.setAttribute("download", filename);
                                document.body.appendChild(link);
                                link.click();
                                document.body.removeChild(link);
                            }
                        }
                    })
                    .catch(e => console.error(e))
            }
        },
        mounted() {
            if (this.$route.name === 'dashboard') {
                this.selected = this.$store.getters['dashboard_pro/getSelectedParam']({ field: 'total', type: 'timelimit' });
            } else {
                this.selected = 'all';
            }
        }
    }
</script>

<style scoped lang="scss">
.dashboard-toolbar {
  box-shadow: inset 0px -1px 0px #EEEEEE !important;
  &.v-sheet {
    background: #FAFAFA !important;
    color: #393E46;
    font-weight: 500;
    .v-btn--fab {
      color: #eee;
      &:hover {
        color: #393E46;
      }
    }
    .info-icon {
      color: #C7C8CA;
      &:hover {
        color: #393E46;
      }
    }
  }
  .time-limit {
    background: transparent;
    border-radius: 8px;
    color: #909295;
    font-size: 14px;
    line-height: 21px;
    &:hover {
      background: #EEEEEE;
      color: #393E46;
    }
    &.v-btn--disabled {
      background-color: transparent !important;
    }
    &.selected {
      background: #00ADB5 !important;
      color: #fff !important;
      &.v-btn--disabled {
        opacity: 0.5;
      }
    }
  }
  .download {
    color:  #03949A;
    cursor: pointer;
    .v-icon {
      color: #03949A;
    }
    &:hover {
      color:#393E46;
      .v-icon {
        color: #393E46;
      }
    }
  }
  @media(max-width: 960px) {
    .card-title {
      font-size: 14px;
    }
  }
}
</style>