<template>
    <div @drop.prevent="addDropFile" @dragover.prevent ref="drag" :class="{over: isOver}" class="file-uploader smfm-uploader-green-box" @click="inputTrigger">
        <div class="file-uploader-body" v-if="!dense">
            <v-progress-circular v-if="ui.uploading"
                                 indeterminate
                                 color="#03949A"
                                 :aria-label="$t('app.loading')"
            ></v-progress-circular>
            <img src="/images/icon-presets-empty.svg" v-if="section === 'preset'">
            <img src="/images/icon-logo-empty.svg" v-if="section === 'logo'">
            <img src="/images/icon-template-empty.svg" v-if="section === 'template'">
            <span class="uploader-green-text">{{ text }}</span>
            <v-btn class="file-uploader-btn" icon>
                <v-icon color="white" class="me-1">{{ iconAttachment }}</v-icon>
                {{ $t('pro.library.upload') }}
            </v-btn>
        </div>
        <div class="file-uploader-body simple" v-else>
            <v-progress-circular v-if="ui.uploading"
                indeterminate
                color="#03949A"
                :aria-label="$t('app.loading')"
            ></v-progress-circular>
            <v-icon color="#03949A">{{ iconAttachment }}</v-icon>
            <span class="uploader-green-text">{{ text }}</span>
        </div>
        <v-file-input v-model="file" v-bind="$attrs"
                      :chips="true" ref="myfile"
                      class="file-uploader-input"
                      @change="uploadFile" :loading="loading" :disabled="loading">
        </v-file-input>
    </div>
</template>

<script>
    import userMixin from "@/mixins/userMixin";
    import {mdiAttachment} from '@mdi/js';

    export default {
        name: "InputFile",
        mixins: [userMixin],
        props: {
            dense: {
                type: Boolean,
                default: false
            },
            text: {String},
            section: {
                type: String,
                default: 'preset'
            }
        },
        data: function() {
            return {
                file: null,
                isOver: false,
                loading: false,
                iconAttachment: mdiAttachment,
                ui: {
                    uploading: false
                }
            }
        },
        mounted () {
            // add the needed event listeners to the container
            this.$refs.drag.addEventListener("dragenter", () => this.showDropTarget());
            this.$refs.drag.addEventListener("dragover", () => this.showDropTarget());
            this.$refs.drag.addEventListener("dragleave", () => this.hideDropTarget());
        },
        methods: {
            showDropTarget() {
                if (this.loading) return
                if (!this.isOver) this.isOver = true
            },

            hideDropTarget() {
                if (this.loading) return
                if (this.isOver) this.isOver = false
            },

            addDropFile(e) {
                if (this.loading) return
                this.hideDropTarget()
                this.file = e.dataTransfer.files[0];

                this.uploadFile()
            },
            uploadFile() {
                const name = this.file ? this.file.name : null,
                      type = this.file ? this.file.type : null

                const doupload = apipath => {
                          this.loading = true
                          this.ui.uploading = true

                          let apiData = {}
                          return this.authPost({url: apipath, params: {filename: name, type}})
                              .then(resp => {
                                  apiData = resp.data
                                  return this.s3Put({url: apiData.uploadurl, body: this.file, headers: apiData.uploadheaders})
                              })
                              .then(() => ({path: apiData.path, name: apiData.name, type}) )
                              // TODO display proper error message
                              .catch(txt => console.error('Something went wrong: ' + txt))
                      },
                      doreset = () => {
                          this.loading = false
                          this.ui.uploading = false
                          this.$nextTick(() => this.file = null)
                      }

                this.$emit('change', {file: this.file, upload: doupload, reset: doreset})
            },
            inputTrigger() {
                this.$refs.myfile.$refs.input.click();
            },
            completeUploading() {
                this.ui.uploading = false
            }
        },
        beforeDestroy() {
            this.ui.uploading = false
        }
    }
</script>

<style lang="scss" scoped>
.file-uploader {
    position: relative;

    .v-progress-circular {
        width: 24px!important;
        height: 24px!important;
    }

    &-btn {
        max-width: 150px;
        width: 100%;
        height: 48px;
        background: #00ADB5;
        border-radius: 12px;
        margin-top: 10px;

        &::v-deep {
            .v-btn__content {
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: #FFFFFF;
            }
        }
    }

    &-body {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        &.simple {
            justify-content: start;
            flex-direction: row;

            span {
                margin-left: 10.5px;
            }
        }
    }

    &-input {
        position: absolute;
        display: none;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        z-index: 2;
    }
}
</style>
