<template>
  <v-menu v-if="selected" offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn text v-bind="attrs" v-on="on"
        :ripple="false"
        :disabled="!hasData"
        class="time-limits ms-auto">
          {{ selected.title }}
          <v-icon>
            {{ attrs["aria-expanded"] === "true" ? iconChevronUp : iconChevronDown }}
          </v-icon>
      </v-btn>
    </template>
    
    <v-list dense width="160">
      <v-list-item v-for="limit in timelimits" :key="limit.value"
          @click="selectTimeLimit(limit)">
          <v-list-item-title class="text-body-1 font-weight-medium">{{ limit.title }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
    import { mdiChevronDown, mdiChevronUp } from '@mdi/js';

    export default {
        name: "ProTimeLimits",
        props: {
            hasData: {
                type: Boolean,
                default: true
            },
            byMonth: {
                type: Boolean,
                default: () => false
            },
            field: {
                type: String,
                default: ''
            },
        },
        data() {
            return {
                selected: null,
            }
        },
        computed: {
            timelimits() {
                if (!this.byMonth) {
                    return [
                        { title: this.$t('pro.dashboard.alltime'), value: 'all' },
                        { title: this.$t('pro.dashboard.lastmonth'), value: '30d' },
                        { title: this.$t('pro.dashboard.lastweek'), value: '7d' }
                    ]
                } else {
                    return [
                        { title: this.$t('pro.dashboard.alltime'), value: 'all' },
                        { title: this.$t('pro.dashboard.lastyear'), value: '365d' }
                    ]
                }
            },
            iconChevronDown() { return mdiChevronDown },
            iconChevronUp() { return mdiChevronUp },
        },
        methods: {
            selectTimeLimit(timelimit) {
                this.selected = timelimit;
                this.$emit('selected', timelimit.value);
            }
        },
        mounted() {
            if (this.$route.name === 'dashboard') {
                this.selected = this.timelimits.find(limit => limit.value === this.$store.getters['dashboard_pro/getSelectedParam']({ field: this.field, type: 'timelimit' }));
            } else {
                this.selected = this.timelimits[0];
            }
        }
    }
</script>

<style scoped lang="scss">
.v-btn.time-limits {
    height: auto !important;
    font-size: 14px !important;
    line-height: 21px;
    color: #393E46 !important;
    padding-inline-end: 0 !important;
}
</style>