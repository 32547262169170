import moment from "moment";
// eslint-disable-next-line no-unused-vars
import momentTZ from 'moment-timezone'
import axios from "axios";
const AWSCognito = require('aws-sdk/clients/cognitoidentityserviceprovider')
const hostwithpath = window.location.origin + window.location.pathname
const isPro = window.location.origin === process.env.VUE_APP_PRO_URL || hostwithpath === process.env.VUE_APP_PRO_LOGIN_CALLBACK
const isAdmin = !isPro &&
    (window.location.origin === process.env.VUE_APP_ADMIN_URL || hostwithpath === process.env.VUE_APP_ADMIN_LOGIN_CALLBACK)

const serialize = function(obj) {
    let str = [];
    for (let p in obj)
        if (Object.prototype.hasOwnProperty.call(obj, p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
    return str.join("&");
}

const getIdToken = function() {
    try {
        const token = JSON.parse(window.localStorage.token)
        return (token.id_token && token.token_expires > new Date().getTime()) ? token.id_token : null
    } catch (_) {
        return null
    }
}

const rememberLang = function() {
    let searchParams = new URLSearchParams(window.location.search),
        lang = searchParams.get('lang')
    if (lang) {
        window.localStorage.preferredLang = lang
    }
}

export default {
    computed: {
        currentUser() {
            return this.$store.getters.user
        },

        userInitials() {
            let names = this.currentUser.name.split(' ')
            let initials = names[0].substring(0, 1).toUpperCase()

            if (names.length > 1) {
                initials += names[names.length - 1].substring(0, 1).toUpperCase()
            }
            return initials
        },

        cognitoLoginRedirect() {
            if (isAdmin) {
                const cognitoredirect = process.env.VUE_APP_ADMIN_LOGIN_CALLBACK
                return cognitoredirect ? cognitoredirect : (process.env.VUE_APP_ADMIN_URL + '/admin_login_callback')
            }
            const cognitoredirect = process.env.VUE_APP_PRO_LOGIN_CALLBACK
            return cognitoredirect ? cognitoredirect : (process.env.VUE_APP_PRO_URL + '/login_callback')
        },

        isLoggedIn() {
            return !!this.currentUser.email
        },

        userLang() {
            if (!this.languages || !this.languagesMenu || Object.keys(this.languagesMenu).length <= 1) {
                // Languages have not loaded yet.
                return 'en'
            }
            const lang = this.currentUser['locale']
            return lang && lang in this.languagesMenu ? lang : this.detectBrowserLang()
        },

        userPlan() {
            const isTrial = this.currentUser['custom:plan'] === 'trial'
            const planEnds = parseInt(this.currentUser['custom:planends'])
            const planRenews = parseInt(this.currentUser['custom:planrenews'])
            const curTime = Math.round((new Date().getTime())/1000)
            const planEnded = this.currentUser['custom:plan'] && !planRenews && planEnds && planEnds < curTime
            let plans = this.pricePlans.filter(plan => plan.shortname === this.currentUser['custom:plan'] || (isTrial && plan.useintrial))
            if (!planEnded && this.currentUser['custom:plan'] && plans.length) {
                return {
                    shortname: this.currentUser['custom:plan'],
                    ends: planEnds,
                    renews: planRenews,
                    canSignUp: isTrial,
                    canStartTrial: false,
                    features: plans[0].features,
                    active: true,
                    isTrial: isTrial,
                }
            } else if (planEnded) {
                // Plan ended.
                return {
                    shortname: this.currentUser['custom:plan'],
                    canStartTrial: false,
                    canSignUp: true,
                    active: false,
                    ended: true,
                    isTrial: isTrial,
                }
            } else {
                // First login, there is no plan yet.
                return {
                    canStartTrial: true,
                    canSignUp: true,
                    active: false,
                }
            }
        }
    },

    methods: {
        __setToken(token) {
            let data = Object.assign({}, token)
            data['token_expires'] = data['expires_in'] ? (new Date().getTime() + parseInt(data['expires_in']) * 1000) : null
            window.localStorage.token = JSON.stringify(data)
            if (this.$store && this.$store.dispatch) {
                this.$store.dispatch('syncUserWithLocalStorage')
            }
            window.localStorage.refreshingtoken = null
        },

        requireLogin() {
            const token = getIdToken()
            if (!token) {
                rememberLang()
                // Redirect to Cognito login
                window.location.href = process.env.VUE_APP_LOGIN_URL +
                    "/login?response_type=code&client_id=" + process.env.VUE_APP_COGNITO_CLIENT +
                    "&redirect_uri=" + this.cognitoLoginRedirect
            } else {
                // We already have a token, refresh user info and check token validity
                return this.refreshToken(true)
            }
        },

        logout() {
            this.__setToken({})
            // Redirect to Cognito logout that will redirect to appurl
            window.location.href = process.env.VUE_APP_LOGIN_URL +
                "/logout?client_id=" + process.env.VUE_APP_COGNITO_CLIENT +
                "&logout_uri=" + encodeURIComponent(this.appUrl)
        },

        loginCallback(queryString) {
            let params = new URLSearchParams(queryString),
                code = params.get('code')

            if (!code) {
                this.__failedToConnect()
                return
            }

            return axios.post(process.env.VUE_APP_LOGIN_URL + '/oauth2/token', serialize({
                grant_type: 'authorization_code',
                client_id: process.env.VUE_APP_COGNITO_CLIENT,
                redirect_uri: this.cognitoLoginRedirect,
                code
            }), {headers: {'Content-type': 'application/x-www-form-urlencoded'}})
                .then(resp => {
                    this.__setToken(resp.data)
                })
                .catch(() => this.__failedToConnect())
        },

        __failedToConnect() {
            // TODO display a notification with a button to relogin
            this.__setToken({})
            this.requireLogin()
        },

        refreshUser() {
            return this.refreshToken(true)
        },

        refreshToken(force = false) {
            //console.log('Refreshing token')
            let refresh_token, expires_in
            if (this.$store && this.$store.dispatch) { this.$store.dispatch('syncUserWithLocalStorage') }
            try {
                const token = JSON.parse(window.localStorage.token)
                refresh_token = token.refresh_token
                expires_in = parseInt(token.token_expires) - (new Date().getTime())
            } catch (e) {
                return this.__failedToConnect()
            }
            if (!refresh_token || expires_in <= 0) {
                return this.__failedToConnect()
            }

            if (parseInt(window.localStorage.refreshingtoken) === 1 || (!force && expires_in > 60 * 60 * 1000)) {
                // 1. Avoid simultaneous requests to refresh token
                // 2. Do not refresh token if there is more than one hour left until the current token expires
                return Promise.resolve()
            }
            // Time to refresh the token.
            window.localStorage.refreshingtoken = 1
            return axios.post(process.env.VUE_APP_LOGIN_URL + '/oauth2/token', serialize({
                grant_type: 'refresh_token',
                client_id: process.env.VUE_APP_COGNITO_CLIENT,
                refresh_token: refresh_token
            }), {headers: {'Content-type': 'application/x-www-form-urlencoded'}})
                .then(resp => this.__setToken(Object.assign({refresh_token}, resp.data)))
                .catch(() => this.__failedToConnect())
        },

        /**
         * Update user attributes
         *
         * accepts attributes [{Name: 'xxx', Value: 'yyy'}]
         * https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/CognitoIdentityServiceProvider.html#updateUserAttributes-property
         *
         * @param attrs
         * @returns {Promise<unknown>}
         */
        updateUser(attrs) {
            return new Promise((resolve, reject) => {
                const token = JSON.parse(window.localStorage.token)
                let sp = new AWSCognito({region: process.env.VUE_APP_COGNITO_REGION})
                sp.updateUserAttributes({
                    AccessToken: token.access_token,
                    UserAttributes: attrs
                },  (err, data) => {
                    if (err) {
                        // an error occurred
                        return reject(err)
                    } else {
                        // successful response - request new id token
                        return this.refreshUser()
                            .then(() => resolve(data))
                    }
                })
            })
        },

        /**
         * Checks if current user is allowed to change the password (did not login with identity provider)
         *
         * @returns {boolean}
         */
        canChangePassword() {
            return !('identities' in this.currentUser && this.currentUser.identities.length > 0)
        },

        /**
         * Change user password
         *
         * @param oldpassword
         * @param newpassword
         * @returns {Promise<unknown>}
         */
        changePassword(oldpassword, newpassword) {
            // Precheck that:
            // - this.canChangePassword() returns true
            // - both old and new passwords are more than 6 characters long,
            // - both old and new passwords do not start and end with whitespaces;
            return new Promise((resolve, reject) => {
                let sp = new AWSCognito({region: process.env.VUE_APP_COGNITO_REGION})
                const token = JSON.parse(window.localStorage.token)
                sp.changePassword({
                    AccessToken: token.access_token,
                    PreviousPassword: oldpassword,
                    ProposedPassword: newpassword
                },  (err, data) => {
                    if (err) {
                        // an error occurred
                        // err.__type == 'InvalidParameterException' - one of the passwords does not satisfy constraints (length, regex ^[\S]+.*[\S]+$ )
                        // err.__type == 'NotAuthorizedException'  -> current password is not correct
                        return reject(err)
                    } else {
                        // successful response - request new id token
                        return this.refreshUser()
                            .then(() => resolve(data))
                    }
                })
            })
        },

        loadStripeLibrary() {
            return new Promise(resolve => {
                const src = 'https://js.stripe.com/v3/'
                let stripeScript = document.head.querySelector(`script[src="${src}"]`)
                if (!stripeScript) {
                    stripeScript = document.createElement('script')
                    stripeScript.setAttribute('src', src)
                    document.head.appendChild(stripeScript)
                }
                if (typeof Stripe !== 'undefined') {
                    resolve()
                } else {
                    stripeScript.onload = () => resolve()
                }
            })
        },

        authGet({url, params}) {
            this.refreshToken()
            let fullurl = process.env.VUE_APP_API + '/' + url + (params ? ('?' + serialize(params)) : '')
            return axios.get(fullurl, {headers: {'Authorization': 'Bearer ' + getIdToken()}})
        },

        authGetBinary({url, params}) {
            this.refreshToken()
            let fullurl = process.env.VUE_APP_API + '/' + url + (params ? ('?' + serialize(params)) : '')
            return axios.get(fullurl, {
                responseType: 'arraybuffer',
                headers: {
                    'Authorization': 'Bearer ' + getIdToken(),
                    'Accept': 'image/png,image/jpeg,application/pdf,application/postscript,application/zip,*/*',
                }
            })
        },

        authDelete({url}) {
            // Delete method does not really work with parameters - use POST instead.
            this.refreshToken()
            let fullurl = process.env.VUE_APP_API + '/' + url,
                data = {headers: {'Authorization': 'Bearer ' + getIdToken()}}
            return axios.delete(fullurl, data)
        },

        authPost({url, params, headers}) {
            this.refreshToken()
            let fullurl = process.env.VUE_APP_API + '/' + url
            headers = headers ? headers : {}
            headers['Authorization'] = 'Bearer ' + getIdToken()
            return axios.post(fullurl, params, {headers: headers})
        },

        authPostBinary({url, params}) {
            this.refreshToken()
            let fullurl = process.env.VUE_APP_API + '/' + url
            return axios.post(fullurl, params, {
                responseType: 'arraybuffer',
                headers: {
                    'Authorization': 'Bearer ' + getIdToken(),
                    'Accept': 'image/png,image/jpeg,application/pdf,application/postscript,application/zip,*/*',
                }
            })
        },

        authPatch({url, params, headers}) {
            this.refreshToken()
            let fullurl = process.env.VUE_APP_API + '/' + url
            headers = headers ? headers : {}
            headers['Authorization'] = 'Bearer ' + getIdToken()
            return axios.patch(fullurl, params, {headers: headers})
        },

        authPut({url, params}) {
            this.refreshToken()
            let fullurl = process.env.VUE_APP_API + '/' + url
            return axios.put(fullurl, params, {headers: {'Authorization': 'Bearer ' + getIdToken()}})
        },

        /**
         * A promise to upload something to S3
         *
         * @param {String} url URL (required)
         * @param {} body normally a file input from a form
         * @param {Object} headers Http headers
         */
        s3Put({url, body, headers}) {
            return new Promise((resolve, reject) => {
                let xhr = new XMLHttpRequest();
                xhr.open("PUT", url);
                Object.keys(headers ? headers : {}).forEach(key => xhr.setRequestHeader(key, headers[key]));
                xhr.onload = () => (xhr.status >= 200 && xhr.status < 300) ? resolve(xhr.response) : reject(xhr.statusText);
                xhr.onerror = () => reject(xhr.statusText);
                xhr.send(body);
            });
        },

        formatDateTime(date, userTimezone = true) {
            let tz = userTimezone ? this.currentUser['zoneinfo'] : 'UTC'
            tz = tz ? tz : Intl.DateTimeFormat().resolvedOptions().timeZone
            return moment(date)
                .tz(tz)
                .format(this.currentUser['custom:dateformat'] + ' ' + this.currentUser['custom:timeformat'])
        },

        formatDate(date) {
            let tz = this.currentUser['zoneinfo']
            tz = tz ? tz : Intl.DateTimeFormat().resolvedOptions().timeZone
            return moment(date)
                .tz(tz)
                .format(this.currentUser['custom:dateformat'])
        },

    }
}
