<template>
<!--        <v-btn @click="savePreset('new preset')"><slot></slot></v-btn>-->

        <simple-dialog persistent :max-width="maxWidth" :header="$t('pro.library.presets.savetitle')"
                       :no-title="true" ref="dialog" :show-cancel="!loading" v-if="existingfile || (currentqr && currentqr.image)">
            <template v-slot:activator="{}">
                <slot name="activator" :open="onOpen"></slot>
            </template>
            <v-progress-linear v-if="loading" indeterminate color="blue" class="mb-0 mt-2"></v-progress-linear>
            <v-row v-else>
                <v-col sm="4" cols="12">
                    <div
                         data-role="svg" v-html="currentqr.image"
                         class="d-flex justify-center align-center qr-preview">
                    </div>
                </v-col>
                <v-col sm="8" cols="12">
                    <div class="mb-6">{{ $t('pro.library.presets.savedesc') }}</div>
                    <v-text-field outlined v-model="newfilename" :label="$t('pro.library.presets.newname')"></v-text-field>
                </v-col>
            </v-row>
            <template v-slot:buttons>
                <v-btn :disabled="!fileNameValid"
                    class="smfm-primary-btn smfm-lg-btn"
                    :class="loading ? ['d-none'] : []"
                    @click="savePreset">
                    {{ $t('app.save') }}
                </v-btn>
            </template>
        </simple-dialog>
</template>

<script>
    import { mapActions, mapGetters } from "vuex";
    import qrdownload from "@/mixins/qrdownload";
    import SimpleDialog from "@/components/forms/SimpleDialog";
    import userMixin from "@/mixins/userMixin";

    export default {
        name: "ProSavePreset",
        components: {SimpleDialog},
        mixins: [qrdownload, userMixin],
        props: {
            config: {type: Object},
            existingfile: null,
        },
        data() {
            return {
                newfilename: 'New preset',
                loading: false,
            }
        },
        computed: {
            ...mapGetters({currentqr: 'getCurrentQr'}),
            fileNameValid() {
                return this.newfilename && this.newfilename.length >= 2 // TODO more validation? Use API
            },
            maxWidth() {
                return this.loading ? '400px' : '600px'
            },
        },
        methods: {
            ...mapActions(['refreshUserLibrary']),
            onOpen() {
                this.$refs.dialog.open()
                // If we are editing the existing file, skip the file name prompt, go straight to uploading.
                if (this.existingfile) this.savePreset()
            },
            savePreset() {

                this.loading = true
                const config = JSON.parse(JSON.stringify(this.config))
                delete config['preset']
                let apidata
                let promise
                if (this.existingfile) {
                    promise = this.authPatch({
                        url: (this.isAdmin ? 'admin/' : 'pro/') + 'library/presets/' + this.existingfile,
                        params: {metadata: {config: JSON.stringify(config)}, file: true}
                    })
                } else {
                    // Always pro, there is no option for admin to "create a new preset".
                    promise = this.authPost({
                        url: 'pro/library/presets',
                        params: {config: config, filename: this.newfilename + '.png', type: 'image/png'}
                    })
                }

                promise
                    .then(resp => {
                        apidata = resp.data
                        if (this.existingfile) {
                            this.refreshUserLibrary({section: 'preset', modified: this.existingfile})
                        } else {
                            this.refreshUserLibrary({section: 'preset', uploaded: apidata.name})
                        }
                        // TODO show a notice if the actual file name was different (apidata.name!==this.newfilename + '.png')
                        this.$emit('saved')
                    })
                    // TODO language string!
                    .catch(txt => console.error('Something went wrong: ' + txt))
                    .finally(() => {
                        if (this.$refs.dialog) {
                            this.$refs.dialog.close()
                        }
                        this.loading = false
                    })
            },
        },
    }
</script>

<style scoped>
::v-deep div.qr-preview[data-role=svg] > svg {
    width: 100%;
    height: 100%;
}
</style>
