<template>
  <v-dialog class="edit-name"
    v-model="showDialog"
    scrollable
    max-width="500">
    <v-card class="smfm-dialog-with-header">
      <v-toolbar flat class="smfm-page-subheading">
          {{ $t('pro.library.editname') }}
        <v-spacer></v-spacer>
        <v-btn icon @click="closeDialog">
          <v-icon color="secondary">{{ iconClose }}</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="px-0" :class="[$vuetify.breakpoint.smAndDown ? 'py-5' : 'px-5 py-5']">
        <pseudo-form>
          <v-text-field outlined name="fileName" :label="$t('pro.library.name')" :rules="[max50chars]" :suffix="fileExtension" v-model="fileNameFormatted">
          </v-text-field>
        </pseudo-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-end align-center pt-5"
                      :class="[$vuetify.breakpoint.smAndDown ? 'px-5 pb-7' : 'px-10 pb-10']">
        <v-btn  text color="secondary" @click="closeDialog">
          {{ $t('app.cancel') }}
        </v-btn>
        <v-btn @click="renamed" class="smfm-primary-btn smfm-lg-btn">{{ $t('app.save') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
    import { mdiClose } from '@mdi/js';
    import userMixin from "@/mixins/userMixin";
    import PseudoForm from "@/components/forms/PseudoForm";
    export default {
        name: 'EditName',
        mixins: [userMixin],
        components: {PseudoForm},
        props: {
            fileName: {
                type: String,
                required: true
            },
            dialogVisible: {
                type: Boolean,
                default: false,
            },
            section: { type: String },
            fullFileName: { type: String },
            apiPath: { type: String },
            hideExtension: { type: Boolean, default: false }
        },
        data() {
            return {
                newName: '',
                oldName: this.fileName,
                max50chars: v => v.length <= 50 || this.$t('pro.library.filenametoolong'),
            }
        },
        computed: {
            iconClose() {
                return mdiClose;
            },
            fileNameFormatted: {
                get() {
                    return this.fileName.split('/').reverse()[0].replace(/\.[^/.]+$/, "");
                },
                set(newVal) {
                    this.newName = newVal.split('/').reverse()[0].replace(/\.[^/.]+$/, "")
                }
            },
            showDialog: {
                get() {
                    return this.dialogVisible;
                },
                set(newValue) {
                    if(!newValue) this.$emit('close');
                }
            },
            fileExtension() {
                let re = /(?:\.([^.]+))?$/;
                return !this.hideExtension && re.exec(this.fileName)[1] ? '.' + re.exec(this.fileName)[1] : ''
            }
        },
        methods: {
            closeDialog() {
                this.showDialog = false;
            },
            renamed() {
                let newName = this.newName,
                    oldName = this.fileName
                if (!newName.length) return
                newName = newName + oldName.replace(/^.*(\.[^/.]+)$/, (a, b) => b)
                let file
                this.authPatch({url: `${this.apiPath}/${oldName}`, params: {name: newName}})
                    .then(resp => file = resp.data)
                    .then((resp) => {
                        this.$emit('updateUserLibrary', {section: this.section, renamed: oldName, newName: resp.name})
                        this.closeDialog()
                    })
                    .then(() => this.value = file.name)
                    .catch(e => this.$emit('error', e))
            },
        }
    }
</script>

<style lang="scss" scoped>
.edit-name {
  &::v-deep {
    .v-card {
      padding: 40px;
    }
  }
}
</style>