export default {
  namespaced: true,

  state: () => ({
    settings: {
      selectedFormat: 'png',
      selectedUnits: 'px',
      selectedSizeIndex: 1,
      customSize: 640,
      qrdpi: 150,
      isAdvanced: false,
      pagesize: '210x297',
      pageorientation: 'portrait',
      marginleft: 0,
      marginright: 0,
      margintop: 0,
      marginbottom: 0,
      repeat: 1,
      spacingx: 0,
      spacingy: 0,
      custompagewidth: 0,
      custompageheight: 0,
      showcutlines: false,
    },
  }),

  getters: {
    downloadSettings(state) { return state.settings },
  },

  mutations: {
    _setQrDownloadPreferences(state, payload) {
      state.settings = { ...payload }
    }
  },

  actions: {
    setQrDownloadPreferences({commit}, data) {
      commit('_setQrDownloadPreferences', data)
    }
  }
}