export default {
  namespaced: true,

  state: () => ({
    QRCodes: [],
    itemsPerPage: 10,
    tagFilters: [],
  }),

  getters: {
    QRCodes(state) { return state.QRCodes },
    itemsPerPage(state) { return state.itemsPerPage },
    tagFilters(state) { return state.tagFilters },
  },

  mutations: {
    _setDynamicCodes(state, payload) {
      state.QRCodes = payload.map((code) => {
        return {
          ...code,
          tags: code.tags && code.tags.length > 0 ? code.tags.sort() : []
        }
      })
    },
    _addTag(state, payload) {
      const code = state.QRCodes.find(code => code.codeid === payload.codeid)
      if (!code.tags) code.tags = []
      payload.tags.forEach(tag => code.tags.push(tag))
    },
    _deleteTag(state, payload) {
      const code = state.QRCodes.find(code => code.codeid === payload.codeid)
      const index = code.tags.findIndex(tag => tag === payload.tag)
      code.tags.splice(index, 1)
    },
    _setItemsPerPage(state, payload) {
      state.itemsPerPage = payload
    },
    _setTagFilters(state, payload) {
      state.tagFilters = payload
    }
  },

  actions: {
    setDynamicCodes({commit}, data) {
      commit('_setDynamicCodes', data)
    },
    addTag({commit}, data) {
      commit('_addTag', data)
    },
    deleteTag({commit}, data) {
      commit('_deleteTag', data)
    },
    setItemsPerPage({commit}, data) {
      commit('_setItemsPerPage', data)
    },
    setTagFilters({commit}, data) {
      commit('_setTagFilters', data)
    }
  }
}