<template>
    <div class="previewimage" v-intersect="loadImage">
        <div v-if="loaded" ref="svg" data-role="svg" v-html="image"></div>
        <div v-if="!loaded">
            <img :src="iconMdiQrcode" alt="">
        </div>
    </div>
</template>

<script>
    import { mdiQrcode } from "@mdi/js";
    import userMixin from "@/mixins/userMixin";
    import qrdownload from "@/mixins/qrdownload";

    export default {
        name: "ProQrImage",
        mixins: [userMixin, qrdownload],
        props: {
            codeid: null,
            qrurl: null,
            qrlabel: null,
        },
        data() {
            return {
                loaded: false,
                loading: false,
                image: null,
                width: 0,
                height: 0,
            }
        },
        computed: {
            iconMdiQrcode: function() {
                // Loading icon.
                const svg = '<svg xmlns="http://www.w3.org/2000/svg" style="width:54px;height:54px" viewBox="0 0 100 100" height="54px" width="54px">'+
                    '<g transform="scale(4.17)"><path fill="#aaa" d="' + mdiQrcode + '" /></g></svg>'
                return 'data:image/svg+xml;base64,'+this.base64encode(svg);
            },
            src() {
                // TODO no longer used
                console.error('Function ProQrImage::src() should not be called any more!')
                return this.loaded ? `data:image/svg+xml;base64,${this.base64encode(this.$refs.svg.innerHTML)}` : null
            },
            summary() {
                return {codeid: this.codeid, image: this.image, width: this.width, height: this.height,
                        qrlabel: this.qrlabel, qrurl: this.qrurl, loading: !this.loaded}
            }
        },
        methods: {
            loadImage() {
                if (this.loaded || this.loading) return
                this.loading = true
                if (this.qrurl) {
                    this.authGet({url: this.qrurl})
                        .then(resp => {
                            this.image = resp.data
                            this.loaded = true
                            this.loading = false
                            this.$emit('loaded')
                        })
                }
            },
        }
    }
</script>

<style lang="scss">
.previewimage {
    width: 54px;
    height: 54px;
    div[data-role="svg"] > svg {
        max-width: 54px;
        max-height: 54px;
    }
}
</style>
