<template>
    <v-row>
        <v-col md="12" lg="8" cols="12" class="smfm-pro-cards ps-0 pb-4">
            <!-- Presets Section -->
            <v-card flat :tile="$vuetify.breakpoint.smAndDown"
                :class="[$vuetify.breakpoint.smAndDown ? 'pa-5' : 'pa-7']">
                <div class="d-flex align-center smfm-page-heading font-weight-medium mb-2">
                    <v-icon color="#21252C" class="me-3">{{ iconAutoFix }}</v-icon>
                    {{ $t('pro.library.presets.title') }}
                </div>
                <div class="smfm-pro-subtitle mb-5" v-if="!isAdmin">
                    {{ $t('pro.library.presets.subtitle') }}
                    <article-link :direct-link="true" article="about_presets">
                        {{ $t('app.readmore') }}
                    </article-link>
                </div>
                <v-card-text class="px-0 py-0">
                    <pro-library-section
                        v-model="selectedPreset"
                        v-if="presetoptions && ui.loaded"
                        :size="120"
                        :all-options="presetoptions"
                        :all-options-counter="presetoptionscounter"
                        section="preset"
                        :show-all-label="$t('pro.library.presets.showsystem')"
                        :user-options-label="$t('pro.library.presets.yours')"
                        :empty-options-message="$t('pro.library.presets.nouseroptions')"
                        :empty-options-message2="$t('pro.library.presets.nouseroptions2')"
                        @change="v=>changeSelectedOption(v, 'preset')"
                        @clearSelectedPreset="updateLibrary"
                        ref="presetsLibrary"
                        @error="setError"
                    ></pro-library-section>
                </v-card-text>
            </v-card>

            <!-- Templates Section -->
            <v-card flat :tile="$vuetify.breakpoint.smAndDown"
                :class="[$vuetify.breakpoint.smAndDown ? 'pa-5' : 'pa-7']" class="mt-4">
                <div class="d-flex align-center smfm-page-heading font-weight-medium mb-2">
                    <v-icon color="#21252C" class="me-3">{{ iconTemplate }}</v-icon>
                    {{ $t('pro.library.templates.title') }}
                </div>
                <div class="smfm-pro-subtitle mb-5" v-if="!isAdmin">
                    {{ $t('pro.library.templates.subtitle') }}
                    <article-link :direct-link="true" article="about_templates">
                        {{ $t('app.readmore') }}
                    </article-link>
                </div>
                <v-card-text class="px-0 py-0">
                    <pro-library-section
                        v-model="selectedTemplate"
                        v-if="templateoptions && ui.loaded"
                        :size="100"
                        :all-options="templateoptions"
                        :all-options-counter="templateoptionscounter"
                        :upload-new-label="$t('pro.library.templates.uploader')"
                        section="template"
                        :show-all-label="$t('pro.library.templates.showsystem')"
                        :user-options-label="$t('pro.library.templates.yours')"
                        :empty-options-message="$t('pro.library.templates.nouseroptions')"
                        @change="v=>changeSelectedOption(v, 'template')"
                        ref="templatesLibrary"
                        @clearSelectedPreset="updateLibrary"
                        @error="setError"
                    ></pro-library-section>
                </v-card-text>
            </v-card>

            <!-- Logos Section  -->
            <v-card flat :tile="$vuetify.breakpoint.smAndDown"
                :class="[$vuetify.breakpoint.smAndDown ? 'pa-5' : 'pa-7']" class="mt-4">
                <div class="d-flex align-center smfm-page-heading font-weight-medium mb-2">
                    <v-icon color="#21252C" class="me-3">{{ iconImage }}</v-icon>
                    {{ $t('pro.library.logos.title') }}
                </div>
                <div class="smfm-pro-subtitle mb-5" v-if="!isAdmin">
                    {{ $t('pro.library.logos.subtitle') }}
                    <article-link :direct-link="true" article="about_logos">
                        {{ $t('app.readmore') }}
                    </article-link>
                </div>
                <v-card-text class="px-0 py-0">
                    <pro-library-section
                        v-model="selectedLogo"
                        v-if="logooptions && ui.loaded"
                        :all-options="logooptions"
                        :all-options-counter="logooptionscounter"
                        :size="60"
                        :upload-new-label="$t('pro.library.logos.uploader')"
                        section="logo"
                        :show-all-label="$t('pro.library.logos.showsystem')"
                        :user-options-label="$t('pro.library.logos.yours')"
                        :empty-options-message="$t('pro.library.logos.nouseroptions')"
                        @clearSelectedPreset="updateLibrary"
                        ref="logosLibrary"
                        @change="v=>changeSelectedOption(v, 'logo')"
                        @error="setError"
                    ></pro-library-section>
                </v-card-text>
            </v-card>
            <v-snackbar v-model="hasError" color="error" timeout="10000">
                {{ errorText }}
                <template v-slot:action="{ attrs }">
                    <v-btn color="gray" text v-bind="attrs" @click="setError(null)">Close</v-btn>
                </template>
            </v-snackbar>
        </v-col>
        <v-col md="12" lg="4" cols="12" class="smfm-pro-cards pe-0 pb-4">
            <!--    Block for changing selected option  -->
            <pro-library-item-controls v-if="selectedOption !== null"
                class="smfm-sticky-md-16" 
                :option="selectedOption" :section="selectedSection"
                @clearSelectedPreset="updateLibrary"
                @error="setError"
                :key="`control-${optionRevisionKey}`">
            </pro-library-item-controls>
        </v-col>
    </v-row>
</template>

<script>
    import { mapActions, mapGetters } from "vuex";
    import { mdiImageOutline, mdiAutoFix, mdiFormatTextbox } from '@mdi/js';
    import ProLibrarySection from "@/components/pro/library/ProLibrarySection";
    import ProLibraryItemControls from "@/components/pro/library/ProLibraryItemControls.vue";
    import userMixin from "@/mixins/userMixin";
    import ArticleLink from "@/components/forms/ArticleLink";

    export default {
        name: "ProLibrary",
        components: { ProLibrarySection, ProLibraryItemControls, ArticleLink },
        mixins: [ userMixin ],
        data() {
            return {
                errorText: null,
                hasError: false,
                panel: [0, 1, 2],
                iconImage: mdiImageOutline,
                iconAutoFix: mdiAutoFix,
                iconTemplate: mdiFormatTextbox,
                optionRevisionKey: 0,
                ui: {
                    loaded: false,
                    uploading: false
                },
                selectedLogo: null,
                selectedPreset: null,
                selectedTemplate: null,
            }
        },
        computed: {
            ...mapGetters(['logooptions', 'templateoptions', 'presetoptions', 'logooptionscounter', 'templateoptionscounter', 'presetoptionscounter']),
            selectedSection() {
                return (this.selectedPreset !== null) ? 'preset' :
                    ((this.selectedTemplate !== null) ? 'template' : 'logo')
            },
            selectedOption() {
                let r = []
                if (this.selectedLogo && this.logooptions)
                    r = this.logooptions.filter(v=>v.value===this.selectedLogo)
                else if (this.selectedPreset && this.presetoptions)
                    r = this.presetoptions.filter(v=>v.value===this.selectedPreset)
                else if (this.selectedTemplate && this.templateoptions)
                    r = this.templateoptions.filter(v=>v.value===this.selectedTemplate)
                return r.length ? r[0] : null
            }
        },
        methods: {
            ...mapActions(['refreshUserLibrary', 'validateUploadedTemplate', 'validateUploadedLogo', 'loadUserLibrary']),
            setError(t) {
                this.errorText = t
                this.hasError = !!t
            },
            changeSelectedOption(val, section) {
                this.selectedLogo = section === 'logo' ? val : null
                this.selectedTemplate = section === 'template' ? val : null
                this.selectedPreset = section === 'preset' ? val : null

                if (this.selectedOption) {
                    let requestedUrl = this.selectedOption.svgpreviewurl
                    // Only logo images can be shown using <img src="...name.svg"> tag, the templates
                    // and presets may contain included fonts, they have to be shown either as preview (png)
                    // or embedded <svg> element.
                    const hassrc = this.selectedOption.src && this.selectedSection === 'logo'
                    if (!this.selectedOption.svg && !hassrc && requestedUrl) {
                        this.authGet({url: requestedUrl})
                            .then((resp) => {
                                // Because it is async, this.selectionOption may be different by now
                                // Make sure it has not changed.
                                if (this.selectedOption && requestedUrl === this.selectedOption.svgpreviewurl) {
                                    this.selectedOption.svg = resp.data
                                    this.optionRevisionKey = this.optionRevisionKey + 1
                                }
                            })
                    }
                }

                // Force ProLibraryItemControls component to update:
                this.optionRevisionKey = this.optionRevisionKey + 1
            },
            updateLibrary(val) {
                this.refreshUserLibrary(val).then(() => {
                    if (val.moved) return
                    let newel = null
                    if (val.deleted) {
                        newel = null
                    } else if (val.renamed) {
                        newel = val.newName
                    } else if (val.modified) {
                        newel = val.modified
                    } else if (val.fileName) {
                        // modified (edited SVG)
                        newel = val.fileName
                    } else if (val.uploaded) {
                        newel = val.uploaded
                    }
                    if (this.isAdmin && newel && !newel.startsWith('shared/')) newel = `shared/${newel}`
                    this.changeSelectedOption(newel, val.section)
                });
            },
        },
        mounted() {
            // TODO use this.libraryloaded and not this.ui.loaded
            this.loadUserLibrary()
                .then(() => this.ui.loaded = true)
        },
    }
</script>

<style lang="scss" scoped>
</style>
