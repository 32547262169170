<script>
    import { Doughnut, mixins } from "vue-chartjs";
    import Chart from "chart.js";

    export default {
        name: "DoughnutChart",
        extends: Doughnut,
        mixins: [ mixins.reactiveProp ],
        data() {
            return {
                options: { 
                    responsive: true, 
                    maintainAspectRatio: false,
                    legend: {
                        position: 'left',
                        labels: {
                            boxWidth: 9,
                            usePointStyle: true,
                            fontColor: '#21252C',
                            fontSize: 14,
                            padding: 20,
                            generateLabels: (chart) => {
                                const { data } = chart;
                                if (data.labels.length && data.datasets.length) {
                                    const total = data.datasets[0].data.reduce((summ, curr) => summ + Number(curr), 0);
                                    return data.labels.map((label, i) => {
                                        const meta = chart.getDatasetMeta(0);
                                        const ds = data.datasets[0];
                                        const arc = meta.data[i];
                                        const custom = (arc && arc.custom) || {};
                                        const { getValueAtIndexOrDefault } = Chart.helpers;
                                        const arcOpts = chart.options.elements.arc;
                                        const fill = custom.backgroundColor ? custom.backgroundColor : getValueAtIndexOrDefault(ds.backgroundColor, i, arcOpts.backgroundColor);
                                        const stroke = custom.borderColor ? custom.borderColor : getValueAtIndexOrDefault(ds.borderColor, i, arcOpts.borderColor);
                                        const bw = custom.borderWidth ? custom.borderWidth : getValueAtIndexOrDefault(ds.borderWidth, i, arcOpts.borderWidth);
                                        const value = chart.config.data.datasets[arc._datasetIndex].data[arc._index];
                                        return {
                                            text: `${label} - ${(100 * value / total).toFixed(1)}%`,
                                            fillStyle: fill,
                                            strokeStyle: stroke,
                                            lineWidth: bw,
                                            hidden: Number.isNaN(ds.data[i]) || meta.data[i].hidden,
                                            index: i,
                                        };
                                    });
                                }
                                return [];
                            },
                        },
                    },
                    tooltips: {
                        displayColors: false,
                        callbacks: {
                            label (tooltipItem, data) {
                                if (data.labels.length && data.datasets.length) {
                                    const label = data.labels[tooltipItem.index] || '';
                                    const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] || '';
                                    const total = data.datasets[tooltipItem.datasetIndex].data.reduce((summ, curr) => summ + Number(curr), 0);
                                    return `${label} - ${(100 * value / total).toFixed(1)}% - ${value} scans`;
                                }
                            }
                        }
                    }
                }
            }
        },
        mounted () {
            this.renderChart(this.chartData, this.options);
        }
    }
</script>