var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"smfm-w-100"},[(_vm.hasdata)?_c('v-data-table',{staticClass:"smfm-data-table",attrs:{"headers":_vm.headers,"items":_vm.rows,"items-per-page":10,"sort-by":['time'],"sort-desc":[true],"mobile-breakpoint":"0","footer-props":{
            itemsPerPageAllText: _vm.$t('pro.all'),
            itemsPerPageText: _vm.$t('pro.rowsperpage')
        }},scopedSlots:_vm._u([{key:"item.time",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formattedDate(item.time))+" ")]}},{key:"item.codeid",fn:function(ref){
        var item = ref.item;
return [('codename' in item)?_c('span',[_c('router-link',{attrs:{"to":("/dynamic/" + (item.codeid))}},[_vm._v(" "+_vm._s(item.codeid)+" ")])],1):_vm._e()]}},{key:"item.country",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formattedCountry(item.country))+" ")]}},{key:"item.lang1",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formattedLang(item.lang1))+" ")]}},{key:"footer.page-text",fn:function(items){return [_vm._v(" "+_vm._s(items.pageStart)+" - "+_vm._s(items.pageStop)+" "+_vm._s(_vm.$t('pro.of'))+" "+_vm._s(items.itemsLength)+" ")]}}],null,true)}):_c('div',{staticClass:"d-flex justify-center align-center py-7"},[_c('span',{staticClass:"font-italic secondary--text"},[_vm._v(_vm._s(_vm.$t('pro.dashboard.nodata')))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }