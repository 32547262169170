<template>
    <div>
        <v-toolbar dark color="primary">
            <v-btn icon dark @click="$emit('closed')">
                <v-icon>{{ iconClose }}</v-icon>
            </v-btn>
            <v-toolbar-title>{{ $t('pro.library.editfile', {file}) }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn dark text @click="onSave" :disabled="loading || svgInvalid">{{ $t('app.save') }}</v-btn>
            </v-toolbar-items>
        </v-toolbar>

        <splitpanes class="default-theme" dir="ltr">
            <pane min-size="20">
                <v-container>
                    <v-textarea v-model="src" rows="20" spellcheck="false" dir="ltr"></v-textarea>
                </v-container>
            </pane>
            <pane>
                <splitpanes class="default-theme" horizontal>
                    <pane>
                        <v-container class="imgcontainer" ref="imgcontainer">
                            <div v-if="!svgInvalid" v-html="src"></div>
                        </v-container>
                    </pane>
                    <pane min-size="20" v-if="section==='template'" :dir="isRTL?'rtl':'ltr'">
                        <v-container>
                            <p>{{ $t('pro.library.templates.previewcaptions') }}</p>
                            <qr-template-captions :disabled="true" :templateimagedom="dom" noneditable="true"></qr-template-captions>
                        </v-container>
                    </pane>
                </splitpanes>
            </pane>
        </splitpanes>

        <v-snackbar v-model="svgInvalid" timeout="-1">{{ $t('pro.library.templates.cannotparsesvg') }}

            <template v-slot:action="{ attrs }">
                <v-btn color="pink" text v-bind="attrs">
                    {{ $t('app.close') }}
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
    import { mdiClose } from '@mdi/js';
    import Splitpanes from "@/components/forms/Splitpanes";
    import Pane from "@/components/forms/Pane";
    import QrTemplateCaptions from "@/components/forms/QrTemplateCaptions";
    import userMixin from "@/mixins/userMixin";

    export default {
        name: "ProSvgEditor",
        mixins: [userMixin],
        components: {QrTemplateCaptions, Pane, Splitpanes },
        props: {
            section: null,
            file: null,
            path: null,
            activated: {default: false},
        },
        data() {
            return {
                loading: null,
                imagesrc: null,
                src: null,
                parser: new DOMParser(),
                parsererrorNS: null,
                iconClose: mdiClose,
            }
        },
        computed: {
            dom() {
                return this.loading ? null : this.parser.parseFromString(this.src,"text/xml")
            },
            svgInvalid() {
                if (this.loading) return false
                if (!this.dom || this.dom.getElementsByTagNameNS(this.parsererrorNS, 'parsererror').length > 0) {
                    return true
                }
                // TODO check for "rect#qr" element
                // } else if (!tmplDom.querySelector('rect#qr[x][y][width][height]')) {
                //     reject('This template file must have a placeholder for the QR code, a <rect> element with id="qr", for example: <rect id="qr" x="0" y="0" width="100" height="100"></rect>')
                // } else {
                //     resolve(file)
                //}
                // TODO check that all text elements have different ids - this.dom.querySelectorAll('text[id]')
                return false
            },
        },
        created() {
            this.parsererrorNS = this.parser.parseFromString('INVALID', 'text/xml').getElementsByTagName("parsererror")[0].namespaceURI
            this.activated && this.onActivated()
        },
        methods: {
            onActivated() {
                this.loading = true
                this.src = null
                this.authGet({url: this.path})
                    .then(resp => {
                        this.src = resp.data
                        this.loading = false
                    })
                    .catch(txt => this.$emit('error', txt))
            },
            onSave() {
                this.loading = true
                this.authPatch({url: this.path, params: {file: true}})
                    .then(resp => this.s3Put({url: resp.data.uploadurl, body: this.src, headers: resp.data.uploadheaders}))
                    .then(() => {
                        this.$emit('saved')
                    })
                    .catch(txt => this.$emit('error', txt))
                    .finally(() => this.loading = false)
            },
        },
        watch: {
            activated(v) {
                v && this.onActivated()
            },
            src() {
                if (!this.svgInvalid) {
                    this.imagesrc = 'data:image/svg+xml;base64,'+this.base64encode(this.src)
                }
            },
        },
    }
</script>

<style scoped>
    .imgcontainer > div {
        max-height: 60vh;
        max-width: 100%;
    }
    /deep/ .imgcontainer > div > svg {
        max-height: 60vh;
        width: 400px;
        height: 400px;
    }

    .splitpanes__pane {
        box-shadow: 0 0 3px rgba(0, 0, 0, .2) inset;
        position: relative;
    }

</style>
