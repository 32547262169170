<template>
    <div class="pro-library-section">
        <div v-if="!libraryloaded">
            <v-skeleton-loader type="table-row"></v-skeleton-loader>
        </div>
        <div v-else-if="allOptions && allOptions.length || isAdmin">
            <v-checkbox v-if="showAllLabel && !isAdmin" 
                v-model="showAllOptions"
                :label="showAllLabel" 
                :hide-details="true">
            </v-checkbox>
            <div class="pro-library-section--presets qr-settings mt-3"
                v-if="(showAllOptions && sharedOptions.length) || userOptions.length || isAdmin">
                <imageselect-sortable v-model="valueSystem"
                            :size="size" :options="sharedOptions"
                            :key="`imselsystem-${isAdmin ? allOptionsCounter : 0}`"
                            v-if="showAllOptions && sharedOptions.length > 0 || isAdmin"
                            ref="system"
                            @input="v=>selected(v, true)"
                            :class="{'mb-5': userOptions.length}"
                            :sortable="(isAdmin && !isTouchScreenDevice) ? true : false"
                            :section="section"
                            @move="movingOption"
                            :noautoselect="true">
                </imageselect-sortable>

                <imageselect-sortable v-model="valueUser"
                            :options="userOptions"
                            :key="`imsel-${allOptionsCounter}`"
                            @input="v=>selected(v, false)"
                            :size="size"
                            ref="value"
                            :label="userOptionsLabel"
                            :section="section"
                            :sortable="!isTouchScreenDevice ? true : false"
                            v-if="userOptions.length > 0"
                            @move="movingOption"
                            :noautoselect="true">
                </imageselect-sortable>
            </div>

            <!--   If we don't have any presets   -->
            <div class="d-flex flex-column justify-center align-center pa-5 no-presets"
                v-if="!userOptions.length && !isAdmin && section === 'preset'">
                <img src="/images/icon-presets-empty.svg" :alt="emptyOptionsMessage">
                <span class="smfm-pro-subtitle font-italic mt-2">{{ emptyOptionsMessage }}</span>
                <span class="smfm-pro-subtitle font-italic mt-2">{{ emptyOptionsMessage2 }}</span>
            </div>
        </div>

        <!--    File Uploader    -->
        <input-file v-if="uploadNewLabel"
                    :text="uploadNewLabel"
                    :dense="!!userOptions.length || isAdmin"
                    :section="section"
                    ref="uploader"
                    @change="uploaded"></input-file>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from "vuex";
    import InputFile from "@/components/forms/InputFile";
    import userMixin from "@/mixins/userMixin";
    import qrdownload from "@/mixins/qrdownload";
    import ImageselectSortable from "@/components/forms/ImageselectSortable";

    export default {
        name: "ProLibrarySection",
        components: {ImageselectSortable, InputFile},
        mixins: [userMixin, qrdownload],
        props: {
            section: null,
            size: {default: 60},
            allOptions: null,
            allOptionsCounter: {default: 0},
            uploadNewLabel: null,
            showAllLabel: null,
            userOptionsLabel: null,
            emptyOptionsMessage: null,
            emptyOptionsMessage2: null,
            value: null,
        },
        data() {
            return {
                valueUser: null,
                showAllOptions: false,
                valueSystem: null,
                isSystem: false, // selected value is from the system imageselect
            }
        },
        computed: {
            ...mapGetters(['libraryloaded']),
            isTouchScreenDevice() {
                return ( 'ontouchstart' in window ) || 
                    ( navigator.maxTouchPoints > 0 ) ||
                    ( navigator.msMaxTouchPoints > 0 );
            },
            apiPath() {
                return this.isAdmin ? `admin/library/${this.section}s` : `pro/library/${this.section}s`
            },
            userOptions() {
                return this.allOptions.filter(e => e.usercontent)
            },
            selectedValue() {
                if (!this.isSystem) {
                    return (this.valueUser !== 'reset') ? this.valueUser : null
                } else {
                    return (this.valueSystem !== 'reset') ? this.valueSystem : null
                }
            },
            selectedOption() {
                if (this.selectedValue !== null && this.allOptions) {
                    const res = this.allOptions.filter(el => el.value === this.selectedValue)
                    return (res.length < 1) ? null : res[0]
                }
                return null
            },
            sharedOptions() {
                return this.allOptions.filter(e => !e.usercontent)
            },
        },
        created() {
            this.__setValue(this.value)
        },
        methods: {
            ...mapActions(['refreshUserLibrary', 'validateUploadedTemplate', 'validateUploadedLogo']),
            selected(v, isSystem) {
                this.isSystem = isSystem
                if (isSystem) this.clearSelected()
                else this.clearSystemSelected()
                this.$emit('change', v)
            },
            uploaded(e) {
                let preCheck
                if (this.section === 'template') {
                    preCheck = this.validateUploadedTemplate
                } else if (this.section === 'logo') {
                    preCheck = this.validateUploadedLogo
                } else {
                    preCheck = f => {
                        return new Promise((resolve) => {
                            resolve(f)
                        })
                    }
                }
                preCheck(e.file)
                    .then(() => e.upload(this.apiPath))
                    .then(r => {
                        this.$emit('clearSelectedPreset', {section: this.section, uploaded: r.name})
                    })
                    .catch(e => this.$emit('error', e))
                    .finally(() => e.reset())
            },
            recalculateSelection() {
                if (this.valueUser && this.selectedOption === null) this.valueUser = null
            },
            movingOption(val) {
                let fileName = val.fileName.split('/').reverse()[0]
                this.authPatch({url: `${this.apiPath}/${fileName}`, params: {after: val.after}})
                    .then(() => this.$emit('clearSelectedPreset', {section: val.section, moved: fileName, after: val.after}))
                    .catch(e => this.setError(e))
            },
            clearSelected() {
                this.valueUser = 'reset'
                if (this.$refs.value)
                    this.$refs.value.clearValue()
            },
            clearSystemSelected() {
                this.valueSystem = 'reset'
                if (this.$refs.system)
                    this.$refs.system.clearValue()
            },
            __setValue(v) {
                const res = v === null ? [] : this.allOptions.filter(el => el.value === v),
                      o = res.length ? res[0] : {value: null, usercontent: true}
                this.isSystem = !o.usercontent
                this.valueUser = this.isSystem ? null : o.value
                this.valueSystem = this.isSystem ? o.value : null
            }
        },
        watch: {
            value(v) {
                this.__setValue(v)
            },
            options() {
                this.recalculateSelection()
            },
        },
    }
</script>

<style lang="scss" scoped>
.pro-library-section {
    &--presets {
        background: #FFFFFF;
        border: 1px solid #C7C8CA;
        box-sizing: border-box;
        border-radius: 10px;
        padding: 20px 15px;
    }

    .no-presets {
        background: #FAFAFA;
        border-radius: 10px;
        margin-top: 10px;
    }
}
.v-input {
    width: fit-content;
}
</style>
