<template>

    <v-menu left bottom v-if="isLoggedIn" :offset-y="true">
        <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
                <v-avatar v-if="currentUser.picture" :size="36"><img :src="currentUser.picture"></v-avatar>
                <v-icon v-else large>{{ usericon }}</v-icon>
            </v-btn>
        </template>

        <v-list>
            <v-list-item to="/">
                <v-list-item-title>{{$t('pro.dashboard.dashboard')}}</v-list-item-title>
            </v-list-item>
            <v-list-item to="/profile">
                <v-list-item-title>Profile</v-list-item-title>
            </v-list-item>
            <v-list-item to="/logout">
                <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
    import { mdiAccountCircle } from '@mdi/js'
    import userMixin from "@/mixins/userMixin";

    export default {
        name: "AdminHeader",
        mixins: [userMixin],
        computed: {
            usericon() { return mdiAccountCircle }
        },
        mounted() {
        }
    }
</script>

<style scoped>

</style>
