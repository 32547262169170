<template>
    <div v-if="options">
        <div v-if="label" class="carousel-label">
            <div v-html="label"></div>
        </div>
        <v-sheet>
            <v-slide-group ref="slider" multiple mobile-breakpoint="960" active-class="elementisactive" v-model="items"
                :show-arrows="$vuetify.breakpoint.mdAndUp">
                <draggable v-model="options4draggable" group="people" class="d-flex" direction="h" @change="moving" :disabled="!sortable">
                    <div v-for="(option, idx) in options4draggable" :key="`div-${option.value}${option.previewsrc}`">
                        <v-slide-item v-slot:default="{  }" :key="`si-${option.value}${option.previewsrc}`" :value="idx" :ref="'el'+idx" eager>
                            <imageselect-element :option="option" :idx="idx"
                                                 :size="size" :asbutton="asbutton"
                                                 @input="v=>$emit('input', v)">
                            </imageselect-element>
                        </v-slide-item>
                    </div>
                </draggable>
            </v-slide-group>
        </v-sheet>
    </div>
</template>

<script>
    // This component is very similar to Imageselect but it also allows sorting. To be used in PRO/admin library.
    // Never use on DEMO site.
    import draggable from 'vuedraggable'
    import ImageselectElement from "@/components/forms/ImageselectElement";

    export default {
        props: {
            options: {
                type: Array,
                default: () => []
            },
            name: {type: String},
            label: null,
            value: {
                get() {
                    return (this.items.length && this.options.length) ? this.options[this.items[0]].value : null
                },
                set(v) {
                    this.__setValue(v)
                }
            },
            asbutton: {default: false},
            section: null,
            sortable: {
                type: Boolean,
                default: false
            },
            size: {default: 60},
        },
        components: {ImageselectElement, draggable },
        data() {
            return {
                items: [],
                options4draggable: [],
            }
        },
        mounted() {
            this.__setValue(this.value)
            this.options4draggable = [...this.options]
        },
        computed: {
            dataset() {
                return this.options && this.options.length && this.items && this.items.length && this.options[this.items[0]]
            }
        },
        methods: {
            loadImage(option) {
                // TODO duplicated
                if (option && option.loadcontent) option.loadcontent()
            },
            reposition() {
                this.$nextTick(() => {
                    // TODO this does not center the selected element any more
                    this.$refs.slider.setWidths()
                    this.$forceUpdate()
                })
            },
            moving(val) {
                const el = val.moved.element.value;
                const nextOffset = val.moved.newIndex - val.moved.oldIndex
                if (val.moved.newIndex === val.moved.oldIndex) return
                const nidx = val.moved.newIndex,
                      after = nidx > val.moved.oldIndex ?
                          this.options[nidx].value :
                          (nidx > 0 ? this.options[nidx - 1].value : "")
                this.$emit('move', {offset: nextOffset, section: this.section, fileName: el, after})
                return true
            },
            __setValue(v) {
                this.items = [...Array(this.options.length).keys()].filter(idx => this.options[idx].value === v)
            },
            clearValue() {
                // TODO not needed
                this.items = []
            },
        },
        watch: {
            value(v) {
                this.__setValue(v)
            },
            options(v, oldv) {
                if (v && v.length) {
                    // List of options changed after the render, we probably uploaded or modified something,
                    // v-intersect will not work in this case, load images now.
                    v.forEach(e => this.loadImage(e))
                }
                this.options4draggable = [...this.options]
                this.$nextTick(() => {
                    this.$refs.slider.setWidths()
                    this.$refs.slider.genWrapper()
                    // TODO the vuetify element does not recalculate the scroll offset automatically.
                    if (this.$refs.slider.scrollOffset > 0 && (!oldv || !v || oldv.length > v.length)) {
                        this.$refs.slider.scrollOffset = 0
                    }
                    this.$forceUpdate()
                })
            },
        },
    }
</script>

<style scoped lang="scss">
.carousel-label {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #393E46;
    padding-inline-start: 10px;
    margin-bottom: 10px;
}
</style>
