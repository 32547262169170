<template>
    <div class="smfm-w-100">
        <v-data-table v-if="hasdata" class="smfm-data-table"
            :headers="headers"
            :items="rows"
            :items-per-page="10"
            :sort-by="['time']"
            :sort-desc="[true]"
            mobile-breakpoint="0"
            :footer-props="{
                itemsPerPageAllText: $t('pro.all'),
                itemsPerPageText: $t('pro.rowsperpage')
            }">
            <template v-slot:[`item.time`]="{ item }">
                {{ formattedDate(item.time) }}
            </template>
            <template v-slot:[`item.codeid`]="{ item }">
                <span v-if="'codename' in item">
                    <router-link :to="`/dynamic/${item.codeid}`">
                        {{ item.codeid }}
                    </router-link>
                </span>
            </template>
            <template v-slot:[`item.country`]="{ item }">
                {{ formattedCountry(item.country) }}
            </template>
            <template v-slot:[`item.lang1`]="{ item }">
                {{ formattedLang(item.lang1) }}
            </template>
            <template v-slot:[`footer.page-text`]="items">
                {{ items.pageStart }} - {{ items.pageStop }} {{ $t('pro.of') }} {{ items.itemsLength }}
            </template>
        </v-data-table>
        <div v-else class="d-flex justify-center align-center py-7">
            <span class="font-italic secondary--text">{{ $t('pro.dashboard.nodata') }}</span>
        </div>
    </div>
</template>

<script>
    import userMixin from "@/mixins/userMixin";

    export default {
        name: "ProScansList",
        mixins: [ userMixin ],
        props: {
            rows: null
        },
        computed: {
            hasdata() {
                return this.rows && this.rows.length;
            },
            headers() {
                const hidden = ['codename', 'ip', 'lang2', 'deleted']
                const titles = {
                    time: this.$t('pro.qrlist.scantime'),
                    codeid: this.$t('pro.qrlist.qrcode'),
                    country: this.$t('pro.qrlist.country'),
                    region: this.$t('pro.qrlist.region'),
                    city: this.$t('pro.qrlist.city'),
                    lang1: this.$t('pro.qrlist.language'),
                    platform: this.$t('pro.qrlist.platform'),
                    os: this.$t('pro.qrlist.os'),
                    browser: this.$t('pro.qrlist.browser'),
                    tags: this.$t('pro.qrlist.tags'),
                }
                return this.hasdata ?
                    Object.keys(this.rows[0])
                        .filter(a => !hidden.includes(a))
                        .map(a => ({ value: a, text: a in titles ? titles[a] : a })) :
                    [];
            }
        },
        methods: {
            formattedCountry(value) {
                return value in this.countriesNames[this.userLang] ? this.countriesNames[this.userLang][value] : value;
            },
            formattedDate(value) {
                // The dates in the results are already in the user timezone.
                return this.formatDateTime(Date.parse(value.replace(/\.\d+$/, '') + 'Z'), false);
            },
            formattedLang(value) {
                return value in this.languagesMap[this.userLang] ? this.languagesMap[this.userLang][value] : value;
            }
        },
    }
</script>

<style lang="scss">
</style>