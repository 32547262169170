<template>
    <div>
        <h3>Profile</h3>
        <div>Name: <span v-html="currentUser.name"></span></div>
        <div v-for="(identity, idx) in currentUser.identities" :key="idx">Identity: <span v-html="identity.providerName"></span> </div>
        <div>Email: <span v-html="currentUser.email"></span></div>
        <div v-if="currentUser.picture">Picture: <img :src="currentUser.picture" alt=""></div>
    </div>
</template>

<script>
    import userMixin from "@/mixins/userMixin";

    export default {
        name: "AdminProfile",
        mixins: [userMixin],
        created() {
            this.refreshUser()
        },
    }
</script>

<style scoped>

</style>
