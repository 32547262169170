import userMixin from "@/mixins/userMixin";
import moment from "moment";

export default {
  mixins: [ userMixin ],
  methods: {
    getStackedChartDataset(metrics) {
      const total = metrics.map(el => el.cnt - el.cntunique);
      const unique = metrics.map(el => el.cntunique);
      return [
        {
          label: this.$t('pro.dashboard.unique'),
          backgroundColor: '#03949A',
          data: unique
        },
        {
          label: this.$t('pro.dashboard.total'),
          backgroundColor: '#12C7D0',
          data: total
        }
      ];
    },
    getDoughnutChartData(metrics, field) {
      const labels = metrics.map(el => {
        if (field === 'lang') {
          return this.languagesMap[this.userLang][el[field]];
        } else if (field === 'country') {
          return this.$store.getters.countriesNames[this.userLang][el[field]];
        } else {
          return el.isother ? this.$t('pro.dashboard.other') : el[field];
        }
      });
      const dataset = metrics.map(el => el.cnt);
      const colors = this.bgColors(metrics.length);
      return {
        labels: labels,
        datasets: [
          {
            backgroundColor: colors,
            data: dataset
          }
        ]
      };
    },
    bgColors(count) {
      const colors = ["#12C7D0", "#909295", "#393E46", "#F1D127", "#03949A", "#C7C8CA"]
      return colors.slice(0, count);
    },
    getMonthName(index) {
      moment.locale(this.userLang);
      return moment.monthsShort()[index];
    },
    getFormattedMonthAndYear(value) {
      return `${this.getMonthName(parseInt(value.substr(5, 2)) - 1)} '${value.substr(2, 2)}`
    },
    getFormattedTime(value) {
      return this.currentUser['custom:timeformat'] === 'hh:mm A' ? moment(value, "hh").format('LT')  : `${value}:00`;
    },
    getFormattedDay(value) {
      return this.currentUser['custom:dateformat'] === 'MM/DD/YYYY' ? moment(value).format('MM/DD') : moment(value).format('DD/MM');
    },
    getFormattedBin(value, bin) {
      // Currently bin can be: hour, day, week, month
      // Potentially also can be: second, minute, quarter, year
      if (bin === 'hour') return this.getFormattedTime(value.substr(11, 2))
      else if (bin === 'month') return this.getFormattedMonthAndYear(value)
      else if (bin === 'day' || bin === 'week') return this.getFormattedDay(value)
      else return value
    }
  }
}