<template>
  <v-dialog
    scrollable
    v-if="showDialog === true"
    v-model="showDialog">
    <v-card class="smfm-dialog-with-header">
      <v-toolbar flat :height="$vuetify.breakpoint.smAndDown ? 60 : 70"
        class="smfm-page-subheading">
        {{ $t('app.download.downloadqr') }}
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click.stop="closeDialog()">
          <v-icon color="secondary">{{ iconClose }}</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pa-0" style="height: 500px">
        <v-row class="qr-settings-wrap">
          <!-- Overlay -->
          <v-overlay :value="isloading"
            :absolute="true" :opacity="0.2"
            :dark="false" :light="true">
          </v-overlay>
          <!-- QR code download preview -->
          <v-col cols="12" sm="12" md="6"
            class="pro-qr-preview pa-0 smfm-h-100 overflow-x-hidden">
            <pro-qr-download-preview ref="qrdownloadpreview"
                                     :qrcodes="qrcodes"
                                     :imagesize="imageSizePx"
                                     :marginleft="marginLeftPx"
                                     :margintop = "marginTopPx"
                                     :marginright = "marginRightPx"
                                     :marginbottom = "marginBottomPx"
                                     :spacingx = "spacingXPx"
                                     :spacingy = "spacingYPx"
                                     :repeat="isAdvanced ? repeat : 1"
                                     :paperwidth = "paperWidthPx"
                                     :paperheight = "paperHeightPx"
                                     :qrdpi="qrdpi"
                                     :showcutlines="isAdvanced ? showcutlines : false"
                                     :isvisible="dialogVisible"
            ></pro-qr-download-preview>
          </v-col>
          <!-- QR code download settings -->
          <v-col cols="12" sm="12" md="6" class="pa-0">
            <div class="pro-qr-settings overflow-x-hidden">
              <!-- Format -->
              <div class="mb-5">
                <div class="mb-2 secondary--text">{{ $t('app.download.format') }}:</div>
                <div class="d-flex justify-start align-center flex-wrap">
                  <qr-download-dialog-btn
                    v-for="(format, index) in formats" :key="index"
                    :label="format.value"
                    :available="format.available"
                    :class="{'selected': selectedFormat === format.value }"
                    @select="selectFormat($event)">
                  </qr-download-dialog-btn>
                </div>
              </div>
              <!-- Units -->
              <div class="mb-5">
                <div class="mb-2 secondary--text">{{ $t('pro.download.units') }}:</div>
                <div class="col-4 px-0 py-0">
                  <v-select dense outlined label=""
                            v-model="selectedUnits"
                            hide-details
                            :items="units" @change="changeUnit">
                  </v-select>
                </div>
              </div>
              <!-- Size -->
              <div class="mb-5">
                <div class="secondary--text mb-2">{{ $t('app.download.qrsize') }}:</div>
                <div class="d-flex justify-start align-center flex-wrap">
                  <qr-download-dialog-btn
                    v-for="(size, index) in sizes" :key="index"
                    :label="`${size.displayValue} ${selectedUnitsLabel}`"
                    :available="size.available"
                    :textTransform="'lower'"
                    :class="{'selected': selectedSizeIndex === index }"
                    @select="selectedSizeIndex = index">
                  </qr-download-dialog-btn>
                  <v-btn small text
                      v-if="selectedSizeIndex !== -1"
                      class="smfm-custom-size-btn"
                      @click="selectedSizeIndex = -1">
                    {{ $t('app.download.custom') }}
                  </v-btn>
                  <div v-if="selectedSizeIndex === -1" class="smfm-custom-size-input">
                    <v-text-field
                      dense outlined
                      hide-details dir="ltr"
                      :label="$t('app.download.custom')"
                      :suffix="selectedUnitsLabel" type="number" :step="numericInputStep"
                      v-model="customSize"
                      :rules="[requiredRule]">
                    </v-text-field>
                  </div>
                </div>
              </div>
              <!-- DPI -->
              <div class="mb-5" v-if="isPro">
                <div class="secondary--text mb-2">{{ $t('pro.download.dpilabel') }}:</div>
                  <div class="d-flex justify-start align-center flex-wrap">
                      <qr-download-dialog-btn
                        v-for="dpi in [150, 300, 600]" :key="'dpi_'+dpi"
                        :label="`${dpi} ${$t('pro.download.dpisuffix')}`"
                        :available="true"
                        :textTransform="'lower'"
                        :class="{'selected': qrdpi === dpi }"
                        @select="qrdpi=dpi">
                      </qr-download-dialog-btn>
                  </div>
              </div>
              <!-- Advanced settings -->
              <div class="smfm-advanced-settings">
                <v-checkbox v-model="isAdvanced">
                  <template v-slot:label>
                    <div class="d-flex flex-column">
                      {{ $t('app.download.advancedsettings') }}
                      <span class="text-caption secondary--text">
                        {{ $t('app.download.advancedoptions') }}
                      </span>
                    </div>
                  </template>
                </v-checkbox>
              </div>
              <div v-if="isAdvanced">
                <!-- Page size -->
                <v-row>
                    <v-col cols="12" sm="12" class="pa-0">
                      <v-select outlined :label="$t('pro.download.pagesize')"
                                v-model="pagesize" :items="pagesizeoptions"
                                @change="onPagesizeChange">
                      </v-select>
                    </v-col>
                </v-row>
                <!-- Orientation -->
                <v-row v-if="pagesize !== 'custom'">
                    <v-col cols="12" sm="12" class="pa-0">
                      <v-select outlined :label="$t('pro.download.orientation')"
                                v-model="pageorientation" :items="pageorientations">
                      </v-select>
                    </v-col>
                </v-row>
                <!-- Page width and height -->
                <v-row v-else>
                    <v-col cols="12" sm="6" md="12" lg="6"
                      :class="[$vuetify.breakpoint.xsOnly || $vuetify.breakpoint.mdOnly ? 'pa-0' : 'py-0 ps-0']">
                      <v-text-field outlined v-model="custompagewidth" dir="ltr"
                                    :suffix="selectedUnitsLabel" type="number"
                                    :step="numericInputStep"
                                    :label="$t('pro.download.pagewidth')">
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="12" lg="6"
                      :class="[$vuetify.breakpoint.xsOnly || $vuetify.breakpoint.mdOnly ? 'pa-0' : 'py-0 pe-0']">
                      <v-text-field outlined v-model="custompageheight" dir="ltr"
                                    :suffix="selectedUnitsLabel" type="number"
                                    :step="numericInputStep"
                                    :label="$t('pro.download.pageheight')">
                      </v-text-field>
                    </v-col>
                </v-row>
                <!-- Left and top margins -->
                <v-row>
                    <v-col cols="12" sm="6" md="12" lg="6"
                      :class="[$vuetify.breakpoint.xsOnly || $vuetify.breakpoint.mdOnly ? 'pa-0' : 'py-0 ps-0']">
                      <v-text-field outlined v-model="marginleft" dir="ltr"
                                    :suffix="selectedUnitsLabel" type="number"
                                    :step="numericInputStep"
                                    :label="$t('pro.download.marginleft')">
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="12" lg="6"
                      :class="[$vuetify.breakpoint.xsOnly || $vuetify.breakpoint.mdOnly ? 'pa-0' : 'py-0 pe-0']">
                      <v-text-field outlined v-model="margintop" dir="ltr"
                                    :suffix="selectedUnitsLabel" type="number"
                                    :step="numericInputStep"
                                    :label="$t('pro.download.margintop')">
                      </v-text-field>
                    </v-col>
                </v-row>
                <!-- Right and bottom margins -->
                <v-row>
                    <v-col cols="12" sm="6" md="12" lg="6"
                      :class="[$vuetify.breakpoint.xsOnly || $vuetify.breakpoint.mdOnly ? 'pa-0' : 'py-0 ps-0']">
                      <v-text-field outlined v-model="marginright" dir="ltr"
                                    :suffix="selectedUnitsLabel" type="number"
                                    :step="numericInputStep"
                                    :label="$t('pro.download.marginright')">
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="12" lg="6"
                      :class="[$vuetify.breakpoint.xsOnly || $vuetify.breakpoint.mdOnly ? 'pa-0' : 'py-0 pe-0']">
                      <v-text-field outlined v-model="marginbottom" dir="ltr"
                                    :suffix="selectedUnitsLabel" type="number"
                                    :step="numericInputStep"
                                    :label="$t('pro.download.marginbottom')">
                      </v-text-field>
                    </v-col>
                </v-row>
                <!-- Repeat -->
                <v-row>
                    <v-col cols="12" sm="12" class="pa-0">
                      <v-select outlined :label="$t('pro.download.repeat')"
                                v-model="repeat" :items="repeatoptions">
                      </v-select>
                    </v-col>
                </v-row>
                <!-- Horizontal and vertical spacing -->
                <v-row v-if="(repeat !== 1 || isBulkDownload)">
                    <v-col cols="12" sm="6" md="12" lg="6"
                      :class="[$vuetify.breakpoint.xsOnly || $vuetify.breakpoint.mdOnly ? 'pa-0' : 'py-0 ps-0']">
                      <v-text-field outlined v-model="spacingx" dir="ltr"
                                    :suffix="selectedUnitsLabel" type="number"
                                    :step="numericInputStep"
                                    :label="$t('pro.download.spacingx')">
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="12" lg="6"
                      :class="[$vuetify.breakpoint.xsOnly || $vuetify.breakpoint.mdOnly ? 'pa-0' : 'py-0 pe-0']">
                      <v-text-field outlined v-model="spacingy" dir="ltr"
                                    :suffix="selectedUnitsLabel" type="number"
                                    :step="numericInputStep"
                                    :label="$t('pro.download.spacingy')">
                      </v-text-field>
                    </v-col>
                </v-row>
                <!-- Display crop marks -->
                <v-row>
                    <v-col cols="12" sm="12" class="pa-0">
                      <v-checkbox :label="$t('pro.download.showcropmarks')" v-model="showcutlines"></v-checkbox>
                    </v-col>
                </v-row>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="d-flex justify-end align-center pt-5"
          :class="[$vuetify.breakpoint.smAndDown ? 'px-5 pb-7' : 'px-10 pb-10']">
        <v-btn
          text
          class="me-3"
          color="secondary"
          @click="closeDialog"
        > {{ $t('app.cancel') }}
        </v-btn>
        <v-btn
          class="smfm-primary-btn smfm-lg-btn"
          @click="onDownloadClick()"> 
          <loading v-if="isloading"></loading>
          <span v-else>{{ $t('app.download.download') }}</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
    import { mapActions, mapGetters } from "vuex";
    import { mdiClose, mdiLock } from '@mdi/js';
    import validation from "@/mixins/validation";
    import Loading from "@/components/common/Loading";
    import QrDownloadDialogBtn from "@/components/demo/QrDownloadDialogBtn";
    import ProQrDownloadPreview from "@/components/pro/download/ProQrDownloadPreview";
    import qrdownload from "@/mixins/qrdownload";

    export default {
        name: "ProQrDownloadDialog",
        components: { QrDownloadDialogBtn, ProQrDownloadPreview, Loading },
        mixins: [ qrdownload, validation ],
        props: {
            dialogVisible: {
                type: Boolean,
                default: false,
            },
            qrlabel: null,
            qrcodes: {default: []}, // list of codes to download
            isloading: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                selectedFormat: 'png',
                selectedUnits: 'px',
                selectedSizeIndex: 1,
                customSize: 640,
                qrdpi: 150,
                isAdvanced: false,
                pagesize: '210x297',
                pageorientation: 'portrait',
                marginleft: 0,
                marginright: 0,
                margintop: 0,
                marginbottom: 0,
                repeat: 1,
                spacingx: 0,
                spacingy: 0,
                custompagewidth: 0,
                custompageheight: 0,
                showcutlines: false,
                oldUnit: 'px',
                pageorientations: [
                    { value: 'portrait', text: this.$t('pro.download.portrait') },
                    { value: 'landscape', text: this.$t('pro.download.landscape') },
                ],
                pagesizeoptionsall: [
                    { value: '841x1189', label: 'A0' },
                    { value: '594x841', label: 'A1' },
                    { value: '420x594', label: 'A2' },
                    { value: '297x420', label: 'A3' },
                    { value: '210x297', label: 'A4' },
                    { value: '148x210', label: 'A5' },
                    { value: '105x148', label: 'A6' },
                    { value: '216x279', label: 'Letter' },
                    { value: '216x356', label: 'Legal' },
                    //{ value: '279.4x431.8', label: 'Tabloid' },
                    { value: 'custom', label: this.$t('pro.download.custompagesize') },
                ],
                units: [
                    { value: 'px', text: this.$t('pro.download.unitpx'), precision: 1 },
                    { value: 'mm', text: this.$t('pro.download.unitmm'), precision: 1 },
                    { value: 'cm', text: this.$t('pro.download.unitcm'), precision: 10 },
                    { value: 'in', text: this.$t('pro.download.unitin'), precision: 100 },
                ],
                repeatoptions: [
                    { value: 1, text: this.$t('pro.download.repeatonce') },
                    { value: -1, text: this.$t('pro.download.repeatmax') },
                    { value: 2, text: '2' },
                    { value: 3, text: '3' },
                    { value: 4, text: '4' },
                    { value: 5, text: '5' },
                    { value: 6, text: '6' },
                    { value: 7, text: '7' },
                    { value: 8, text: '8' },
                    { value: 9, text: '9' },
                    { value: 10, text: '10' },
                    { value: 11, text: '11' },
                    { value: 12, text: '12' },
                    { value: 13, text: '13' },
                    { value: 14, text: '14' },
                    { value: 15, text: '15' },
                    { value: 16, text: '16' },
                    { value: 17, text: '17' },
                    { value: 18, text: '18' },
                    { value: 19, text: '19' },
                    { value: 20, text: '20' },
                ],
            }
        },
        computed: {
            ...mapGetters('qrdownload_pro', ['downloadSettings']),
            iconClose() { return mdiClose },
            iconLock() { return mdiLock },
            showDialog: {
                get() {
                    return this.dialogVisible;
                },
                set(newValue) {
                    if(!newValue) this.$emit('close');
                }
            },
            formats() {
                return [
                    { value: 'png', available: true },
                    { value: 'svg', available: true },
                    { value: 'pdf', available: true },
                    { value: 'ps', available: true },
                ]
            },
            sizes() {
                let arrSizes = [
                    { value: 128, available: true },
                    { value: 256, available: true },
                    { value: 512, available: true },
                    { value: 1024, available: true },
                ]
                return arrSizes.map((el) => {
                    el.displayValue = this.convertDimension(el.value, 'px', this.selectedUnits, true)
                    return el;
                })
            },
            numericInputStep() {
                const filtered = this.units.filter(v=>v.value === this.selectedUnits)
                return filtered.length ? 1/filtered[0].precision : 1
            },
            selectedUnitsLabel() {
                const filtered = this.units.filter(v=>v.value === this.selectedUnits)
                return filtered.length ? filtered[0].text : this.selectedUnits
            },
            qrlabeldisplay() {
                return this.qrlabel ? this.qrlabel : (this.qrcodes[0].qrlabel ? this.qrcodes[0].qrlabel : '')
            },
            pagesizeoptions() {
                const sizeLabel = v => {
                    if (v.value === 'custom') return v.label
                    let width = parseFloat(v.value.split(/x/)[0]), height = parseFloat(v.value.split(/x/)[1])
                    const units = this.selectedUnits === 'in' ? 'in' : 'mm'
                    if (units === 'in') {
                        width = Math.round(this.convertDimension(width, 'mm', units) * 10) / 10
                        height = Math.round(this.convertDimension(height, 'mm', units) * 10) / 10
                    }
                    const unitsLabel = this.units.filter(v=>v.value === units)[0].text
                    return `${v.label} (${width} x ${height} ${unitsLabel})`
                }
                return this.pagesizeoptionsall.map((v) => ({value: v.value, text: sizeLabel(v)}))
            },
            islandscape() {
                return this.pageorientation === 'landscape'
            },
            imageSizePx() {
                return this.selectedSizeIndex === -1 ? this.convertToPx(this.customSize) :
                    this.sizes[this.selectedSizeIndex].value
            },
            paperWidthPx() {
                if (!this.isAdvanced) return 0
                if (this.pagesize === 'custom') return this.convertToPx(this.custompagewidth)
                return this.convertDimension(parseInt(this.pagesize.split(/x/)[this.islandscape ? 1 : 0]), 'mm', 'px', true)
            },
            paperHeightPx() {
                if (!this.isAdvanced) return 0
                if (this.pagesize === 'custom') return this.convertToPx(this.custompageheight)
                return this.convertDimension(parseInt(this.pagesize.split(/x/)[this.islandscape ? 0 : 1]), 'mm', 'px', true)
            },
            marginLeftPx() { return this.isAdvanced ? this.convertToPx(this.marginleft) : 0 },
            marginRightPx() { return this.isAdvanced ? this.convertToPx(this.marginright) : 0 },
            marginTopPx() { return this.isAdvanced ? this.convertToPx(this.margintop) : 0 },
            marginBottomPx() { return this.isAdvanced ? this.convertToPx(this.marginbottom) : 0 },
            spacingXPx() { return this.isAdvanced ? this.convertToPx(this.spacingx) : 0 },
            spacingYPx() { return this.isAdvanced ? this.convertToPx(this.spacingy) : 0 },
            isBulkDownload() {
                return this.qrcodes.length > 1
            },

        },
        methods: {
            ...mapActions('qrdownload_pro', ['setQrDownloadPreferences']),
            closeDialog() {
                this.showDialog = false;
                this.setQrDownloadPreferences({
                    selectedFormat: this.selectedFormat,
                    selectedUnits: this.selectedUnits,
                    selectedSizeIndex: this.selectedSizeIndex,
                    qrdpi: this.qrdpi,
                    isAdvanced: this.isAdvanced,
                    pagesize: this.pagesize,
                    custompagewidth: this.custompagewidth,
                    custompageheight: this.custompageheight,
                    pageorientation: this.pageorientation,
                    marginleft: this.marginleft,
                    margintop: this.margintop,
                    marginright: this.marginright,
                    marginbottom: this.marginbottom,
                    spacingx: this.spacingx,
                    spacingy: this.spacingy,
                    repeat: this.repeat,
                    showcutlines: this.showcutlines,
                });
            },
            selectFormat(format) {
                this.selectedFormat = format;
            },
            onDownloadClick() {
                const dpi = this.qrdpi
                const size = this.imageSizePx // The variable 'size' has to be always in pixels!

                this.$emit('downloadQR', {
                    format: this.selectedFormat,
                    codes: this.qrcodes,
                    size,
                    dpi,
                    advancedoptions: this.isAdvanced,
                    marginleft: this.marginLeftPx,
                    margintop: this.marginTopPx,
                    marginright: this.marginRightPx,
                    marginbottom: this.marginBottomPx,
                    spacingx: this.spacingXPx,
                    spacingy: this.spacingYPx,
                    repeat: this.isAdvanced ? this.repeat : 1,
                    paperwidth: this.paperWidthPx,
                    paperheight: this.paperHeightPx,
                    showcutlines: this.showcutlines
                });
            },
            round(dimension, unit) {
                const filtered = this.units.filter(v => v.value === unit),
                      precision = filtered.length ? filtered[0].precision : 1
                return Math.round(dimension * precision) / precision
            },
            convertDimension(dimension, fromUnit, toUnit, doRound=false) {
                const toPx = (v, f) => {
                    if (f === 'mm') return v / 25.4 * this.qrdpi
                    else if (f === 'cm') return v / 2.54 * this.qrdpi
                    else if (f === 'in') return v * this.qrdpi
                    else return v
                }
                const v = toPx(dimension, fromUnit) / toPx(1, toUnit)
                return doRound ? this.round(v, toUnit) : v
            },
            convertToPx(dimension) {
                return this.convertDimension(dimension, this.selectedUnits, 'px', true)
            },
            changeUnit(val) {
                this.marginleft = this.convertDimension(this.marginleft, this.oldUnit, val)
                this.margintop = this.convertDimension(this.margintop, this.oldUnit, val)
                this.marginright = this.convertDimension(this.marginright, this.oldUnit, val)
                this.marginbottom = this.convertDimension(this.marginbottom, this.oldUnit, val)
                this.spacingx = this.convertDimension(this.spacingx, this.oldUnit, val)
                this.spacingy = this.convertDimension(this.spacingy, this.oldUnit, val)
                this.paperwidth = this.convertDimension(this.paperwidth, this.oldUnit, val)
                this.paperheight = this.convertDimension(this.paperheight, this.oldUnit, val)
                this.custompagewidth = this.convertDimension(this.custompagewidth, this.oldUnit, val)
                this.custompageheight = this.convertDimension(this.custompageheight, this.oldUnit, val)
                this.customSize = this.convertDimension(this.customSize, this.oldUnit, val)
                this.oldUnit = val
            },
            onPagesizeChange() {
                // When user selects 'custom' for the first time, set the custompagewidth/height
                if (this.pagesize === 'custom') {
                    if (this.custompagewidth === 0)
                        this.custompagewidth = this.convertDimension(210, 'mm', this.selectedUnits, true)
                    if (this.custompageheight === 0)
                        this.custompageheight = this.convertDimension(297, 'mm', this.selectedUnits, true)
                }
            }
        },
        watch: {
            qrdpi(val, old) {
                this.oldDpi = old
                this.changeUnit(this.selectedUnits)
            }
        },
        created() {
            if (this.downloadSettings) {
                const {
                    selectedFormat,
                    selectedUnits,
                    selectedSizeIndex,
                    customSize,
                    qrdpi,
                    isAdvanced,
                    pagesize,
                    pageorientation,
                    marginleft,
                    marginright,
                    margintop,
                    marginbottom,
                    repeat,
                    spacingx,
                    spacingy,
                    custompagewidth,
                    custompageheight,
                    showcutlines,
                } = this.downloadSettings;
                this.selectedFormat = selectedFormat ? selectedFormat : 'png';
                this.selectedUnits = selectedUnits ? selectedUnits : 'px';
                this.selectedSizeIndex = selectedSizeIndex ? selectedSizeIndex : 1;
                this.customSize = customSize ? customSize : 640;
                this.qrdpi = qrdpi ? qrdpi : 150;
                this.isAdvanced = isAdvanced ? isAdvanced : false;
                this.pagesize = pagesize ? pagesize : '210x297';
                this.pageorientation = pageorientation ? pageorientation : 'portrait';
                this.marginleft = marginleft ? marginleft : 0;
                this.marginright = marginright ? marginright : 0;
                this.margintop = margintop ? margintop : 0;
                this.marginbottom = marginbottom ? marginbottom : 0;
                this.repeat = repeat ? repeat : 1;
                this.spacingx = spacingx ? spacingx : 0;
                this.spacingy = spacingy ? spacingy : 0;
                this.custompagewidth = custompagewidth ? custompagewidth : 0;
                this.custompageheight = custompageheight ? custompageheight : 0;
                this.showcutlines = showcutlines ? showcutlines : false;
            }
        },
    }
</script>

<style scoped lang="scss">
.v-dialog__content {
  &::v-deep {
    .v-dialog {
      width: 70% !important;
      max-width: 1000px;
      @media (max-width: 768px) {
        width: 90% !important;
      }
      .qr-settings-wrap {
        height: 100%;
        .pro-qr-preview {
          background: #EEEEEE;
          overflow-y: auto;
        }
        .pro-qr-settings {
          padding: 30px 40px;
          height: 500px;
          overflow-y: auto;
          @media(max-width: 960px) {
            padding: 20px;
          }
          .smfm-custom-size-input {
            .v-input__slot {
              width: 200px;
            }
          }
        }
      }
      @media(max-width: 960px) {
        .qr-settings-wrap {
          overflow-y: auto;
          .pro-qr-preview, .pro-qr-settings {
            height: auto;
            overflow-y: hidden;
          }
        }
      }
    }
  }
}

::v-deep {
  .v-input {
    label {
      color: #393E46!important;
    }
  }
}
</style>