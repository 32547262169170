<template>
    <v-row class="dynamic-list">
        <!-- QR codes table -->
        <v-col md="12" lg="8" cols="12" class="smfm-pro-cards ps-0 pb-4"
            :class="{'smfm-h-100': $vuetify.breakpoint.lgAndUp}">
            <v-card flat :tile="$vuetify.breakpoint.smAndDown" class="pa-5"
                :class="[
                    $vuetify.breakpoint.mdAndDown ? (currentqr.image ? '' : 'smfm-h-100') : 'smfm-h-100'
                ]">
                <!-- Header -->
                <div class="d-flex flex-wrap justify-space-between align-center mb-4">
                    <div class="smfm-page-heading">
                        {{ type === 'dynamic' ? $t('pro.dynamiccodes') : $t('pro.staticcodes')}}
                    </div>
                    <div class="d-flex flex-wrap align-center">
                        <v-select
                            v-model="filteredTags"
                            :items="tags"
                            :label="$t('pro.qrlist.filterbytag')"
                            hide-details
                            outlined
                            dense
                            multiple
                            clearable
                            class="filter-tags my-1">
                        </v-select>
                        <v-text-field
                            :label="$t('pro.searchqr')"
                            v-model="search"
                            :prepend-inner-icon="iconSearch"
                            single-line
                            hide-details
                            outlined
                            dense
                            clearable
                            class="my-1"
                            :class="{'ms-5': $vuetify.breakpoint.smAndUp}">
                        </v-text-field>
                    </div>
                </div>
                <div class="d-flex justify-center align-center" :class="{'smfm-h-100': isloading}">
                    <loading v-if="isloading" :size="3" :class="{'my-16': $vuetify.breakpoint.mdAndDown}"></loading>
                    <div v-else class="smfm-w-100">
                        <!-- Bulk actions -->
                        <div v-if="selected.length > 0" class="d-flex align-center action-btns-wrap">
                            <!-- Download QR codes -->
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">                           
                                    <v-btn v-bind="attrs" v-on="on"
                                        small color="#393E46" class="action-btn"
                                        @click="bulkDownload">
                                        <v-icon color="#fff">{{ iconDownload }}</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('app.download.download') }}</span>
                            </v-tooltip>
                            <!-- Add tags dialog -->
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" 
                                        small color="#393E46" class="action-btn"
                                        @click="tagsAddDialogVisible = true">
                                        <img :src="'/images/pro/plus_tag.svg'"/>
                                    </v-btn>
                                </template>
                                <span>{{ $t('pro.qrlist.addtags') }}</span>
                            </v-tooltip>
                            <!-- Remove tags dialog -->
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">                                    
                                    <v-btn v-bind="attrs" v-on="on"
                                        small color="#393E46" class="action-btn"
                                        @click="tagsRemoveDialogVisible = true">
                                        <img :src="'/images/pro/minus_tag.svg'"/>
                                    </v-btn>
                                </template>
                                <span>{{ $t('pro.qrlist.removetags') }}</span>
                            </v-tooltip>
                            <!-- Delete QR codes dialog -->
                            <simple-dialog :header="$t('pro.qrlist.delete')" :no-title="true"
                                @save="bulkDelete">
                                <template v-slot:activator="{ open }">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn v-bind="attrs" v-on="on"
                                                small color="error" class="action-btn" @click="open">
                                                <img :src="'/images/pro/delete_outline.svg'"/>
                                            </v-btn>
                                        </template>
                                        <span>{{ $t('pro.qrlist.delete') }}</span>
                                    </v-tooltip>
                                </template>
                                <div v-if="selected.length > 1">
                                    {{$t('pro.qrlist.confirmdelete')}}
                                    <div v-for="code in selected" :key="code.codeid" class="text--primary">
                                        {{ code.name }}
                                    </div>
                                </div>
                                <div v-else class="text--primary" v-html="$t('pro.qrlist.confirmdelete1', {name: `<strong>${selected[0].name}</strong>`})">
                                </div>
                                <template v-slot:buttons="{save}">
                                    <v-btn class="smfm-lg-btn" color="error" @click="save">{{ $t('pro.qrlist.delete') }}</v-btn>
                                </template>
                            </simple-dialog>
                        </div>
                        <!-- Dynamic QR codes table -->
                        <v-data-table v-if="hasdata" ref="codes" class="smfm-data-table qr-codes"
                            :class="{'down': selected.length > 0}"
                            v-model="selected"
                            show-select
                            :headers="headers"
                            :items="filteredCodes"
                            item-key="codeid"
                            :search="search"
                            :items-per-page="itemsPerPage"
                            :item-class= "row_classes" 
                            mobile-breakpoint="0"
                            :footer-props="{
                                itemsPerPageAllText: $t('pro.all'),
                                itemsPerPageText: $t('pro.rowsperpage')
                            }"
                            @update:items-per-page="tableOptionsPerPageChanged"
                            @dblclick:row="selectQrdoubleClick"
                            @click:row="selectQr">
                            <template v-slot:[`header.data-table-select`]="{ props, on }">
                                <v-simple-checkbox color="primary"
                                    :ripple="false" v-bind="props" v-on="on">
                                </v-simple-checkbox>
                            </template>
                            <template v-slot:[`item.data-table-select`]="{ isSelected,select }">
                                <v-simple-checkbox color="primary" :ripple="false"
                                    :value="isSelected" @input="select($event)">
                                </v-simple-checkbox>
                            </template>
                            <!-- QR code -->
                            <template v-slot:[`item.name`]="{ item }">
                                <div class="d-flex align-center">
                                    <pro-qr-image
                                        :qrurl="item.qrurl"
                                        :codeid="item.codeid"
                                        :qrlabel="item.name"
                                        @loaded="qrImageLoaded(item.codeid)"
                                        :ref="`code${item.codeid}`"
                                        class="me-3">
                                    </pro-qr-image>
                                    <div>
                                        <div class="d-flex align-center mb-1">
                                            <v-icon color="#909295" class="me-2">
                                                {{ getQrIcon(type, item.type) }}
                                            </v-icon>
                                            <span class="qr-list-item-name">{{ item.name }}</span>
                                        </div>
                                        <div v-if="type === 'dynamic'" class="smfm-link">
                                            <a :href="item.text+'?notracking=1'" target="_blank"
                                                class="d-flex align-center smfm-externallink smfm-externallink-small" @click.stop>
                                                {{ item.text }}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <!-- Statistics button -->
                            <template v-slot:[`item.codeid`]="{ item }">
                                <v-btn outlined small color="#C7C8CA" class="stats-btn"
                                    @click.stop="showStats(item.codeid)">
                                    <span>{{ $t('pro.qrlist.statistics') }}</span>
                                </v-btn>
                            </template>
                            <!-- Date -->
                            <template v-slot:[`item.timecreated`]="{ item }">
                                {{ formattedDate(item.timecreated) }}
                            </template>
                            <!-- Tags -->
                            <template v-slot:[`item.text`]="{ item, index }">
                                <div class="d-flex align-center">
                                    <v-btn small outlined color="primary" class="add-tag-btn me-2"
                                        :id="`addbtn-${index}`"
                                        @click.stop="openAddTag(item.codeid, index)">
                                        <v-icon color="primary">{{ iconPlus }}</v-icon>
                                    </v-btn>
                                    <div class="smfm-w-100">
                                        <div class="d-flex flex-wrap align-center tags-wrap">
                                            <v-chip v-for="(tag, index) in item.tags" :key="`tag-${index}`"
                                                close
                                                color="primary"
                                                label
                                                outlined
                                                class="ma-1"
                                                @click:close="removeTag(item.codeid, tag)">
                                                {{ tag }}
                                            </v-chip>  
                                        </div>  
                                        <pro-tags-add-combo
                                            ref="addtagcombo"
                                            v-if="editableRow[index]"
                                            :codes="codeslist"
                                            :editable-code-id="editableCodeId"
                                            @add="addTags($event, index)">
                                        </pro-tags-add-combo>   
                                    </div>                         
                                </div>
                            </template>
                            <template v-slot:[`footer.page-text`]="items">
                                {{ items.pageStart }} - {{ items.pageStop }} {{ $t('pro.of') }} {{ items.itemsLength }}
                            </template>
                        </v-data-table>
                        <pro-scans-no-data v-else 
                            :icon-type="'total'"
                            :type="type"
                            :has-data="hasdata"
                            :has-create-btn="true">
                        </pro-scans-no-data>
                    </div>
                </div>
            </v-card>
        </v-col>
        <!-- Selected QR code -->
        <v-col md="12" lg="4" cols="12" class="smfm-pro-cards pe-0 pb-4">
            <div v-if="currentqr.image && selectedQR" class="demo-image-container smfm-sticky-md-16">
                <qr-preview :iconname="''" ref="qrpreview" class="mb-4"></qr-preview>
                <pro-qr-download-button :qrlabel="selectedQR.name" ref="qrdownload"
                                        :class="{'mb-2': $vuetify.breakpoint.mdAndUp}">
                </pro-qr-download-button>
                <div class="d-flex flex-wrap justify-space-between align-center">
                    <v-btn v-if="editable" class="smfm-lg-btn qr-btn edit mb-2"
                        :class="{'mx-5': $vuetify.breakpoint.smAndDown}"
                        @click="editQrStyle(selectedQR.codeid)">
                        <span>{{ $t('pro.qrlist.editcode') }}</span>
                    </v-btn>   
                    <simple-dialog v-if="editable" :header="$t('pro.qrlist.delete')" :no-title="true"
                        @save="deleteCode(selectedQR.codeid)">
                        <template v-slot:activator="{ open }">
                            <v-btn class="smfm-lg-btn qr-btn delete mb-2"
                                :class="{'mx-5': $vuetify.breakpoint.smAndDown}"
                                @click="open">
                                <span>{{ $t('pro.qrlist.deletecode') }}</span>
                            </v-btn>
                        </template>
                        <div class="text--primary"
                             v-html="$t('pro.qrlist.confirmdelete1', {name: `<strong>${selectedQR.name}</strong>`})">
                        </div>
                        <template v-slot:buttons="{save}">
                            <v-btn class="smfm-lg-btn" color="error" @click="save">{{ $t('pro.qrlist.delete') }}</v-btn>
                        </template>
                    </simple-dialog>
                    <v-btn class="smfm-lg-btn qr-btn regenerate mb-2" v-if="!editable" @click="regenerate(selectedQR.codeid)">
                        <span>Regenerate</span>
                    </v-btn>
                </div>
            </div>
        </v-col>
        <!-- Tags add dialog -->
        <pro-tags-add-dialog
            :dialogVisible="tagsAddDialogVisible"
            :selected="selected"
            :codes="codeslist"
            @refresh="softRefresh"
            @close="tagsAddDialogVisible=false">
        </pro-tags-add-dialog>
        <!-- Remove tags dialog -->
        <pro-tags-remove-dialog
            :dialogVisible="tagsRemoveDialogVisible"
            :selected="selected"
            @refresh="softRefresh"
            @close="tagsRemoveDialogVisible=false">
        </pro-tags-remove-dialog>
        <!--Download dialog-->
        <pro-qr-download-dialog
            :qrcodes="qrcodesForBulkDownload"
            :dialogVisible="bulkDownloadDialogVisible"
            :isloading="isdownloading"
            @downloadQR="downloadQR"
            @close="bulkDownloadDialogVisible=false">
        </pro-qr-download-dialog>
    </v-row>
</template>

<script>
    import { mapGetters, mapActions } from "vuex";
    import { mdiMagnify, mdiPlus } from "@mdi/js";
    import qrMixin from "@/mixins/qrMixin";
    import userMixin from "@/mixins/userMixin";
    import qrdownload from "@/mixins/qrdownload";
    import customIcons from "@/mixins/customIcons";
    import ProQrImage from "@/components/pro/ProQrImage";
    import QrPreview from "@/components/demo/QrPreview";
    import SimpleDialog from "@/components/forms/SimpleDialog";
    import Loading from "@/components/common/Loading";
    import ProScansNoData from "@/components/pro/dashboard/ProScansNoData";
    import ProTagsAddDialog from '@/components/pro/lists/ProTagsAddDialog';
    import ProTagsRemoveDialog from '@/components/pro/lists/ProTagsRemoveDialog';
    import ProTagsAddCombo from '@/components/pro/lists/ProTagsAddCombo';
    import ProQrDownloadButton from "@/components/pro/download/ProQrDownloadButton";
    import ProQrDownloadDialog from "@/components/pro/download/ProQrDownloadDialog";

    export default {
        name: "ProQrList",
        mixins: [ qrMixin, userMixin, customIcons, qrdownload ],
        components: {
            ProQrDownloadDialog,
            Loading,
            ProQrImage, 
            QrPreview,
            ProQrDownloadButton,
            SimpleDialog,
            ProScansNoData,
            ProTagsAddDialog,
            ProTagsRemoveDialog,
            ProTagsAddCombo
        },
        props: {
            type: null, // static or dynamic
            editable: {default: true},
            apiurl: {default: 'pro/codes'},
        },
        data() {
            return {
                codeslist: null,
                isloading: false,
                search: '',
                selectedQR: null,
                selected: [],
                tagsAddDialogVisible: false,
                tagsRemoveDialogVisible: false,
                editableCodeId: null,
                editableRow: null,
                editableKeys: [],
                bulkDownloadDialogVisible: false,
                qrcodesForBulkDownload: [],
                isdownloading: false,
            }
        },
        computed: {
            ...mapGetters({currentqr: 'getCurrentQr'}),
            ...mapGetters('qrcodes_pro', ['QRCodes', 'itemsPerPage', 'tagFilters']),
            hasdata() {
                return this.codeslist && !!this.codeslist.length;
            },
            headers() { 
                let columns = [
                    {
                        text: this.$t('pro.qrlist.qrcode'),
                        align: "start",
                        value: "name",
                    },
                    { text: this.$t('pro.qrlist.datecreated'), value: "timecreated" },
                    { text: this.$t('pro.qrlist.tags'), value: "text", sortable: false },
                ];
                if (this.type === 'dynamic') columns.splice(1, 0, { 
                    text: this.$t('pro.qrlist.statistics'), value: "codeid", sortable: false
                });
                return columns;
            },
            qrlabeldisplay() {
                return this.qrlabel ? this.qrlabel : (this.currentqr.qrlabel ? this.currentqr.qrlabel : '')
            },
            iconSearch() { return mdiMagnify },
            iconPlus() { return mdiPlus },
            filteredCodes() {
                let filtered = [];
                filtered = this.QRCodes && this.QRCodes.filter(code => {
                    if (code.tags && code.tags.length > 0) {
                        return code.tags.some(tag => this.filteredTags.includes(tag))
                    }
                });
                return filtered.length > 0 ? filtered : this.QRCodes;
            },
            tags() {
                const tags = [];
                const tagsInCodes = this.QRCodes && [].concat(...this.QRCodes.map(code => {
                    return code.tags && code.tags.length > 0 ? code.tags : [];
                }));
                tagsInCodes && tagsInCodes.forEach(tag => {
                    if (!tags.includes(tag)) tags.push(tag);
                })
                return tags;
            },
            selectedCodes() {
                // TODO: Make sure the selected codes are sorted in the same order as how they appear on the page
                // regardless of the order of how we checked/unchecked them
                return this.selected.map(code => code.codeid);
            }, 
            filteredTags: {
                get() {
                    return this.tagFilters;
                },
                set(newValue) {
                    this.setTagFilters(newValue);
                }
            }
        },
        mounted() {
            this.refresh()
            this.editableKeys = this.QRCodes.length > 0 ? [...Array(this.QRCodes.length).keys()] : [];
        },
        methods: {
            ...mapActions(['setCurrentQr', 'resetCurrentQr']),
            ...mapActions('qrcodes_pro', ['setDynamicCodes', 'addTag', 'deleteTag', 'setItemsPerPage', 'setTagFilters']),
            formattedDate(timestamp) {
                let date = new Date(timestamp);
                return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
            },
            refresh() {
                this.resetCurrentQr()
                this.isloading = true
                this.editableRow = this.editableKeys.map(() => false);
                this.softRefresh()
                    .finally(() => this.isloading = false)
            },
            softRefresh() {
                // Refresh nicely, only affected entities, without blinking
                return this.authGet({url: this.apiurl, params: {type: this.type}})
                    .then(res => {
                        this.codeslist = Object.values(res.data);
                        this.setDynamicCodes(this.codeslist);
                        this.selected = [];
                    })
                    .catch(e => console.error(e))
            },
            deleteCode(codeid) {
                this.authDelete({url: this.apiurl + '/' + codeid})
                    .then(() => {
                        this.$delete(this.codeslist, codeid);
                        this.selectedQR = null;
                    })
                    .then(() => this.softRefresh())
                    .catch(e => console.error(e))
            },
            editQrStyle(codeid) {
                this.$emit('edit', codeid)
            },
            regenerate(codeid) {
                console.log('regenerate for '+codeid)
                console.log(this.apiurl);
                this.authPost({url: this.apiurl + '/' + codeid + '/regenerate'})
            },
            row_classes(item) {
                if (this.selectedQR && item.codeid === this.selectedQR.codeid) return 'selected';
            },
            selectQr(code) {
                //const index = this.QRCodes.findIndex(el => el.codeid === code.codeid);
                if (code && code.codeid && this.$refs['code'+code.codeid]) {
                    this.selectedQR = code;
                    this.setCurrentQr(this.$refs['code'+code.codeid].summary)
                }
            },
            selectQrdoubleClick(e, row = null) {
                if (row?.item?.codeid) {
                    this.editQrStyle(row.item.codeid)
                }
            },
            showStats(codeid) {
                this.$router.push({name: 'codestats', params: {...this.$route.params, codeid}})
            },
            bulkDelete() {
                this.authPost({url: `pro/codesbulk/delete`, params: {codes: this.selectedCodes}})
                    .then(() => this.softRefresh())
                    .catch(e => console.error(e))
            },
            openAddTag(codeid, index) {
                this.editableRow = this.editableKeys.map(() => false);
                this.editableCodeId = codeid;
                this.editableRow[index] = true;
                this.$nextTick(() => {
                    this.$refs.addtagcombo.$refs.tagscombobox.focus();
                })
            },
            addTags(event, index) {
                this.editableRow[index] = false;
                this.addTag({ codeid: this.editableCodeId, tags: event })
                this.authPost({
                    url: 'pro/codesbulk/addtags', 
                    params: { codes: [this.editableCodeId], tags: event }
                }).then(() => {
                    this.editableCodeId = null;
                    this.tagstoadd = [];
                    document.getElementById(`addbtn-${index}`).focus();
                })
                    .then(() => this.softRefresh())
                    .catch(e => console.error(e))
            },
            removeTag(codeid, tag) {
                this.deleteTag({ codeid, tag })
                this.removeTags([codeid], [tag])
            },
            removeTags(codes, tags) {
                return this.authPost({
                    url: 'pro/codesbulk/removetags', 
                    params: { codes, tags }
                }).then(() => this.softRefresh())
                    .catch(e => console.error(e))
            },
            bulkDownload() {
                this.updateQrcodesForBulkDownload()
                this.bulkDownloadDialogVisible = true
            },
            updateQrcodesForBulkDownload() {
                let codes = []
                if (this.selectedCodes.length) {
                    for (let codeid of this.selectedCodes) {
                        let el = this.$refs['code'+codeid]
                        el.loadImage() // Image should be loaded on v-intersect but it is possible that
                        // user clicks "select all" and selects images that are outside of view
                        codes.push(el.summary)
                    }
                }
                this.qrcodesForBulkDownload = codes
            },
            qrImageLoaded(codeid) {
                if (this.bulkDownloadDialogVisible && this.selectedCodes.includes(codeid)) {
                    this.updateQrcodesForBulkDownload()
                }
                if (this.currentqr && this.currentqr.codeid === codeid) {
                    this.selectQr(codeid)
                }
            },
            downloadQR(params) {
                this.isdownloading = true;
                // this.bulkDownloadDialogVisible = false;
                this.downloadQrCodesPro(this.qrlabel, params, this.authGetBinary, this.authPostBinary)
                    .then(() => {
                        this.isdownloading = false;
                    })
            },
            tableOptionsPerPageChanged(e) {
                console.log('tableOptionsPerPageChanged', e);
                this.setItemsPerPage(e);
            },
        },
        watch: {
            selected() {
                // When the bulk selection checkboxes are checked/unchecked
                this.resetCurrentQr();
            }
        }
    }
</script>

<style scoped lang="scss">
.dynamic-list {
    height: 100%;
    .action-btns-wrap {
        position: sticky;
        top: 16px;
        background: #F6F6F6;
        padding-top: 9.5px;
        padding-bottom: 9.5px;
        padding-inline-end: 10px;
        padding-inline-start: 40px;
        border-radius: 6px;
        z-index: 5;
        .action-btn {
            min-width: 28px !important;
            width: 28px !important;
            height: 28px !important;
            margin-inline-end: 10px;
            border-radius: 6px;
            padding: 0;
        }
    }
    .filter-tags {
        width: 320px;
    } 
    .qr-btn {
        width: 48% !important;
        background-color: #fff !important;
        border: 1px solid #EEEEEE;
        border-radius: 12px;
        &.edit {
            color: #3271D9;
        }
        &.delete {
            color: #C9454D;
        }
        @media(max-width: 960px) {
            width: calc(100% - 40px) !important;
        }
    }
    .tags-wrap {
        .v-chip.v-size--default {
            height: 30px;
        }
    }
    .smfm-data-table.qr-codes {
        &.down {
            margin-top: -47px;
        }
    }
    .qr-list-item-name {
        max-width: 250px;
        max-height: 40px;
        overflow: hidden;
        text-overflow: ellipsis;
        @media(max-width: 960px) {
            max-width: 150px;
        }
    }
}
</style>