<script>
    import { Bar, mixins } from "vue-chartjs";

    export default {
        name: "StackedBarChart",
        extends: Bar,
        mixins: [ mixins.reactiveProp ],
        data() {
            return {
                options: { 
                    responsive: true, 
                    maintainAspectRatio: false,
                    legend: false,
                    scales: {
                        xAxes: [{
                            stacked: true,
                            ticks: {
                                fontSize: 14,
                                fontColor: '#909295'                             
                            },
                        }],
                        yAxes: [{
                            stacked: true,
                            ticks: {
                                min: 0,
                                beginAtZero: true,
                                fontSize: 14,
                                fontColor: '#909295',
                                callback (value) {
                                    if (Math.floor(value) === value) return value;
                                },
                            }
                        }]
                    },
                    tooltips: {
                        displayColors: false,
                        callbacks: {
                            label (tooltipItem, data) {
                                let value;
                                if (data.labels.length && data.datasets.length) {
                                    const label = data.datasets[tooltipItem.datasetIndex].label || '';
                                    if (tooltipItem.datasetIndex === 1) {
                                        value = Number(data.datasets[0].data[tooltipItem.index]) + Number(data.datasets[1].data[tooltipItem.index]) || '';
                                    } else {
                                        value = Number(data.datasets[0].data[tooltipItem.index]) || '';
                                    }
                                    return `${label}: ${value} scans`;
                                }
                            }
                        }
                    }
                }
            }
        },
        mounted () {
            this.renderChart(this.chartData, this.options)
        }
    }
</script>