<script>
    import { HorizontalBar, mixins } from "vue-chartjs";

    export default {
        name: "BarChart",
        extends: HorizontalBar,
        mixins: [ mixins.reactiveProp ],
        data() {
            return {
                options: { 
                    responsive: true, 
                    maintainAspectRatio: false,
                    legend: false,
                    scales: {
                        xAxes: [{
                            ticks: {
                                min: 0,
                                beginAtZero: true,
                                callback (value) {
                                    if (Math.floor(value) === value) return value;
                                },
                                fontSize: 14,
                                fontColor: '#909295'
                            }
                        }],
                        yAxes: [{
                            ticks: {
                                fontSize: 14,
                                fontColor: '#909295'
                            },
                            gridLines : {
                                display : false,
                            }                            
                        }]
                    },
                    tooltips: {
                        displayColors: false,
                        callbacks: {
                            label (tooltipItem, data) {
                                if (data.labels.length && data.datasets.length) {
                                    const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] || '';
                                    return `${value} scans`;
                                }
                            }
                        }
                    }
                }
            }
        },
        mounted () {
            this.renderChart(this.chartData, this.options)
        }
    }
</script>