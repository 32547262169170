<template>
    <div>
        <v-btn :block="$vuetify.breakpoint.mdAndUp" class="smfm-primary-btn smfm-lg-btn" 
            :disabled="!currentqr.image"
            :class="{'mx-5 smfm-block-btn': $vuetify.breakpoint.smAndDown}"
            @click="openQRDownloadDialog">
            <loading v-if="isloading"></loading>
            <span v-else>{{ $t('app.download.downloadqr') }}</span>
        </v-btn>
        <!-- QR download dialog -->
        <pro-qr-download-dialog
            :dialogVisible="qrDownloadDialogVisible"
            :qrcodes="[currentqr]"
            :qrlabel="qrlabel"
            :isloading="isloading"
            @downloadQR="downloadQR"
            @close="qrDownloadDialogVisible=false">
        </pro-qr-download-dialog>
        <v-btn v-if="allowsave" :block="$vuetify.breakpoint.mdAndUp" class="smfm-lg-btn save-btn" 
            :disabled="!currentqr.image"
            :class="{'mx-5 smfm-block-btn': $vuetify.breakpoint.smAndDown}"
            @click="saveForLater">
            {{ $t('pro.forms.saveforlater') }}
        </v-btn>
    </div>

</template>

<script>
    import { mapGetters } from "vuex";
    import userMixin from "@/mixins/userMixin";
    import qrdownload from "@/mixins/qrdownload";
    import qrMixin from "@/mixins/qrMixin";
    import Loading from "@/components/common/Loading";
    import ProQrDownloadDialog from "@/components/pro/download/ProQrDownloadDialog";

    export default {
        name: "ProQrDownloadButton",
        props: {
            qrlabel: null,
            allowsave: { default: false },
        },
        components: { ProQrDownloadDialog, Loading },
        mixins: [ qrdownload, qrMixin, userMixin ],
        data() {
            return {
                qrDownloadDialogVisible: false,
                isloading: false,
            }
        },
        computed: {
            ...mapGetters({currentqr: 'getCurrentQr'}),
            qrlabeldisplay() {
                return this.qrlabel ? this.qrlabel : (this.currentqr.qrlabel ? this.currentqr.qrlabel : '')
            },
        },
        methods: {
            openQRDownloadDialog() {
                this.qrDownloadDialogVisible = true;
            },
            saveForLater() {
                this.$emit('saved', this.qrlabeldisplay)
            },
            downloadQR(params) {
                this.isloading = true;
                // this.qrDownloadDialogVisible = false;
                this.downloadQrCodesPro(this.qrlabel, params, this.authGetBinary, this.authPostBinary)
                    .then(() => {
                        this.isloading = false;
                    })
            },
        }
    }
</script>

<style scoped lang="scss">
.save-btn {
    background: #CCEFF0 !important;
    margin-top: 10px;
    &.v-btn--disabled.v-btn--has-bg {
        background-color: transparent !important;
    }
}
</style>