<template>
  <div class="d-flex align-center legend">
    <div v-for="(item, index) in items" :key="index"
      class="d-flex align-center ms-8">
      <div class="dot me-2" :style="`background-color: ${item.color}`"></div>
      {{ item.label }}
    </div>
  </div>
</template>

<script>
    export default {
        name: "ProChartLegend",
        computed: {
            items() {
                return [
                    { label: this.$t('pro.dashboard.total'), color: '#12C7D0' },
                    { label: this.$t('pro.dashboard.unique'), color: '#03949A' },
                ]
            }
        }
    }
</script>

<style scoped lang="scss">
.legend {
  font-size: 14px;
  line-height: 21px;
  color: #21252C;
  margin-inline-start: auto;
  .dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }
}
</style>