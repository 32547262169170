<template>
  <v-combobox
    ref="tagscombobox"
    v-model="tagstoadd"
    :items="tagsToAddList"
    :search-input.sync="search"
    multiple
    outlined
    chips
    hide-details
    class="add-tags-combo mt-1 ms-1"
    @focus.stop
    @change="addNewTag">
    <template v-slot:selection="data">
      <v-chip
        v-bind="data.attrs"
        :input-value="data.selected"
        close
        color="primary"
        label
        outlined
        @click:close="remove(data.item)">
        {{ data.item }}
      </v-chip>
    </template>
    <template v-slot:item="data">
      <v-list-item-content v-text="data.item"></v-list-item-content>
    </template>
    <template v-slot:no-data>
      <v-list-item class="no-match-found">
        <v-list-item-content class="py-0">
          <v-list-item-title class="text-caption" v-html="$t('pro.qrlist.tagnotfound', {tagname: search})">
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-combobox> 
</template>

<script>
    export default {
        name: "ProTagsAddCombo",
        props: {
            codes: {
                type: Array,
                default: () => {}
            },
            editableCodeId: {
                type: String,
                default: null
            },
        },
        data() {
            return {
                tagstoadd: [],
                search: null,
            }
        },
        computed: {
            tagsToAddList() {
                if (this.editableCodeId) {
                    const tags = [];
                    const codeWithTags = this.codes.filter(code => code.tags && code.tags.length > 0);
                    if (codeWithTags && codeWithTags.length > 0) {
                        const notSelected = codeWithTags.filter(code => code.codeid !== this.editableCodeId);
                        const tagsInNotSelected = [].concat(...notSelected.map(code => code.tags));
                        const tagSelected = this.codes.find(code => code.codeid === this.editableCodeId);
                        const tagsInSelected = tagSelected.tags && tagSelected.tags.length > 0 ? tagSelected.tags : [];
                        tagsInNotSelected && tagsInNotSelected.forEach(tag => {
                            if (!tags.includes(tag) && !tagsInSelected.includes(tag)) tags.push(tag);
                        })
                    }
                    return tags;
                } else {
                    return [];
                }
            }, 
        },
        methods: {
            remove(tag) {
                const index = this.tagstoadd.indexOf(tag)
                if (index >= 0) this.tagstoadd.splice(index, 1)
            },
            addNewTag() {
                this.$emit('add', this.tagstoadd)
            }
        }
    }
</script>

<style lang="scss">
.v-list-item {
  &.no-match-found {
    min-height: 30px !important;
    .fvalue {
      background: #DCF6F6;
      padding: 2px 5px;
      border-radius: 4px;
    }
  }
}
</style>