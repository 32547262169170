<template>
    <div>
        <v-toolbar dark color="primary">
            <v-btn icon dark @click="$emit('closed')">
                <v-icon>{{ iconClose }}</v-icon>
            </v-btn>
            <v-toolbar-title>{{ $t('pro.library.editfile', {file: filenameToEdit}) }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <pro-save-preset :config="config" @saved="onSave" :existingfile="file">
                    <template v-slot:activator="{open}">
                        <v-btn :disabled="loading" dark text @click="open">{{ $t('app.save') }}</v-btn>
                    </template>
                </pro-save-preset>
            </v-toolbar-items>
        </v-toolbar>

        <v-card>
            <v-row>
                <v-col md="8" cols="12" class="left-column">
                    <qrstyles @change="autoSubmit" ref="qrstyles" :ispreseteditor="true"></qrstyles>
                </v-col>
                <v-col md="4" cols="12" class="right-column">
                    <v-card>
                        <!-- TODO hardcoded string -->
                        <span v-html="$t('pro.library.matrixsize')"></span>
                        <v-chip :color="previewsize === 0 ? 'primary' : null" @click="previewsize=0">{{ $t('pro.library.matrixs') }}</v-chip>
                        <v-chip :color="previewsize === 1 ? 'primary' : null" @click="previewsize=1">{{ $t('pro.library.matrixm') }}</v-chip>
                        <v-chip :color="previewsize === 2 ? 'primary' : null" @click="previewsize=2">{{ $t('pro.library.matrixl') }}</v-chip>
                        <v-chip :color="previewsize === 3 ? 'primary' : null" @click="previewsize=3">{{ $t('pro.library.matrixxl') }}</v-chip>
                    </v-card>
                    <div class="demo-image-container smfm-sticky-md-100">
                        <qr-preview iconname="url" ref="qrpreview"></qr-preview>
                    </div>
                </v-col>
            </v-row>

        </v-card>
    </div>
</template>

<script>
    import {mdiClose} from "@mdi/js";
    import qrMixin from '../../mixins/qrMixin'
    import ProSavePreset from "./ProSavePreset";
    import Qrstyles from "@/components/forms/Qrstyles";
    import QrPreview from "@/components/demo/QrPreview";
    import userMixin from "@/mixins/userMixin";

    export default {
        name: "ProPresetEditor",
        components: {QrPreview, Qrstyles, ProSavePreset},
        mixins: [qrMixin, userMixin],
        props: {
            file: null,
            path: null,
            activated: {default: false},
            configinitial: {type: Object},
        },
        data() {
            return {
                loading: null,
                iconClose: mdiClose,
                previewsize: 1,
                config: null
            }
        },
        computed: {
            text() {
                if (this.previewsize === 0) return 'http://qrs.re'
                if (this.previewsize === 2) return 'https://scanmefindme.com/en/create-a-qr-code-for-a-website-or-url'
                if (this.previewsize === 3) return "BEGIN:VCARD\r\nVERSION:4.0\r\nFN:ScanMeFindMe\r\nN:;;;;\r\nORG:ScanMeFindMe\r\nURL:https://scanmefindme.com\r\nREV:2020-10-21T15:29:24.583Z\r\nEND:VCARD\r\n"
                return 'https://scanmefindme.com'
            },
            filenameToEdit() {
                return this.file ?
                    this.file.split('/').reverse()[0].replace(/\.[^/.]+$/, "") : ''
            },
        },
        created() {
            this.activated && this.onActivated()
        },
        methods: {
            onActivated() {
                this.config = JSON.parse(JSON.stringify(this.configinitial))
                this.$nextTick(() => {
                    this.$refs.qrstyles.setConfig(this.config)
                    this.autoSubmit()
                })
            },
            onSave() {
                this.$emit('saved')
            },
            autoSubmit() {
                this.config = JSON.parse(JSON.stringify(this.$refs.qrstyles.config))
                let params = {text: this.text, config: this.config,}
                this.refreshQrPro(this.authPut({url: 'pro/codes', params}), this.text)
            },
        },
        watch: {
            activated(v) {
                v && this.onActivated()
            },
            previewsize(v, oldv) {
                if (v !== oldv) this.autoSubmit()
            },
        }
    }
</script>

<style scoped>

</style>
