import { render, staticRenderFns } from "./ProTagsAddCombo.vue?vue&type=template&id=1a5f15c9&"
import script from "./ProTagsAddCombo.vue?vue&type=script&lang=js&"
export * from "./ProTagsAddCombo.vue?vue&type=script&lang=js&"
import style0 from "./ProTagsAddCombo.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VChip,VCombobox,VListItem,VListItemContent,VListItemTitle})
