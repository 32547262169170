<template>
    <div>
        <h3>Dashboard</h3>
    </div>
</template>

<script>
    export default {
        name: "AdminDashboard",
    }
</script>

<style scoped>

</style>
