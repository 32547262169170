import Vue from 'vue'
import VueRouter from 'vue-router'
import AdminDashboard from "@/components/admin/AdminDashboard";
import ProLibrary from "@/components/pro/library/ProLibrary";
import AdminProfile from "@/components/admin/AdminProfile";
import AdminUsers from "@/components/admin/AdminUsers";
import AdminUsersCodes from "@/components/admin/AdminUsersCodes";
import ProCodeStats from "@/components/pro/ProCodeStats";

Vue.use(VueRouter)

const routes = [
    { path: '/', component: AdminDashboard },
    { path: '/admin_login_callback', name: 'loginCallback', meta: {skiplogin: true} },
    { path: '/profile', component: AdminProfile },
    { path: '/logout', name: 'logout', meta: {skiplogin: true} },
    { path: '/library', component: ProLibrary },
    { path: '/users', component: AdminUsers, name: 'users' },
    { path: '/users/:userid/codes/:codeid', component: ProCodeStats, name: 'codestats' },
    { path: '/users/:userid/codes', component: AdminUsersCodes, name: 'usercodes' },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        return { x: 0, y: 0 }
    }
})

export default router
