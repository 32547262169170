<template>
    <v-app class="smfm-app-wrapper gray-bg admin">
        <v-navigation-drawer v-model="drawer" :clipped="$vuetify.breakpoint.mdAndUp" v-if="isLoggedIn" app>
            <v-list dense>
                <template v-for="item in navbaritems">
                    <v-list-item :key="item.text" link :to="item.link">
                        <v-list-item-action>
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>{{ item.text }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </v-list>
        </v-navigation-drawer>
        <v-app-bar app fixed elevate-on-scroll color="white" :clipped-left="$vuetify.breakpoint.mdAndUp">
            <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
            <v-app-bar-nav-icon>
                <router-link to="/"><img src="/images/logo.svg" width="50" height="50"></router-link>
            </v-app-bar-nav-icon>
            <v-toolbar-title v-cloak class="pl-4">{{ appname }} ADMIN</v-toolbar-title>

            <v-spacer></v-spacer>
            <admin-header></admin-header>
        </v-app-bar>
        <v-main>
            <v-container style="max-width: 1300px" v-cloak v-if="isLoggedIn">
                <router-view class="view"></router-view>
            </v-container>
                <admin-login></admin-login>
        </v-main>
        <v-footer app id="footer" dark absolute style="z-index: 0" v-cloak>
            <v-row justify="center">
                <!--....Footer....-->
            </v-row>
        </v-footer>
    </v-app>
</template>

<script>
    import {mapActions} from 'vuex'
    import AdminLogin from "@/components/admin/AdminLogin";
    // import {mdiImageMultiple, mdiPlusCircle, mdiQrcode, mdiViewDashboard} from "@mdi/js";
    import {mdiImageMultipleOutline, mdiAccountMultiple} from "@mdi/js";
    import AdminHeader from "@/components/admin/AdminHeader";
    import userMixin from "@/mixins/userMixin";
    import customIcons from "@/mixins/customIcons";

    export default {
        name: "Admin",
        mixins: [userMixin, customIcons],
        components: {AdminHeader, AdminLogin},
        data: () => ({
            dialog: false,
            drawer: null,
            appname: null,
        }),
        computed: {
            navbaritems() {
                return [
                    // { icon: mdiViewDashboard, text: 'Dashboard', link: '/' },
                    // { icon: mdiPlusCircle, text: 'Create new code', link: '/create' },
                    // { icon: mdiQrcode, text: 'View dynamic codes', link: '/dynamic' },
                    // { icon: mdiQrcode, text: 'View static codes', link: '/static' },
                    { icon: mdiImageMultipleOutline, text: 'Library', link: '/library' },
                    { icon: mdiAccountMultiple, text: 'Users', link: '/users' },
                ]
            },
        },
        created() {
            this.appname = process.env.VUE_APP_NAME
            this.onLogin()
        },
        methods: {
            ...mapActions(['loadUserLibrary']),
            onLogin() {
                // Executed when login process is finished and information about the user is retrieved.
                if (!this.isLoggedIn) { return }
                this.loadUserLibrary()
            }
        },
        watch: {
            isLoggedIn() { this.onLogin() },
        }
    }
</script>

<style scoped>

</style>