<template>
    <v-card>
        <div class="float-right pb-5"><v-btn text @click="reloadUsers()">Refresh <v-icon>{{iconRefresh}}</v-icon></v-btn></div>
        <v-card-title>
            Users
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                :append-icon="iconSearch"
                label="Search"
                single-line
                hide-details
            ></v-text-field>
        </v-card-title>
        <v-data-table :headers="headers"
                      :items="users"
                      :search="search"
                      :options="tableOptions"
                      show-expand
                      :single-expand="false"
                      :expanded.sync="expanded"
                      item-key="username">
            <template v-slot:[`item.username`]="{item}">
                <router-link :to="`/users/${item.username}/codes`">{{ item.username }}</router-link>
                <v-icon v-if="!item.enabled" color="error">{{iconUnchecked}}</v-icon>
            </template>
            <template v-slot:[`item.name`]="{ item }">
                <v-avatar v-if="item.picture" :size="36"><img :src="item.picture"></v-avatar>
                 {{ item.name }}
            </template>
            <template v-slot:[`item.timecreated`]="{ item }">
                {{ formatDate(item.timecreated*1000) }}
            </template>
            <template v-slot:[`item.custom:plan`]="{ item }">
                <span :class="item['custom:plan'] === 'trial' ? ['rounded-pill', 'blue', 'lighten-4', 'pa-1'] : []">
                    {{ item['custom:plan'] }}
                </span>
                <v-icon v-if="isPlanActive(item)" color="success">{{iconCheck}}</v-icon>
                <v-icon v-else color="error" :title="inactivePlanTitle(item)">{{iconUnchecked}}</v-icon>
            </template>
            <template v-slot:[`item.email`]="{ item }">
                {{ item.email }}
                <v-icon v-if="item.email_verified" color="success">{{iconCheck}}</v-icon>
                <v-icon v-else color="error">{{iconUnchecked}}</v-icon>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <simple-dialog header="Delete" :no-title="true" @save="deleteUser(item)" buttontext="Delete">
                    <template v-slot:activator="{ open }">
                        <v-btn @click="open" icon fab small><v-icon>{{ iconDelete }}</v-icon></v-btn>
                    </template>
                    Are you sure you want to completely delete this user? This operation can not be undone
                </simple-dialog>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                    <pre><small>{{item}}</small></pre>
                </td>
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
    import {mdiMagnify, mdiCheck, mdiClose, mdiDelete} from '@mdi/js';
    import userMixin from "@/mixins/userMixin";
    import SimpleDialog from "../forms/SimpleDialog";
    import customIcons from "../../mixins/customIcons";

    export default {
        name: "AdminUsers",
        components: {SimpleDialog},
        mixins: [userMixin, customIcons],
        data() {
            return {
                users: [],
                PaginationToken: null,
                search: '',
                headers: [
                    { text: 'username', value: 'username' },
                    { text: 'name', value: 'name' },
                    { text: 'email', value: 'email' },
                    { text: 'locale', value: 'locale' },
                    { text: 'plan', value: 'custom:plan' },
                    { text: 'timecreated', value: 'timecreated' },
                    { text: 'actions', value: 'actions'}
                ],
                expanded: [],
            }
        },
        computed: {
            iconSearch() { return mdiMagnify },
            iconCheck() { return mdiCheck },
            iconUnchecked() { return mdiClose },
            tableOptions() { return {itemsPerPage: 15} },
            iconDelete() { return mdiDelete }
        },
        created() {
            this.reloadUsers()
        },
        methods: {
            loadUsers(token = null) {
                let params = {'limit': 60}
                if (token) {
                    params['PaginationToken'] = token
                }
                return this.authGet({url: 'admin/users', params})
                    .then(resp => {
                        this.users = this.users.concat(resp.data.users)
                        if (resp.data.PaginationToken) {
                            return this.loadUsers(resp.data.PaginationToken)
                        } else {
                            return null
                        }
                    })
            },
            reloadUsers() {
                this.users = []
                this.loadUsers()
            },
            isPlanActive(item) {
                const curTime = Math.round((new Date().getTime())/1000)
                const planEnds = parseInt(item['custom:planends'])
                const planRenews = parseInt(item['custom:planrenews'])
                return item['custom:plan'] && !(!planRenews && planEnds && planEnds < curTime)
            },
            inactivePlanTitle(item) {
                if (!item['custom:plan']) {
                    return 'No current plan'
                }
                const curTime = Math.round((new Date().getTime())/1000)
                const planEnds = parseInt(item['custom:planends'])
                if (planEnds && planEnds < curTime) {
                    return `Plan ended ${this.formatDate(planEnds*1000)}`
                }
            },
            deleteUser(item) {
                console.log("deeleting")
                console.log(item)
                this.authDelete({url: `admin/users/${item.username}`})
                    .then((resp) => {
                        this.reloadUsers()
                        console.log(resp)
                    })
            }
        }
    }
</script>

<style scoped>

</style>