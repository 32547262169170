<template>
    <div class="pagepreview">
        <div v-for="page in allpages" :key="`page${page}`">
            <div class="previewsvg" v-html="svgpreview[page]"></div>
        </div>
    </div>
</template>

<script>
    import qrdownload from "@/mixins/qrdownload";

    export default {
        name: "ProQrDownloadPreview",
        mixins: [qrdownload],
        props: {
            qrcodes: {default: []}, // list of codes to download (objects with property 'image' that contains svg source)
            imagesize: null,
            marginleft: null,
            marginright: null,
            margintop: null,
            marginbottom: null,
            spacingx: null,
            spacingy: null,
            repeat: { default: 1 },
            paperwidth: null,
            paperheight: null,
            qrdpi: { default: 150 },
            showcutlines: { default: false },
            isvisible: { default: false }, // To be used in watch to refresh content when dialog is displayed
        },
        data() {
            return {
                positions: [],
                svgpreview: [],
            }
        },
        computed: {
            onemm() { return 0.1 / 2.54 * this.qrdpi },
            allimagesloaded() {
                if (!this.qrcodes || !this.qrcodes.length) return false

                for (let qrcode of this.qrcodes) {
                    if (qrcode && qrcode.loading) return false
                }
                return true
            },
            numpages() {
                if (!this.positions || !this.positions.length) return 1
                return this.positions[this.positions.length - 1].page + 1
            },
            allpages() {
                return Array.from(Array(this.numpages).keys())
            },
            previewDisplayRatio() {
                let sizes = [this.paperwidth, this.paperheight]
                if (!this.paperwidth && !this.paperheight) {
                    if (!this.preloadedImages) {
                        return 1
                    }
                    this.preloadedImages.forEach(i => sizes.push(...Object.values(this.getImageSize(i))))
                }
                return 300 / Math.max(...sizes)
            },
            preloadedImages() {
                return this.allimagesloaded ? this.qrcodes.map(qrcode => this.preloadImage(qrcode.image)) : null
            }
        },
        created() {
            if (this.isvisible) this.$nextTick(() => this.refreshPreview())
        },
        methods: {
            actualPaperWidth(page) {
                if (!this.paperwidth && !this.paperheight && this.preloadedImages && this.preloadedImages[page]) {
                    let imagesize = this.getImageSize(this.preloadedImages[page])
                    return imagesize.width
                }
                return this.paperwidth
            },
            actualPaperHeight(page) {
                if (!this.paperwidth && !this.paperheight && this.preloadedImages && this.preloadedImages[page]) {
                    let imagesize = this.getImageSize(this.preloadedImages[page])
                    return imagesize.height
                }
                return this.paperheight
            },
            preloadImage(svg) {
                let d = this.createElement('div', {}, svg)
                let viewbox = d.firstChild.getAttribute('viewBox')
                let width = 1, height = 1
                if (viewbox) {
                    let [a, b, c, d] = viewbox.split(/[ ,]+/)
                    width = parseFloat(c) - parseFloat(a)
                    height = parseFloat(d) - parseFloat(b)
                }
                return {
                    element: d.firstChild,
                    naturalWidth: width,
                    naturalHeight: height,
                }
            },

            getImageSize(img) {
                let size = this.imagesize
                let imagesizeratio = img.naturalWidth / img.naturalHeight
                let imagewidthpx = (imagesizeratio < 1) ? size : size * imagesizeratio
                let imageheightpx = (imagesizeratio >= 1) ? size : size / imagesizeratio
                return {width: imagewidthpx, height: imageheightpx}
            },

            calculatePositionsFillThePage() {
                this.positions = []
                let page = 0

                for (let image of this.preloadedImages) {
                    let {width, height} = this.getImageSize(image)

                    let maxrepeatx = Math.max(1, Math.floor((this.paperwidth - this.marginleft - this.marginright + this.spacingx) / (width + this.spacingx)))
                    let maxrepeaty = Math.max(1, Math.floor((this.paperheight - this.margintop - this.marginbottom + this.spacingy) / (height + this.spacingy)))

                    for (let iy = 0; iy < maxrepeaty; iy++) {
                        for (let ix = 0; ix < maxrepeatx; ix++) {
                            let x = this.marginleft + ix * (width + this.spacingx),
                                y = this.margintop + iy * (height + this.spacingy)
                            this.positions.push({image, page, x, y, width, height})
                        }
                    }

                    page++
                }
            },

            calculatePositions() {
                if (this.repeat < 0) {
                    this.calculatePositionsFillThePage()
                    return
                }

                let lines = []
                let lastline = []
                let lastelright = 0

                this.preloadedImages.forEach( (image) => {
                    //let width = image.naturalWidth, height = image.naturalHeight
                    let {width, height} = this.getImageSize(image)

                    for (let i=0; i<this.repeat; i++) {
                        if (lastline.length && lastelright + this.spacingx + width < this.paperwidth - this.marginright) {
                            let x = lastelright + this.spacingx
                            lastline.push({image, x, width, height})
                            lastelright = x + width
                        } else {
                            if (lastline.length) lines.push(lastline)
                            lastline = [{image, x: this.marginleft, width, height}]
                            lastelright = this.marginleft + width
                        }
                    }
                })
                lines.push(lastline)

                const getLineHeight = (line) => {
                    let height = 0
                    for (let el of line) height = Math.max(height, el.height)
                    return height
                }

                let pages = []
                let lastpage = []
                let lastbottom = 0
                for (let line of lines) {
                    let lineheight = getLineHeight(line)
                    if (lastpage.length && lastbottom + this.spacingy + lineheight < this.paperheight - this.marginbottom) {
                        let y = lastbottom + this.spacingy
                        lastpage.push(line)
                        lastbottom = y + lineheight
                    } else {
                        if (lastpage.length) pages.push(lastpage)
                        lastpage = [line]
                        lastbottom = this.margintop + lineheight
                    }
                }
                pages.push(lastpage)

                this.positions = []
                let ipage = 0
                for (let page of pages) {
                    let y = this.margintop
                    for (let line of page) {
                        for (let position of line) {
                            this.positions.push({...position, page: ipage, y})
                        }
                        y = y + this.spacingy + getLineHeight(line)
                    }
                    ipage++
                }
            },

            createElement(child, attributes, innerHTML='') {
                let childNode
                if (typeof child === "string") {
                    childNode = document.createElement(child);
                } else {
                    childNode = child;
                }
                if (attributes) {
                    for (let att in attributes) {
                        childNode.setAttribute(att, attributes[att]);
                    }
                }
                childNode.innerHTML = innerHTML
                return childNode;
            },

            refreshPreview() {
                if (!this.allimagesloaded) return;
                this.calculatePositions();

                // TODO page size is hardcoded as 300 here:
                // Make sure each page does not exceed 300 x 300
                let sizes = [this.paperwidth, this.paperheight]
                this.allpages.forEach(page => sizes.push(this.actualPaperWidth(page), this.actualPaperHeight(page)))
                let canvasratio = 300 / Math.max(...sizes)

                this.svgpreview = []
                for (let page of this.allpages) {
                    const attrs = {
                        'xmlns': 'http://www.w3.org/2000/svg',
                        'xmlns:xlink': 'http://www.w3.org/1999/xlink',
                        'viewBox': `0 0 ${this.actualPaperWidth(page)} ${this.actualPaperHeight(page)}`,
                        'width': this.actualPaperWidth(page) * canvasratio,
                        'height': this.actualPaperHeight(page) * canvasratio,
                    }
                    let svg = this.createElement('svg', attrs)

                    let cropx = [], cropy = []
                    for (let position of this.positions) {
                        if (position.page === page) {
                            let img = position.image
                            const g = this.createElement('g', {transform: `translate(${position.x} ${position.y})`})
                            const el = img.element.cloneNode(true)
                            el.setAttribute('width', position.width)
                            el.setAttribute('height', position.height)
                            g.appendChild(el)
                            svg.appendChild(g)
                            cropx.push(position.x, position.x + position.width)
                            cropy.push(position.y, position.y + position.height)
                        }
                    }
                    if (this.showcutlines) {
                        svg.appendChild(this.addCropMarks(cropx, cropy))
                    }
                    this.svgpreview[page] = svg.outerHTML
                }
            },

            addCropMarks(cropx, cropy) {
                var g = this.createElement("g", {})
                let mm = this.onemm
                let vertlines = [], horlines = []
                for (let x of cropx) {
                    if (!(parseInt(x) in vertlines)) {
                        const whf = {width: 0.2*mm, height: 5*mm, fill: 'black'}
                        g.appendChild(this.createElement('rect', {x: x-0.1*mm, y: this.margintop - 7*mm, ...whf}))
                        g.appendChild(this.createElement('rect', {x: x-0.1*mm, y: this.paperheight - this.marginbottom - 7*mm, ...whf}))
                        vertlines.push(parseInt(x))
                    }
                }
                for (let y of cropy) {
                    if (!(parseInt(y) in horlines)) {
                        const whf = {width: 5*mm, height: 0.2*mm, fill: 'black'}
                        g.appendChild(this.createElement('rect', {x: this.marginleft-7*mm, y: y-0.1*mm, ...whf}))
                        g.appendChild(this.createElement('rect', {x: this.paperwidth-this.marginright+2*mm, y: y-0.1*mm, ...whf}))
                        horlines.push(parseInt(y))
                    }
                }
                return g
            },
        },
        watch: {
            qrcodes() {
                //this.preloadedImages = null
                this.refreshPreview()
            },
            imagesize() { this.refreshPreview() },
            marginleft() { this.refreshPreview() },
            marginright() { this.refreshPreview() },
            margintop() { this.refreshPreview() },
            marginbottom() { this.refreshPreview() },
            spacingx() { this.refreshPreview() },
            spacingy() { this.refreshPreview() },
            repeat() { this.refreshPreview() },
            paperwidth() { this.refreshPreview() },
            paperheight() { this.refreshPreview() },
            qrdpi() { this.refreshPreview() },
            showcutlines() { this.refreshPreview() },
            allimagesloaded() { this.refreshPreview() },
            isvisible(v) {
                if (v) this.$nextTick(() => this.refreshPreview())
            }
        }
    }
</script>

<style lang="scss" scoped>
.pagepreview {
  height: 100%;
  width: 320px;
  padding: 10px;
  margin: 0 auto;
  & > div {
    margin-bottom: 10px;
    & > .previewsvg {
      background: white;
      display: inline-block;
    }
  }
}
</style>