<template>
  <div class="d-flex flex-column justify-center align-center no-data"
    :class="{'lg': size === 'lg', 'xl': size === 'xl'}">
    <img :src="`/images/pro/${getImage}`"/>
        <div class="font-italic mb-3 secondary--text text-center">
        {{ isStats ? $t('pro.dashboard.nodata') :
            !hasData ? 
            (type === 'dynamic' ? $t('pro.dashboard.nodynamic') : $t('pro.dashboard.nostatic')) :
            $t('pro.dashboard.nodata')
        }}
    </div>
    <v-btn v-if="hasCreateBtn && !hasData && !isStats" class="smfm-primary-btn smfm-md-btn" @click="goToCreate">
        {{ $t('pro.dashboard.create') }}
    </v-btn>
  </div>
</template>

<script>
    import EventBus from '@/plugins/EventBus.js';

    export default {
        name: "ProScansNoData",
        props: {
            hasData: {
                type: Boolean,
                default: true
            },
            type: {
                type: String,
                default: 'dynamic'
            },
            iconType: {
                type: String,
                default: ''
            },
            size: {
                type: String,
                default: ''
            },
            hasCreateBtn: {
                type: Boolean,
                default: false
            },
            isStats: {
                type: Boolean,
                default: false
            },
        },
        computed: {
            getImage() {
                let img = '';
                switch (this.iconType) {
                case 'total': 
                    img = 'no_dynamic.svg';
                    break;
                case 'date': 
                    img = 'no_dynamic_date.svg';
                    break;
                case 'os': 
                    img = 'no_dynamic_os.svg';
                    break;
                case 'lang':
                    img = 'no_dynamic_lang.svg';
                    break;
                case 'location': 
                    img = 'no_dynamic_location.svg';
                    break;
                }               
                return img;
            }
        },
        methods: {
            goToCreate() {
                const action = this.type === 'dynamic' ? 'expandDynamic' : 'highlightStatic';
                this.$router.push('/create');
                EventBus.$emit(`${action}`);
            }
        }
    }
</script>

<style scoped lang="scss">
.no-data {
    height: 200px;
    &.lg {
        min-height: 310px;
    }
    &.xl {
        min-height: 385px;
        @media(max-width: 960px) {
            min-height: 355px;
        }
    }
}
</style>