<template>
    <div class="library-item-control" >
        <v-card>
            <!-- Card title -->
            <v-card-title v-if="formattedFilename !== undefined" class="px-5 py-2">
                <div class="d-flex justify-space-between align-center smfm-w-100">
                    <div class="smfm-pro-subtitle">{{ formattedFilename }}</div>
                    <v-tooltip :disabled="!isSystem"
                                content-class="pro-library-tooltip"
                                nudge-left="-4" max-width="180" left>
                        <template v-slot:activator="{ on, attrs }">
                            <div v-on="on">
                                <v-btn icon v-bind="attrs" :disabled="!canEdit" @click="ui.editName = true">
                                    <v-icon color="secondary">{{ iconEdit }}</v-icon>
                                </v-btn>
                            </div>
                        </template>
                        <span>{{ $t('pro.library.noedithint') }}</span>
                    </v-tooltip>
                </div>
            </v-card-title>

            <!-- Qr Preview -->
            <v-card-text class="mb-4">
                <div class="previewelement" v-if="formattedFilename !== undefined">
                    <!-- TODO: if the option.svg is available - display as svg stretched to fill the container,
                    if not - display src / previewsrc stretched to fill the container,
                    this way when image is loaded it does not change, just becomes better quality -->
                    <div v-if="option.svg" v-html="option.svg"></div>
                    <img v-else-if="option.src" :src="option.src"/>
                    <loading v-else-if="!option.src || ui.loading || option.svg.length !== 0" :size="3" class="loading"></loading>
                </div>
            </v-card-text>
        </v-card>

        <!-- Edit Name Dialog -->
        <edit-name :file-name="fileName"
                   :section="this.section"
                   :full-file-name="this.option.value"
                   :api-path="this.apiPath"
                   :dialogVisible="ui.editName"
                   :hide-extension="section !== 'logo'"
                   @updateUserLibrary="updateLibrary"
                   @close="ui.editName = false"/>

        <!-- Button with tooltip for open Pro Editor Dialog -->
        <v-tooltip :disabled="!isSystem" color="black"
                   nudge-bottom="20" content-class="pro-library-tooltip"
                   max-width="180" flat v-if="section === 'preset'" top>
            <template v-slot:activator="{ on, attrs }">
                <div  v-on="on">
                    <v-btn v-bind="attrs"
                        :block="$vuetify.breakpoint.mdAndUp"
                        class="smfm-primary-btn smfm-lg-btn mb-2"
                        :class="{'mx-5 smfm-block-btn': $vuetify.breakpoint.smAndDown}"
                        :loading="ui.editing" :disabled="!canEdit"
                        @click="showEditDialog">
                        {{ $t('pro.library.edit') }}
                    </v-btn>
                </div>
            </template>
            <span color="#fff">{{ $t('pro.library.noedithint') }}</span>
        </v-tooltip>

        <!-- Button with tooltip for open Pro Svg Editor Dialog -->
        <v-tooltip :disabled="!isSystem" content-class="pro-library-tooltip" nudge-bottom="20" max-width="180"
                   v-if="section !== 'preset'" top>
            <template v-slot:activator="{ on, attrs }">
                <div  v-on="on">
                    <v-btn v-bind="attrs"
                            :block="$vuetify.breakpoint.mdAndUp"
                            class="smfm-primary-btn smfm-lg-btn mb-2"
                            :class="{'mx-5 smfm-block-btn': $vuetify.breakpoint.smAndDown}"
                            :loading="ui.editing" :disabled="!canEditSvg" 
                            @click="editSvg">
                        {{ $t('pro.library.editsvg') }}
                    </v-btn>
                </div>
            </template>
            <span>{{ $t('pro.library.noedithint') }}</span>
        </v-tooltip>

        <!-- Pro Preset Editor dialog -->
        <v-dialog v-model="ui.editDialog" v-if="section === 'preset'" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card>
                <pro-preset-editor :activated="ui.editDialog"
                                   :file="fileName" :path="apiPath + '/' + this.option.value"
                                   :configinitial="this.option.config"
                                   @error="e => $emit('error', e)"
                                   v-if="ui.editDialog === true"
                                   @saved="editSaved()"
                                   @closed="closeEditDialog"
                ></pro-preset-editor>
            </v-card>
        </v-dialog>

        <!-- Pro Svg Editor Dialog -->
        <v-dialog v-if="canEditSvg" v-model="ui.editSvg" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card>
                <pro-svg-editor :activated="ui.editSvg" :section="section" :file="fileName"
                                :path="apiPath+'/'+fileName"
                                @error="e => $emit('error', e)"
                                @saved="editSaved('editsvg')"
                                v-if="ui.editSvg === true"
                                @closed="closeEditSvg"
                ></pro-svg-editor>
            </v-card>
        </v-dialog>

        <!-- Actions buttons -->
        <v-btn v-if="section !== 'preset'"
            :block="$vuetify.breakpoint.mdAndUp" class="smfm-lg-btn library-item-control--btn mb-2" 
            :disabled="!canExport" :loading="ui.exporting"
            :class="{'mx-5 smfm-block-btn': $vuetify.breakpoint.smAndDown}"
            @click="exported">
            {{ exportBtnLabel }}
        </v-btn>
        <div class="d-flex flex-wrap justify-space-between align-center">
            <v-btn class="smfm-lg-btn library-item-control--btn mb-2"
                :class="{'mx-5': $vuetify.breakpoint.smAndDown}"
                :loading="ui.duplicating" @click="duplicated">
                <span>{{ $t('pro.library.duplicate') }}</span>
            </v-btn>   
            <v-btn class="smfm-lg-btn library-item-control--btn delete mb-2"
                :disabled="!canEdit"
                :class="{'mx-5': $vuetify.breakpoint.smAndDown}"
                :loading="ui.deleting" @click="deleted">
                <span>{{ $t('pro.library.delete') }}</span>
            </v-btn>
        </div>
    </div>
</template>

<script>
    import { mdiPencil } from '@mdi/js';
    import userMixin from "@/mixins/userMixin";
    import EditName from "./dialogs/ProLibraryEditName";
    import ProPresetEditor from "@/components/pro/ProPresetEditor";
    import ProSvgEditor from "@/components/pro/ProSvgEditor";
    import qrdownload from "@/mixins/qrdownload";
    import Loading from "@/components/common/Loading";

    export default {
        name: "ProLibraryItemControls",
        mixins: [ userMixin, qrdownload ],
        components: { EditName, ProPresetEditor, ProSvgEditor, Loading },
        props: {
            option: null,
            section: null,
        },
        data() {
            return {
                iconEdit: mdiPencil,
                ui: {
                    editDialog: false,
                    editName: false,
                    editSvg: false,
                    duplicating: false,
                    deleting: false,
                    editing: false,
                    loading: false,
                    exporting: false
                }
            }
        },
        methods: {
            duplicated() {
                this.ui.duplicating = true
                this.authPost({url: this.apiPath, params: {copyfrom: this.option.value}})
                    .then(resp => {
                        this.$emit('clearSelectedPreset', {section: this.section, uploaded: resp.data.name});
                    })
                    .catch(e => this.$emit('error', e))
            },
            deleted() {
                this.ui.deleting = true
                const filename = !this.isAdmin ? this.option.value : this.option.value.split('/').reverse()[0]
                this.authDelete({url: this.apiPath + '/' + filename})
                    .then(() => this.updateLibrary({section: this.section, deleted: filename}))
                    .catch(e => this.$emit('error', e))
            },
            exported() {
                this.ui.exporting = true;
                this.authGetBinary({url: `${this.apiPath}/${this.fileName}`})
                    .then(resp => {
                        const blob = new Blob([resp.data], {type: resp.headers['content-type']});
                        const objectUrl = URL.createObjectURL(blob);
                        this.triggerDownload(objectUrl, this.option.value)
                        this.ui.exporting = false;
                    })
                    .catch(txt => this.$emit('error', txt))
            },
            updateLibrary(val) {
                this.$emit('clearSelectedPreset', val)
            },
            editSaved(val = 'edit') {
                if (val === 'editsvg') {
                    this.ui.editSvg = false
                } else {
                    this.ui.editDialog = false
                }
                this.$emit('clearSelectedPreset', {section: this.section, modified: this.fileName, fileName: this.fileName})
            },
            showEditDialog() {
                this.ui.editing = true
                this.ui.editDialog = true
            },
            editSvg() {
                this.ui.editSvg = true
                this.ui.editing = true
            },
            closeEditSvg() {
                this.ui.editing = false
                this.ui.editSvg = false
            },
            closeEditDialog() {
                this.ui.editing = false
                this.ui.editDialog = false
            }
        },
        computed: {
            apiPath() {
                return this.isAdmin ? `admin/library/${this.section}s` : `pro/library/${this.section}s`
            },
            formattedFilename() {
                return this.section !== 'logo' ?
                    this.option.value.split('/').reverse()[0].replace(/\.[^/.]+$/, "") : this.fileName
            },
            isSystem() {
                return !this.option.usercontent && !this.isAdmin
            },
            canEdit() {
                return this.option && (this.option.usercontent || this.isAdmin)
            },
            canEditSvg() {
                return this.canEdit && this.option.value.endsWith('.svg')
            },
            canExport() {
                return this.canEdit && this.section !== 'preset'
            },
            carouselLabel() {
                return this.section ? `Your ${this.section}s: ` : ''
            },
            fileName() {
                return this.option.value ? this.option.value.split('/').reverse()[0] : '';
            },
            // eslint-disable-next-line vue/return-in-computed-property
            exportBtnLabel() {
                if (this.section === 'template') {
                    return this.$t('pro.library.templates.export')
                } else if (this.section === 'logo') {
                    return this.$t('pro.library.logos.export')
                }
            }
        },
        watch: {
            option() {
                this.ui.deleting = false
                this.ui.duplicating = false
                this.ui.editing = false
            }
        },
        mounted() {
            this.ui.loading = true
            setTimeout(() => {
                this.ui.loading = false
            }, 500)
        }
    }
</script>

<style lang="scss" scoped>
.library-item-control {
    &::v-deep {
        .library-item-control--btn {
            width: 48% !important;
            background-color: #fff !important;
            border: 1px solid #EEEEEE;
            border-radius: 12px;
            color: #454e5e !important;
            &.delete {
                color: #C9454D !important;
            }
            &:disabled {
                background-color: #FFFFFF !important;
                opacity: 0.5;
            }
            @media(max-width: 960px) {
                width: calc(100% - 40px) !important;
            }
        }
        .v-card {
            box-shadow: none;
            border-radius: 4px;
            &__title {
                border-bottom: 2px solid rgba(3, 148, 154, 0.2);
            }
            &__text {
                padding: 40px;
                min-height: 390px;
            }
        }
    }
}

.previewelement::v-deep {
    & > div > svg,
    & > .loading,
    & > img {
        /* TODO: stretch/resize to fill the size of the container; display centered horizontally and vertically */
        width: 100%;
        height: 300px;
    }
}

.pro-library-tooltip {
    background: black;
    text-align: center;
    padding: 4px 8px;
    border-radius: 4px;
    &::v-deep {
        span {
            font-size: 10px;
            font-style: normal;
            font-weight: 500;
            line-height: 14px;
            letter-spacing: 0;
            text-align: center;
            color: #FFFFFF!important;
        }
    }
}

</style>
