<template>
  <v-dialog
    v-model="showDialog"
    scrollable
    max-width="500">
    <v-card class="smfm-dialog-with-header">
      <v-toolbar flat :height="$vuetify.breakpoint.smAndDown ? 60 : 70"
        class="smfm-page-subheading">
        {{ $t('pro.qrlist.addtags') }}
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click.stop="closeDialog()">
          <v-icon color="secondary">{{ iconClose }}</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <div class="mb-7">
            {{ $t('pro.qrlist.addingto') }}:
            <div v-for="code in selected" :key="code.codeid" class="text--primary">
                {{ code.name }}
            </div>
        </div>
        <v-combobox
          v-model="tagstoadd"
          :items="tags"
          :label="$t('pro.qrlist.tags')"
          multiple
          outlined
          chips
          hide-details>
          <template v-slot:selection="data">
            <v-chip
              v-bind="data.attrs"
              :input-value="data.selected"
              close
              color="primary"
              label
              outlined
              @click:close="remove(data.item)">
              {{ data.item }}
            </v-chip>
          </template>
          <template v-slot:item="data">
            <v-list-item-content v-text="data.item"></v-list-item-content>
          </template>
        </v-combobox>
      </v-card-text>
      <v-card-actions class="d-flex justify-end align-center pt-5"
          :class="[$vuetify.breakpoint.smAndDown ? 'px-5 pb-7' : 'px-10 pb-10']">
        <v-btn
          text
          class="me-3"
          color="secondary"
          @click.stop="closeDialog()"
        > {{ $t('app.cancel') }}
        </v-btn>
        <v-btn
          class="smfm-primary-btn smfm-lg-btn"
          :disabled="!tagstoadd.length"
          @click="bulkAddTags"
        > {{ $t('pro.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
    import userMixin from "@/mixins/userMixin";
    import { mdiClose } from '@mdi/js';

    export default {
        name: "ProTagsAddDialog",
        mixins: [ userMixin ],
        props: {
            dialogVisible: {
                type: Boolean,
                default: false,
            },
            selected: {
                type: Array,
                default: () => {}
            },
            codes: {
                type: Array,
                default: () => {}
            },
        },
        data() {
            return {
                tagstoadd: [],
            }
        },
        computed: {
            iconClose() { return mdiClose },
            showDialog: {
                get() {
                    return this.dialogVisible;
                },
                set(newValue) {
                    if(!newValue) this.$emit('close');
                }              
            },  
            tags() {
                const tags = [];
                const notSelected = this.codes && this.codes.filter(code => !this.selectedCodes.includes(code.codeid));
                const tagsInNotSelected = notSelected && [].concat(...notSelected.map(code => code.tags ? code.tags : []));
                const tagsInSelected = [].concat(...this.selected.map(code => code.tags));
                tagsInNotSelected && tagsInNotSelected.forEach(tag => {
                    if (!tags.includes(tag) && !tagsInSelected.includes(tag)) tags.push(tag);
                })
                return tags;
            },            
            selectedCodes() {
                return this.selected.map(code => code.codeid);
            }        
        },
        methods: {
            closeDialog() {
                this.showDialog = false;
                this.tagstoadd = [];
            },
            remove(tag) {
                const index = this.tagstoadd.indexOf(tag)
                if (index >= 0) this.tagstoadd.splice(index, 1)
            }, 
            bulkAddTags() {
                this.authPost({
                    url: 'pro/codesbulk/addtags', 
                    params: { codes: this.selectedCodes, tags: this.tagstoadd }
                }).then(() => {
                    this.$emit('refresh');
                    this.closeDialog();
                })
            },         
        }   
    }
</script>

<style scoped lang="scss">
</style>
