import userMixin from "@/mixins/userMixin";

export default {
    //namespaced: true,

    state: () => ({
        adminArticles: null,
    }),

    getters: {
        adminArticles(state) {
            return state.adminArticles
        }
    },

    mutations: {
        _setAdminArticles(state, payload) {
            state.adminArticles = payload
        }
    },

    actions: {

        adminLoadResources({commit, getters}, force = false) {
            if (!force && getters.adminArticles !== null) {
                return null
            }
            return userMixin.methods.authGet({url: 'admin/blog'})
                .then(resp => commit('_setAdminArticles', resp.data))
        }
    }
}