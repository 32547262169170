var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('div',{staticClass:"float-right pb-5"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.reloadUsers()}}},[_vm._v("Refresh "),_c('v-icon',[_vm._v(_vm._s(_vm.iconRefresh))])],1)],1),_c('v-card-title',[_vm._v(" Users "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":_vm.iconSearch,"label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.users,"search":_vm.search,"options":_vm.tableOptions,"show-expand":"","single-expand":false,"expanded":_vm.expanded,"item-key":"username"},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.username",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("/users/" + (item.username) + "/codes")}},[_vm._v(_vm._s(item.username))]),(!item.enabled)?_c('v-icon',{attrs:{"color":"error"}},[_vm._v(_vm._s(_vm.iconUnchecked))]):_vm._e()]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [(item.picture)?_c('v-avatar',{attrs:{"size":36}},[_c('img',{attrs:{"src":item.picture}})]):_vm._e(),_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.timecreated",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.timecreated*1000))+" ")]}},{key:"item.custom:plan",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item['custom:plan'] === 'trial' ? ['rounded-pill', 'blue', 'lighten-4', 'pa-1'] : []},[_vm._v(" "+_vm._s(item['custom:plan'])+" ")]),(_vm.isPlanActive(item))?_c('v-icon',{attrs:{"color":"success"}},[_vm._v(_vm._s(_vm.iconCheck))]):_c('v-icon',{attrs:{"color":"error","title":_vm.inactivePlanTitle(item)}},[_vm._v(_vm._s(_vm.iconUnchecked))])]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.email)+" "),(item.email_verified)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v(_vm._s(_vm.iconCheck))]):_c('v-icon',{attrs:{"color":"error"}},[_vm._v(_vm._s(_vm.iconUnchecked))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('simple-dialog',{attrs:{"header":"Delete","no-title":true,"buttontext":"Delete"},on:{"save":function($event){return _vm.deleteUser(item)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var open = ref.open;
return [_c('v-btn',{attrs:{"icon":"","fab":"","small":""},on:{"click":open}},[_c('v-icon',[_vm._v(_vm._s(_vm.iconDelete))])],1)]}}],null,true)},[_vm._v(" Are you sure you want to completely delete this user? This operation can not be undone ")])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('pre',[_c('small',[_vm._v(_vm._s(item))])])])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }